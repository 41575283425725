import * as styles from './Code.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
}

const Code: React.FC<Props> = (props) => {
  const { children } = props

  return <code className={styles.code}>{children}</code>
}

export default Code
