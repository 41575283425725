import * as styles from './AccentColorContext.module.scss'

import React, { useContext, useMemo } from 'react'

import { hexToRGB, hslToRgb, rgbToHsl } from './colors'

/**
 * Sets CSS variables and provides a hook for accessing the accent color for
 * child elements.
 *
 * Wherever possible the CSS variables `--accent-color` and `--accent-color-rgb`
 * should be used in child elements, but the React context can also be used if
 * colors are needed in JavaScript, for example when passing them through React
 * portals.
 */

const defaultAccentColor = '#2b39ff'

export const AccentColorContext = React.createContext(defaultAccentColor)

export function useAccentColor() {
  const color = useContext(AccentColorContext)

  return {
    color,
    containerStyles: stylesForAccentColor(color),
  }
}

interface Props {
  children: React.ReactNode
  color?: string
}

function stylesForAccentColor(color: string) {
  const rgb = hexToRGB(color)

  if (rgb == null) {
    return {}
  }

  const hsl = rgbToHsl(...rgb)
  const shiftedRgb = hslToRgb((hsl[0] + 0.85) % 1, hsl[1], hsl[2])

  return {
    '--accent-color': color,
    '--accent-color-rgb': rgb.join(', '),
    '--player-accent-1': rgb.join(', '),
    '--player-accent-2': shiftedRgb.join(', '),
  } as React.CSSProperties
}

export const AccentColor: React.FC<Props> = (props) => {
  const { children, color = defaultAccentColor } = props

  const accentStyles = useMemo(() => {
    return stylesForAccentColor(color)
  }, [color])

  return (
    <AccentColorContext.Provider value={color}>
      <div className={styles.container} style={accentStyles}>
        {children}
      </div>
    </AccentColorContext.Provider>
  )
}
