import React from 'react'

import { useTooltip } from 'components/visualization/tooltip/Tooltip'

interface BarProps {
  x: number
  y: number
  width: number
  height: number
  label: string
}

const HistogramBarUnmemoized: React.FC<BarProps> = (props) => {
  const { x, y, width, height, label } = props

  const { mouseEvents, portal } = useTooltip(() => <div>{label}</div>)

  return (
    <>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        {...mouseEvents}
        style={{ fill: 'var(--accent-color)' }}
      />

      {portal}
    </>
  )
}

export const HistogramBar = React.memo(HistogramBarUnmemoized)
