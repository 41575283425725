import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'

export const Museum2: React.FC = () => {
  return (
    <Decklist
      label="Museum of Modern"
      labelLink="https://cubecobra.com/cube/overview/ModernMuseum"
      name="Braid To Light"
      maindeck={[
        [
          { name: 'Misty Rainforest', set: 'MH2' },
          { name: 'Verdant Catacombs', set: 'MH2' },
          { name: 'Verdant Catacombs', set: 'MH2' },
          { name: 'Scalding Tarn', set: 'MH2' },
          { name: 'Wooded Foothills', set: 'KTK' },
          { name: 'Wooded Foothills', set: 'KTK' },
          { name: 'Wooded Foothills', set: 'KTK' },
          { name: 'Windswept Heath', set: 'KTK' },
          { name: 'Godless Shrine', set: 'GTC' },
          { name: 'Stomping Ground', set: 'GTC' },
          { name: 'Overgrown Tomb', set: 'RTR' },
          { name: 'Temple Garden', set: 'RTR' },
          { name: 'Blood Crypt', set: 'RTR' },
          { name: 'Breeding Pool', set: 'GTC' },
          { name: 'Steam Vents', set: 'RTR' },
          { name: 'Forest', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
        ],
        [
          { name: 'Narnam Renegade' },
          { name: 'Narnam Renegade' },
          { name: 'Birds of Paradise', set: '7ED' },
          { name: 'Thoughtseize' },
          { name: 'Inquisition of Kozilek' },
          { name: 'Lightning Bolt' },
        ],
        [
          { name: 'Tarmogoyf', set: 'MMA' },
          { name: 'Scavenging Ooze' },
          { name: 'Scavenging Ooze' },
          { name: 'Voice of Resurgence', set: 'DGM' },
          { name: 'Tribal Flames' },
          { name: 'Tribal Flames' },
          { name: 'Go For the Throat' },
          { name: 'Abrupt Decay', set: 'RTR' },
        ],
        [
          { name: 'Lingering Souls' },
          { name: 'Kitchen Finks' },
          { name: 'Renegade Rallier' },
          { name: 'Reclamation Sage' },
          { name: 'Anafenza, the Foremost', set: 'KTK' },
          { name: 'Loxodon Smiter', set: 'RTR' },
        ],
        [
          { name: 'Bloodbraid Elf', set: 'ARB' },
          { name: 'Bloodbraid Elf', set: 'ARB' },
          { name: 'Siege Rhino', set: 'KTK' },
          { name: 'Bring to Light', set: 'BFZ' },
        ],
      ]}
    />
  )
}
