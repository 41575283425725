import * as styles from './Chaotic.module.scss'

import React, { useCallback, useState } from 'react'
import { random } from 'lodash'

import { TreeDiagram, TreeNode } from './TreeDiagram'
import { weightedRandom } from 'src/utils/weightedRandom'

export const Chaotic: React.FC = () => {
  const [trees, setTrees] = useState(() => {
    return Array.from(Array(3)).map(() => {
      return makeTree(random(5, 7))
    })
  })

  const randomizeTree = useCallback((index: number) => {
    setTrees((value) => {
      const newValue = [...value]
      newValue[index] = makeTree(random(5, 7))
      return newValue
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.trees}>
        {trees.map((tree, index) => (
          <div key={index} onMouseEnter={() => randomizeTree(index)}>
            <TreeDiagram data={tree} width={200} height={200} />
          </div>
        ))}
      </div>
      <div>Feel Something</div>
    </div>
  )
}

function makeTree(height: number, depth = 0): TreeNode {
  const childCount =
    depth === 0
      ? Math.floor(random(1, 4.5, true))
      : weightedRandom([
          { value: 0, weight: 3 },
          { value: 1, weight: 7 },
          { value: 2, weight: 5 },
          { value: 3, weight: 2 },
          { value: 4, weight: 1 },
          { value: 5, weight: 1 },
          { value: 6, weight: 1 },
        ]).value
  const children =
    height > 1
      ? Array.from(Array(childCount)).map(() => makeTree(height - 1, depth + 1))
      : undefined

  return {
    name: 'node',
    children,
  }
}
