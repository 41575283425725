import * as styles from './TextArea.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  accent?: boolean
}

export const TextArea: React.FC<Props> = (props) => {
  const { accent, ...textAreaProps } = props

  return (
    <textarea
      {...textAreaProps}
      className={classNames(styles.textarea, {
        [styles.accent]: accent,
      })}
    />
  )
}
