import * as styles from './Uniform.module.scss'

import React, { useMemo } from 'react'
import { random } from 'lodash'

import { TreeDiagram, TreeNode } from './TreeDiagram'

export const Uniform: React.FC = () => {
  const tree = useMemo(() => {
    return makeTree(5)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.tree}>
        <TreeDiagram data={tree} />
      </div>
      <div>Games</div>
    </div>
  )
}

function makeTree(height: number, depth = 0): TreeNode {
  const childCount = depth === 0 ? 3 : Math.floor(random(1.9, 3.3, true))
  const children =
    height > 1
      ? Array.from(Array(childCount)).map(() => makeTree(height - 1, depth + 1))
      : undefined

  return {
    name: 'node',
    children,
  }
}
