import * as styles from './BattleBoxList.module.scss'

import React from 'react'

import * as Typography from 'components/typography'

import CardSet from 'components/article-elements/card-set/CardSet'

import list from './BattleBoxList.json'

const BattleBoxList: React.FC = () => {
  return (
    <div className={styles.container}>
      <CardSet columns={list as any} />
      <Typography.Caption>
        An example Battle Box list.{' '}
        <a href="https://cubecobra.com/cube/list/n6yy">View on Cube Cobra</a>
      </Typography.Caption>
    </div>
  )
}

export default BattleBoxList
