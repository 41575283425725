import * as styles from './ModalWithHeading.module.scss'

import React from 'react'

import { Modal } from 'components/controls/Modal'

interface Props {
  title?: string
  description?: string
  children: React.ReactNode
  presented: boolean
  dismiss(): void
}

export const ModalWithHeading: React.FC<Props> = (props) => {
  const { title, description, children, ...modalProps } = props

  return (
    <Modal
      {...modalProps}
      buttonColor={title || description ? 'light' : 'dark'}
    >
      <div className={styles.container}>
        {(title || description) && (
          <div className={styles.heading}>
            {title && <div className={styles.title}>{title}</div>}
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </Modal>
  )
}
