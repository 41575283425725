import { MedAncTimes } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/MedAncTimes.tsx";
import { AtomicCube } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/AtomicCube.tsx";
import { PenroseCube } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/PenroseCube.tsx";
import { Museum1 } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/Museum1.tsx";
import { DoWN } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/DoWN.tsx";
import { AltTimeline } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/AltTimeline.tsx";
import { Museum2 } from "../../../../src/data/articles/a-dozen-friends-eleven-cube-drafts-and-one-flat-tire/sample-decks/Museum2.tsx";
import * as React from 'react';
export default {
  MedAncTimes,
  AtomicCube,
  PenroseCube,
  Museum1,
  DoWN,
  AltTimeline,
  Museum2,
  React
};