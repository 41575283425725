import * as styles from './Caption.module.scss'

import React from 'react'

import SmallText from './SmallText'

interface Props {
  children: React.ReactNode
}

const Caption: React.FC<Props> = (props) => {
  const { children } = props

  return (
    <div className={styles.caption}>
      <SmallText>{children}</SmallText>
    </div>
  )
}

export default Caption
