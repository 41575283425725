import * as styles from './SecondaryHeading.module.scss'

import React, { useMemo } from 'react'
import { kebabCase } from 'lodash'

import { getNodeText } from 'src/utils/getNodeText'

interface Props {
  children: React.ReactNode
  text?: string | null
}

const SecondaryHeading: React.FC<Props> = (props) => {
  const { children } = props

  const slug = useMemo(() => {
    const text = getNodeText(children)
    return typeof text === 'string' ? kebabCase(text) : null
  }, [children])

  return (
    <div className={styles.heading} id={slug ?? undefined}>
      {children}
    </div>
  )
}

export default SecondaryHeading
