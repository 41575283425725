import * as styles from './Linear.module.scss'

import React, { useMemo } from 'react'

import { TreeDiagram, TreeNode } from './TreeDiagram'

export const Linear: React.FC = () => {
  const tree = useMemo(() => {
    return makeTree(8)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.tree}>
        <TreeDiagram data={tree} width={400} height={100} direction="right" />
      </div>
      <div>Not a Game</div>
    </div>
  )
}

function makeTree(height: number): TreeNode {
  const children = height > 1 ? [0].map(() => makeTree(height - 1)) : undefined

  return {
    name: 'node',
    children,
  }
}
