import * as styles from './MapBanner.module.scss'

import React from 'react'

import image from './map-banner.png'

const MapBanner: React.FC = () => {
  return (
    <div>
      <img src={image} alt="" className={styles.image} />
    </div>
  )
}

export default MapBanner
