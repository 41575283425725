import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const UBCheat: React.FC = () => {
  return (
    <Decklist
      name="Omnicheat"
      drafter="Andy Mangold"
      description={
        <>
          This deck highlights the overlapping combos in the{' '}
          <em>Degenerate Micro Cube</em>. It&rsquo;s not a good{' '}
          <CardHover card="Flash">Flash</CardHover> deck, nor a good reanimator
          deck, nor a good{' '}
          <CardHover card="Show and Tell">Show and Tell</CardHover> deck, but
          with all those elements combined it&rsquo;s quite effective and
          flexible.{' '}
          <CardHover card="Snapcaster Mage">Snapcaster Mage</CardHover> is
          really flexing it&rsquo;s muscles here as a a second copy of either{' '}
          <CardHover card="Reanimate">Reanimate</CardHover> or{' '}
          <CardHover card="Flash">Flash</CardHover>.
        </>
      }
      maindeck={[
        [
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Swamp', set: 'TMP' },
          { name: 'Swamp', set: 'TMP' },
          { name: 'Gitaxian Probe' },
          { name: 'Disrupting Shoal' },
          { name: 'Force of Negation' },
          { name: 'Foil', set: 'PCY' },
          { name: 'Force of Will', set: 'ALL' },
        ],
        [{ name: 'Snapcaster Mage', set: 'ISD' }],
        [
          { name: 'Reanimate', set: 'TMP' },
          { name: 'Flash' },
          { name: 'Show and Tell' },
        ],
        [{ name: 'Ashen Rider' }, { name: 'Iona, Shield of Emeria' }],
      ]}
      sideboard={[
        { name: 'Thalia, Guardian of Thraben' },
        { name: 'Daze', set: 'NEM' },
        { name: 'Dark Ritual', set: 'MMQ' },
        { name: 'Mind Twist', set: 'LEB' },
        { name: 'Kitesail Freebooter' },
        { name: 'Vampire Hexmage' },
        { name: 'Once Upon a Time' },
        { name: 'Elvish Spirit Guide', set: 'ALL' },
        { name: "Feldon's Cane" },
      ]}
    />
  )
}

export default UBCheat
