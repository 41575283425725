import * as styles from './ColorInput.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  accent?: boolean
}

export const ColorInput: React.FC<Props> = (props) => {
  const { accent, ...inputProps } = props

  return (
    <input
      type="color"
      {...inputProps}
      className={classNames(styles.input, {
        [styles.accent]: accent,
      })}
    />
  )
}
