import * as styles from './CardSet.module.scss'

import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Column from './Column'

import { Color } from './Color'

export interface Props {
  columns: Array<{
    label?: string
    color?: Color
    sections: Array<{
      label: string
      color?: Color
      cards: Array<{ name: string; set?: string; color?: Color }>
    }>
  }>
}

/**
 * Collection of cards organized in columns and sections.
 */
const CardSet: React.FC<Props> = (props) => {
  const { columns } = props

  return (
    <section>
      <HorizontalScrollingContainer>
        <div className={styles.container}>
          {columns.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </div>
      </HorizontalScrollingContainer>
    </section>
  )
}

export default CardSet
