import * as styles from './RarityBreakdown.module.scss'

import React from 'react'

export const RarityBreakdown: React.FC = () => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.heading}>&nbsp;</div>
        <div className={styles.labelTable}>
          <div>Common</div>
          <div>Uncommon</div>
          <div>Rare/Mythic</div>
        </div>
      </div>

      <div>
        <div className={styles.heading}>Booster</div>
        <div className={styles.table}>
          <div>11</div>
          <div>&rarr;</div>
          <div>71%</div>

          <div>3</div>
          <div>&rarr;</div>
          <div>21%</div>

          <div>1</div>
          <div>&rarr;</div>
          <div>7%</div>
        </div>
      </div>
      <div>
        <div className={styles.heading}>Cube</div>
        <div className={styles.table}>
          <div>100 x 4</div>
          <div>&rarr;</div>
          <div>63%</div>

          <div>80 x 2</div>
          <div>&rarr;</div>
          <div>25%</div>

          <div>60 x 1</div>
          <div>&rarr;</div>
          <div>12%</div>
        </div>
      </div>
    </div>
  )
}
