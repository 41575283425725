import CardRankings from "../../../../src/components/card-rankings/CardRankings";
import oneRatings from "../../../../src/data/articles/cube-prospective-phyrexia-all-will-be-one/one-prospective-ratings.json";
import ProspectiveIntro from "../../../../src/components/article-elements/ProspectiveIntro.tsx";
import { ONEMechanicsGraph } from "../../../../src/data/articles/cube-prospective-phyrexia-all-will-be-one/ONEMechanicsGraph.tsx";
import * as React from 'react';
export default {
  CardRankings,
  oneRatings,
  ProspectiveIntro,
  ONEMechanicsGraph,
  React
};