import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Decklist from 'components/decklists/Decklist'

export const SimicDeck: React.FC = () => {
  return (
    <HorizontalScrollingContainer>
      <Decklist
        maindeckLabel="Example Deck"
        maindeck={[
          [
            { name: 'Waterlogged Grove' },
            { name: 'Botanical Sanctum' },
            { name: 'Otawara, Soaring City' },
            { name: 'Verdant Catacombs' },
            { name: 'Yavimaya Coast' },
            { name: 'Tropical Island' },
            { name: 'Prismatic Vista' },
            { name: 'Forest', set: 'mh3', count: 5 },
            { name: 'Island', set: 'mh3', count: 3 },
          ],
          [
            { name: 'Force of Will' },
            { name: 'Worldly Tutor' },
            { name: 'Thought Scour' },
            { name: 'Once Upon a Time' },
            { name: 'Spell Pierce' },
            { name: 'Hexdrinker' },
            { name: 'Joraga Treespeaker' },
            { name: 'Noble Hierarch' },
            { name: 'Generous Ent' },
          ],

          [
            { name: 'Mana Leak' },
            { name: 'Phantom Interference' },
            { name: 'Rofellos, Llanowar Emissary' },
            { name: 'Phantasmal Image' },
            { name: 'Sakura-Tribe Elder' },
            { name: 'Fertile Ground' },
          ],
          [
            { name: 'Flare of Cultivation' },
            { name: 'Freestrider Lookout' },
            { name: 'Courser of Kruphix' },
            { name: 'Shark Typhoon' },
            { name: 'Brazen Borrower' },
            { name: 'Uro, Titan of Nature’s Wrath' },
          ],
          [
            { name: 'Green Sun’s Zenith' },
            { name: 'Nissa, Who Shakes the World' },
            { name: 'Tarmogoyf Nest' },
          ],
        ]}
      />
    </HorizontalScrollingContainer>
  )
}
