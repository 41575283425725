import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const BantControl: React.FC = () => {
  return (
    <Decklist
      name="All-In Tinker"
      drafter="Anthony Mattox"
      description={
        <>
          At first, it might seem like this extremely fast{' '}
          <CardHover card="Tinker">Tinker</CardHover> deck would just scoop if
          their opponent countered or discarded their{' '}
          <CardHover card="Tinker">Tinker</CardHover>, but plan B is to simply
          cast <CardHover card="Inkwell Leviathan">Inkwell Leviathan</CardHover>{' '}
          and{' '}
          <CardHover card="Blightsteel Colossus">
            Blightsteel Colossus
          </CardHover>{' '}
          on the back of{' '}
          <CardHover card="Mishra's Workshop">Workshop</CardHover>,{' '}
          <CardHover card="Tolarian Academy">Academy</CardHover>, and{' '}
          <CardHover card="Sol Ring">Sol Ring</CardHover>. It may seem
          far-fetched, but it happened multiple times in the matches this deck
          played!
        </>
      }
      maindeck={[
        [
          { name: 'Island', set: 'TMP' },
          { name: "Mishra's Workshop", set: 'ATQ' },
          { name: 'Tolarian Academy', set: 'USG' },
          { name: 'Wasteland', set: 'TMP' },
          { name: 'Mox Opal', set: 'SOM' },
        ],
        [
          { name: 'Careful Study' },
          { name: 'Aether Spellbomb' },
          { name: 'Chromatic Star' },
          { name: "Conjurer's Bauble" },
          { name: 'Pithing Needle' },
          { name: 'Sol Ring', set: 'LEB' },
        ],
        [{ name: 'Time Walk', set: 'LEB' }, { name: 'Phyrexian Revoker' }],
        [
          { name: 'Tinker' },
          { name: 'Inkwell Leviathan' },
          { name: 'Blightsteel Colossus' },
        ],
      ]}
      sideboard={[
        { name: 'Containment Priest' },
        { name: 'Hushbringer' },
        { name: 'Goblin Welder' },
        { name: 'Kaya, Orzhov Usurper' },
        { name: "Thespian's Stage" },
      ]}
    />
  )
}

export default BantControl
