import React, { useMemo } from 'react'
import { groupBy } from 'lodash'

import ScatterPlot from 'components/visualization/ScatterPlot'

import { useCSV } from 'utils/useCSV'

import data from 'raw-loader!./clustering_toy_data.csv'

const colors = ['#F2B701', '#3969AC', '#7F3C8D', '#11A579']

const ClusterPlot: React.FC = () => {
  const csvData = useCSV<{ x: number; y: number; cluster: number }>(data)

  const series = useMemo(() => {
    const clusters = groupBy(csvData, 'cluster')
    return Object.keys(clusters).map((key, index) => {
      return {
        color: colors[index],
        values: clusters[key].map((v) => [v.x, v.y] as [number, number]),
      }
    })
  }, [csvData])

  return (
    <ScatterPlot
      data={series}
      horizontalAxisLabel="Dimension 1"
      verticalAxisLabel="Dimension 2"
    />
  )
}

export default ClusterPlot
