import React from 'react'

import CardSet from 'components/article-elements/card-set/CardSet'

const EldraziSeed: React.FC = () => {
  return (
    <CardSet
      columns={[
        {
          label: 'Seed Cards',
          color: 'm',
          sections: [
            {
              label: 'Cool Eldrazi',
              cards: [
                { name: 'Endless One' },
                { name: 'Thought-Knot Seer' },
                { name: 'Reality Smasher' },
                { name: 'Matter Reshaper' },
                { name: 'Glaring Fleshraker' },
                { name: "Ulamog's Crusher" },
                { name: "Ugin's Conjurant" },
                { name: 'Spatial Contortion' },
                { name: 'Forerunner of Slaughter' },

                { name: 'Thief of Existence' },
                { name: 'Wastescape Battlemage' },
                { name: "Emrakul's Messenger" },
                { name: 'Wasteland Strangler' },
                { name: 'Bearer of Silence' },
              ],
            },
          ],
        },
        {
          label: 'Ways to Cast Eldrazi',
          color: 'c',
          sections: [
            {
              label: 'Deserts/Fixing Lands',
              cards: [
                { name: 'Brushland' },
                { name: 'Grand Coliseum' },
                { name: 'Base Camp' },
                { name: 'Twilight Mire' },
                { name: 'High Market' },
                { name: 'Pit of Offerings' },
                { name: 'Phyrexian Tower' },
                { name: 'Mutavault' },
                { name: "Mishra's Factory" },
                { name: 'Ramunap Ruins' },
                { name: 'Ipnu Rivulet' },
                { name: 'Hashep Oasis' },
                { name: 'Shefet Dunes' },
                { name: 'Ifnir Deadlands' },
                { name: 'Hostile Desert' },
                { name: 'Desert' },
              ],
            },
            {
              label: 'Non-Lands',
              cards: [
                { name: 'Talisman of Indulgence' },
                { name: 'Mind Stone' },
              ],
            },
          ],
        },
        {
          label: 'Spawn Synergies',
          color: 'b',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Pawn of Ulamog' },
                { name: 'Nest Invader' },
                { name: 'Carrion Feeder' },

                { name: 'Woodland Champion' },
                { name: "Jinnie Fay, Jetmir's Second" },
              ],
            },
            {
              label: 'Non-Creatures',
              cards: [
                { name: 'Malevolent Rumble' },
                { name: 'Intangible Virtue' },
                { name: "Desert's Due" },
                { name: 'Combine Chrysalis' },
              ],
            },
          ],
        },
        {
          label: 'Desert Synergies',
          color: 'g',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Colossal Rattlewurm' },
                { name: 'Paladin of Atonement' },
                { name: 'Ramunap Hydra' },
                { name: 'Greater Gargadon' },
                { name: "Death's Shadow" },
                { name: 'Sand Strangler' },
              ],
            },
            {
              label: 'Non-Creatures',
              cards: [{ name: 'Blazing Hope' }, { name: 'Hour of Promise' }],
            },
          ],
        },
        {
          label: 'Other Cool Cards',
          color: 'c',
          sections: [
            {
              label: 'Creatures',
              cards: [{ name: 'Elvish Piper' }],
            },
            {
              label: 'Non-Creatures',
              cards: [
                { name: 'Birthing Pod' },
                { name: 'Ceremonious Rejection' },
                { name: 'Ancient Stirrings' },
                { name: 'Infernal Reckoning' },
              ],
            },
          ],
        },
      ]}
    />
  )
}

export default EldraziSeed
