import * as styles from './CheckboxField.module.scss'

import React from 'react'

import { Checkbox } from './Checkbox'

interface Props {
  name?: string
  label: string
  checked: boolean
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
}

export const CheckboxField: React.FC<Props> = (props) => {
  const { name, label, checked, onChange } = props

  return (
    <label className={styles.label}>
      <Checkbox name={name} checked={checked} onChange={onChange} /> {label}
    </label>
  )
}
