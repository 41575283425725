import * as styles from './FAQuestionHeading.module.scss'

import React from 'react'
import { kebabCase } from 'lodash'

interface Props {
  children: string
}

export const FAQuestionHeading: React.FC<Props> = (props) => {
  const { children } = props

  return (
    <h2 className={styles.container}>
      <a id={kebabCase(children)}>
        <span className={styles.bullet}>&bull; </span>
        {children}
      </a>
    </h2>
  )
}
