import './TertiaryHeading.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
}

const TertiaryHeading: React.FC<Props> = (props) => {
  const { children } = props

  return <div className="TertiaryHeading">{children}</div>
}

export default TertiaryHeading
