import * as styles from './BackLink.module.scss'

import React from 'react'

import TextBlock from 'components/typography/blocks/TextBlock'

interface Props {
  href: string
  children: React.ReactNode
}

const BackLink: React.FC<Props> = (props) => {
  return (
    <TextBlock>
      <a className={styles.link} href={props.href}>
        ⬑ Back to {props.children}
      </a>
    </TextBlock>
  )
}

export default BackLink
