import * as styles from './CardImage.module.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import { useImageURLs } from 'utils/scryfall/hooks'
import { useIsTouchDevice } from 'utils/useIsTouchDevice'

import FlipButton from './FlipButton'

interface Props {
  children: string
  set?: string
  id?: string
  canFlip?: boolean
  flipMode?: 'hover' | 'button'
  imageClass?: string
  emphasized?: boolean
}

const CardImage: React.FC<Props> = (props) => {
  const {
    children,
    set,
    id,
    canFlip,
    flipMode = 'hover',
    imageClass,
    emphasized = false,
  } = props

  const [isFlipped, setFlipped] = useState(false)

  const [backFaceFailed, setBackFaceFailed] = useState(false)

  const imageURLs = useImageURLs(children, set, id)
  const isDoubleFaced = imageURLs.back != null && !backFaceFailed

  const isTouchDevice = useIsTouchDevice()

  const mouseHandlers = useMemo(() => {
    if (!canFlip || !isDoubleFaced || flipMode === 'button') {
      return {}
    }
    if (isTouchDevice) {
      return {
        onClick: () => isDoubleFaced && setFlipped((f) => !f),
      }
    }
    return {
      onMouseEnter: () => isDoubleFaced && setFlipped(true),
      onMouseLeave: () => isDoubleFaced && setFlipped(false),
    }
  }, [canFlip, flipMode, isDoubleFaced, isTouchDevice])

  const flip = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setFlipped((isFlipped) => !isFlipped)
  }, [])

  useEffect(() => {
    if (!canFlip) {
      setFlipped(false)
    }
  }, [canFlip])

  return (
    <div
      // This key forces a re-render rather than having the image source
      // attribute changed to prevent stale images.
      key={children}
      className={classNames([styles.container], {
        [styles.flipped]: isFlipped,
        [styles.emphasized]: emphasized,
      })}
      {...mouseHandlers}
    >
      <div className={styles.card}>
        <img
          className={classNames(styles.face, imageClass)}
          src={imageURLs.front}
          width={215}
          height={300}
          alt={children}
          draggable={false}
        />
        {imageURLs.back && (
          <img
            className={classNames(styles.back, imageClass)}
            src={imageURLs.back}
            width={215}
            height={300}
            alt=""
            draggable={false}
            onError={() => setBackFaceFailed(true)}
          />
        )}
      </div>

      {isDoubleFaced && flipMode === 'button' && (
        <FlipButton isFlipped={isFlipped} onCLick={flip} />
      )}
    </div>
  )
}

export default CardImage
