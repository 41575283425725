import * as styles from './RadioButton.module.scss'

import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const RadioButton: React.FC<Props> = (props) => {
  const { ...inputProps } = props

  return <input type="radio" className={styles.input} {...inputProps} />
}
