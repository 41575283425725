import * as styles from './MarkdownTemplate.module.scss'

import React, { useCallback } from 'react'

import { copyToClipboard } from 'utils/copyToClipboard'

const copyContent = `## Context

Explain how your cube will be played here.

## Restrictions

Explain the restrictions for your cube here.

## Power

Explain the desired power level of your cube here.

## Gameplay

Explain your ideal gameplay here.

—————

Cube description derived from [The First Four Questions Cube Designers
Should
Ask](https://luckypaper.co/articles/the-first-four-questions-cube-designers-should-ask/)`

const MarkdownTemplate: React.FC = () => {
  const copyTemplate = useCallback(() => {
    copyToClipboard(copyContent)
  }, [])

  return (
    <div className={styles.container}>
      <button className={styles.copyButton} onClick={copyTemplate}>
        Copy
      </button>

      <div>
        ## Context
        <br />
        <br />
        Explain how your cube will be played here.
        <br />
        <br />
        ## Restrictions
        <br />
        <br />
        Explain the restrictions for your cube here.
        <br />
        <br />
        ## Power
        <br />
        <br />
        Explain the desired power level of your cube here.
        <br />
        <br />
        ## Gameplay
        <br />
        <br />
        Explain your ideal gameplay here.
        <br />
        <br />
        —————
        <br />
        <br />
        Cube description derived from [The First Four Questions Cube Designers
        Should
        Ask](https://luckypaper.co/articles/the-first-four-questions-cube-designers-should-ask/).
      </div>
    </div>
  )
}

export default MarkdownTemplate
