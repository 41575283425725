import * as styles from './QuestionHeading.module.scss'

import React from 'react'

interface Props {
  label: string
  question: string
}

const QuestionHeading: React.FC<Props> = (props) => {
  const { label, question } = props

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <h2 className={styles.question}>{question}</h2>
    </div>
  )
}

export default QuestionHeading
