import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const SneakAndDepths: React.FC = () => {
  return (
    <Decklist
      name="Sneak &amp; Depths"
      drafter="Jay Wojciechowski"
      description={
        <>
          Venturing slightly above the minimum deck size to support an
          assortment of combos, this deck managed to pull off the 3-0.{' '}
          <CardHover card="Flash">Flash</CardHover> serves as a combo piece in
          addition to a way to recycle the entire graveyard by binning{' '}
          <CardHover card="Kozilek, Butcher of Truth">Kozilek</CardHover> or{' '}
          <CardHover card="Ulamog, the Infinite Gyre">Ulamog</CardHover>.
        </>
      }
      maindeck={[
        [
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Mountain', set: 'TMP' },
          { name: 'Simian Spirit Guide' },
          { name: 'Gemstone Mine', set: 'WTH' },
          { name: 'City of Traitors' },
          { name: "Thespian's Stage" },
          { name: 'Dark Depths', set: 'CSP' },
          { name: 'Force of Will', set: 'ALL' },
        ],
        [
          { name: 'Time Walk', set: 'LEB' },
          { name: 'Gilded Drake' },
          { name: 'Flash' },
          { name: 'Mana Drain', set: 'LEG' },
        ],
        [{ name: 'Sphinx of Foresight' }, { name: 'Sneak Attack', set: 'USG' }],
        [
          { name: 'Woodfall Primus' },
          { name: 'Kozilek, Butcher of Truth', set: 'ROE' },
          { name: 'Ulamog, the Infinite Gyre', set: 'ROE' },
        ],
      ]}
      sideboard={[
        { name: 'Abrade' },
        { name: "Nature's Claim" },
        { name: 'Oath of Druids' },
        { name: 'Scavenging Ooze' },
        { name: 'Nullstone Gargoyle' },
      ]}
    />
  )
}

export default SneakAndDepths
