import React from 'react'

import CardSet from 'components/article-elements/card-set/CardSet'

const ContrivedDecklists: React.FC = () => {
  return (
    <CardSet
      columns={[
        {
          label: 'List 1',
          color: 'w',
          sections: [
            {
              label: 'Empty List',
              cards: [],
            },
          ],
        },
        {
          label: 'List 2',
          color: 'w',
          sections: [
            {
              label: 'Card',
              cards: [{ name: 'Ponder', color: 'u' }],
            },
          ],
        },
        {
          label: 'List 3',
          color: 'w',
          sections: [
            {
              label: 'Card',
              cards: [{ name: 'Lightning Bolt', color: 'r' }],
            },
          ],
        },
        {
          label: 'List 4',
          color: 'w',
          sections: [
            {
              label: 'Cards',
              cards: [
                { name: 'Ponder', color: 'u' },
                { name: 'Lightning Bolt', color: 'r' },
              ],
            },
          ],
        },
      ]}
    />
  )
}

export default ContrivedDecklists
