import { CardHeading } from "../../../../src/data/articles/usmans-mh-3-cube-commentary/CardHeading.tsx";
import { MDFCdeck } from "../../../../src/data/articles/usmans-mh-3-cube-commentary/MDFCdeck.tsx";
import { SimicDeck } from "../../../../src/data/articles/usmans-mh-3-cube-commentary/SimicDeck.tsx";
import { GruulDeck } from "../../../../src/data/articles/usmans-mh-3-cube-commentary/GruulDeck.tsx";
import { WUBRdeck } from "../../../../src/data/articles/usmans-mh-3-cube-commentary/WUBRdeck.tsx";
import * as React from 'react';
export default {
  CardHeading,
  MDFCdeck,
  SimicDeck,
  GruulDeck,
  WUBRdeck,
  React
};