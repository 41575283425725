import * as styles from './Tooltip.module.scss'

import React from 'react'

interface Props {
  data: {
    color: string
    name: string
    cardsIncluded: number
    responseCount: number
  }[]
}

export const Tooltip: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <div className={styles.container}>
      <div className={styles.mechanics}>
        {data.map((mechanic, index) => (
          <div key={index} className={styles.mechanic}>
            <div
              className={styles.icon}
              style={{ backgroundColor: mechanic.color }}
            />
            {mechanic.name} - {mechanic.responseCount}
          </div>
        ))}
      </div>
    </div>
  )
}
