import * as styles from './BannerRow.module.scss'

import React from 'react'
import { Link } from 'gatsby'

interface Props {
  to: string
  title: string
  subtitle: string
  backgroundImage: string
}

const BannerRow: React.FC<Props> = (props) => {
  const { to, title, subtitle, backgroundImage } = props

  return (
    <Link to={to} className={styles.container}>
      <img className={styles.background} src={backgroundImage} alt="" />
      <div className={styles.title}>{title}</div>
      <p className={styles.subtitle}>{subtitle}</p>
    </Link>
  )
}

export default BannerRow
