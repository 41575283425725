import React from 'react'

import CardSet from 'components/article-elements/card-set/CardSet'

const DesertedTowerList: React.FC = () => {
  return (
    <CardSet
      columns={[
        {
          label: 'White',
          color: 'w',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Resilient Wanderer',
                  set: 'ody',
                },
                {
                  name: 'Deftblade Elite',
                  set: 'lgn',
                },
                {
                  name: 'Benevolent Bodyguard',
                  set: 'jud',
                },
                {
                  name: 'Angelic Page',
                  set: 'usg',
                },
                {
                  name: 'Longbow Archer',
                  set: 'vis',
                },
                {
                  name: 'Benalish Knight',
                  set: 'wth',
                },
                {
                  name: 'Pianna, Nomad Captain',
                  set: 'ody',
                },
                {
                  name: 'Youthful Knight',
                  set: 'sth',
                },
                {
                  name: 'Mystic Penitent',
                  set: 'ody',
                },
                {
                  name: 'Advance Scout',
                  set: 'tmp',
                },
                {
                  name: 'Reborn Hero',
                  set: 'tor',
                },
                {
                  name: 'Nomad Decoy',
                  set: 'ody',
                },
                {
                  name: 'Mistmoon Griffin',
                  set: 'wth',
                },
                {
                  name: 'Pearl Dragon',
                  set: 'mir',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Humble',
                  set: 'usg',
                },
                {
                  name: 'Guided Strike',
                  set: 'jud',
                },
                {
                  name: 'Disenchant',
                  set: 'ice',
                },
                {
                  name: 'Smite',
                  set: 'sth',
                },
                {
                  name: 'Shelter',
                  set: 'ody',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Breath of Life',
                  set: 'p02',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Pacifism',
                  set: 'usg',
                },
                {
                  name: 'Angelic Renewal',
                  set: 'wth',
                },
                {
                  name: 'Arrest',
                  set: 'mmq',
                },
                {
                  name: 'Seal of Cleansing',
                  set: 'nem',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Secluded Steppe',
                  set: 'ons',
                },
                {
                  name: 'Ruins of Trokair',
                  set: 'fem',
                },
                {
                  name: 'Forbidding Watchtower',
                  set: 'ulg',
                },
                {
                  name: 'Abandoned Outpost',
                  set: 'ody',
                },
                {
                  name: 'Drifting Meadow',
                  set: 'usg',
                },
                {
                  name: 'Nomad Stadium',
                  set: 'ody',
                },
              ],
            },
          ],
        },
        {
          label: 'Blue',
          color: 'u',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Merfolk Looter',
                  set: 'exo',
                },
                {
                  name: 'Waterfront Bouncer',
                  set: 'mmq',
                },
                {
                  name: "Man-o'-War",
                  set: 'vis',
                },
                {
                  name: 'Cephalid Looter',
                  set: 'ody',
                },
                {
                  name: 'Rishadan Airship',
                  set: 'mmq',
                },
                {
                  name: 'Hapless Researcher',
                  set: 'jud',
                },
                {
                  name: 'Aquamoeba',
                  set: 'tor',
                },
                {
                  name: 'Cloud of Faeries',
                  set: 'ulg',
                },
                {
                  name: 'Riptide Mangler',
                  set: 'lgn',
                },
                {
                  name: 'Puppeteer',
                  set: 'ody',
                },
                {
                  name: 'Keeneye Aven',
                  set: 'lgn',
                },
                {
                  name: 'Cloud Dragon',
                  set: 'por',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Miscalculation',
                  set: 'ulg',
                },
                {
                  name: 'Complicate',
                  set: 'ons',
                },
                {
                  name: 'Mana Leak',
                  set: 'sth',
                },
                {
                  name: 'Brainstorm',
                  set: 'ice',
                },
                {
                  name: 'Opt',
                  set: 'inv',
                },
                {
                  name: 'Memory Lapse',
                  set: 'hml',
                },
                {
                  name: 'Discombobulate',
                  set: 'ons',
                },
                {
                  name: 'Arcane Denial',
                  set: 'all',
                },
                {
                  name: 'Frantic Search',
                  set: 'ulg',
                },
                {
                  name: 'Choking Tethers',
                  set: 'ons',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Deep Analysis',
                  set: 'tor',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Seal of Removal',
                  set: 'nem',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Lonely Sandbar',
                  set: 'ons',
                },
                {
                  name: 'Svyelunite Temple',
                  set: 'fem',
                },
                {
                  name: 'Faerie Conclave',
                  set: 'ulg',
                },
                {
                  name: 'Seafloor Debris',
                  set: 'ody',
                },
                {
                  name: 'Cephalid Coliseum',
                  set: 'ody',
                },
                {
                  name: 'Remote Isle',
                  set: 'usg',
                },
              ],
            },
          ],
        },
        {
          label: 'Black',
          color: 'b',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Phyrexian Rager',
                  set: 'apc',
                },
                {
                  name: 'Undead Gladiator',
                  set: 'ons',
                },
                {
                  name: 'Faceless Butcher',
                  set: 'tor',
                },
                {
                  name: 'Nantuko Husk',
                  set: 'ons',
                },
                {
                  name: 'Putrid Imp',
                  set: 'tor',
                },
                {
                  name: 'Brood of Cockroaches',
                  set: 'vis',
                },
                {
                  name: 'Bone Shredder',
                  set: 'ulg',
                },
                {
                  name: 'Gravedigger',
                  set: 'tmp',
                },
                {
                  name: 'Nekrataal',
                  set: 'vis',
                },
                {
                  name: 'Undertaker',
                  set: 'mmq',
                },
                {
                  name: 'Doomed Necromancer',
                  set: 'ons',
                },
                {
                  name: 'Catacomb Dragon',
                  set: 'mir',
                },
                {
                  name: 'Fledgling Djinn',
                  set: 'wth',
                },
                {
                  name: 'Skulking Ghost',
                  set: 'mir',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Smother',
                  set: 'ons',
                },
                {
                  name: 'Expunge',
                  set: 'usg',
                },
                {
                  name: 'Vendetta',
                  set: 'mmq',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Unearth',
                  set: 'ulg',
                },
                {
                  name: 'Morgue Theft',
                  set: 'ody',
                },
                {
                  name: 'Victimize',
                  set: 'usg',
                },
                {
                  name: "Chainer's Edict",
                  set: 'tor',
                },
                {
                  name: 'Zombify',
                  set: 'ody',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Seal of Doom',
                  set: 'nem',
                },
                {
                  name: 'Diabolic Servitude',
                  set: 'usg',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Barren Moor',
                  set: 'ons',
                },
                {
                  name: 'Ebon Stronghold',
                  set: 'fem',
                },
                {
                  name: 'Spawning Pool',
                  set: 'ulg',
                },
                {
                  name: 'Bog Wreckage',
                  set: 'ody',
                },
                {
                  name: 'Cabal Pit',
                  set: 'ody',
                },
                {
                  name: 'Polluted Mire',
                  set: 'usg',
                },
              ],
            },
          ],
        },
        {
          label: 'Red',
          color: 'r',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Keldon Champion',
                  set: 'uds',
                },
                {
                  name: 'Arc Mage',
                  set: 'nem',
                },
                {
                  name: 'Goblin Patrol',
                  set: 'usg',
                },
                {
                  name: 'Mogg Fanatic',
                  set: 'tmp',
                },
                {
                  name: 'Mogg Maniac',
                  set: 'sth',
                },
                {
                  name: 'Pardic Arsonist',
                  set: 'tor',
                },
                {
                  name: 'Ghitu Slinger',
                  set: 'ulg',
                },
                {
                  name: 'Bloodrock Cyclops',
                  set: 'wth',
                },
                {
                  name: 'Volcanic Dragon',
                  set: 'mir',
                },
                {
                  name: 'Goblin War Buggy',
                  set: 'usg',
                },
                {
                  name: 'Fireslinger',
                  set: 'tmp',
                },
                {
                  name: 'Goblin Brigand',
                  set: 'scg',
                },
                {
                  name: 'Grim Lavamancer',
                  set: 'tor',
                },
                {
                  name: 'Kris Mage',
                  set: 'mmq',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Solar Blast',
                  set: 'ons',
                },
                {
                  name: 'Incinerate',
                  set: 'ice',
                },
                {
                  name: 'Fiery Temper',
                  set: 'tor',
                },
                {
                  name: 'Shock',
                  set: 'sth',
                },
                {
                  name: 'Carbonize',
                  set: 'scg',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Arc Lightning',
                  set: 'usg',
                },
                {
                  name: 'Firebolt',
                  set: 'ody',
                },
                {
                  name: 'Reckless Charge',
                  set: 'ody',
                },
                {
                  name: 'Volcanic Hammer',
                  set: 'por',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Seal of Fire',
                  set: 'nem',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Forgotten Cave',
                  set: 'ons',
                },
                {
                  name: 'Dwarven Ruins',
                  set: 'fem',
                },
                {
                  name: 'Ghitu Encampment',
                  set: 'ulg',
                },
                {
                  name: 'Ravaged Highlands',
                  set: 'ody',
                },
                {
                  name: 'Barbarian Ring',
                  set: 'ody',
                },
                {
                  name: 'Smoldering Crater',
                  set: 'usg',
                },
              ],
            },
          ],
        },
        {
          label: 'Green',
          color: 'g',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Centaur Chieftain',
                  set: 'tor',
                },
                {
                  name: 'Nimble Mongoose',
                  set: 'ody',
                },
                {
                  name: 'Basking Rootwalla',
                  set: 'tor',
                },
                {
                  name: 'Arrogant Wurm',
                  set: 'tor',
                },
                {
                  name: 'Pouncing Jaguar',
                  set: 'usg',
                },
                {
                  name: 'Wild Mongrel',
                  set: 'ody',
                },
                {
                  name: "Seton's Scout",
                  set: 'tor',
                },
                {
                  name: 'Lone Wolf',
                  set: 'ulg',
                },
                {
                  name: 'Wild Dogs',
                  set: 'usg',
                },
                {
                  name: 'Canopy Dragon',
                  set: 'mir',
                },
                {
                  name: 'Simian Grunts',
                  set: 'ulg',
                },
                {
                  name: 'Werebear',
                  set: 'ody',
                },
                {
                  name: 'Heart Warden',
                  set: 'uds',
                },
                {
                  name: 'Cartographer',
                  set: 'ody',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Reclaim',
                  set: 'exo',
                },
                {
                  name: 'Naturalize',
                  set: 'ons',
                },
                {
                  name: 'Lull',
                  set: 'usg',
                },
                {
                  name: 'Primal Boost',
                  set: 'ons',
                },
                {
                  name: 'Aggressive Urge',
                  set: 'inv',
                },
                {
                  name: 'Sylvan Might',
                  set: 'ody',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Mulch',
                  set: 'sth',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Exoskeletal Armor',
                  set: 'jud',
                },
                {
                  name: 'Narcissism',
                  set: 'tor',
                },
                {
                  name: 'Seal of Strength',
                  set: 'nem',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Tranquil Thicket',
                  set: 'ons',
                },
                {
                  name: 'Havenwood Battleground',
                  set: 'fem',
                },
                {
                  name: 'Treetop Village',
                  set: 'ulg',
                },
                {
                  name: 'Timberland Ruins',
                  set: 'ody',
                },
                {
                  name: 'Centaur Garden',
                  set: 'ody',
                },
                {
                  name: 'Slippery Karst',
                  set: 'usg',
                },
              ],
            },
          ],
        },
        {
          label: 'Multicolor',
          color: 'm',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Pyre Zombie',
                  set: 'inv',
                },
                {
                  name: 'Voracious Cobra',
                  set: 'inv',
                },
                {
                  name: 'Anurid Brushhopper',
                  set: 'jud',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Consume Strength',
                  set: 'apc',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Death Grasp',
                  set: 'apc',
                },
                {
                  name: 'Diabolic Vision',
                  set: 'ice',
                },
                {
                  name: 'Temporal Spring',
                  set: 'apc',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: "Squee's Embrace",
                  set: 'apc',
                },
                {
                  name: 'Quicksilver Dagger',
                  set: 'apc',
                },
                {
                  name: 'Wings of Aesthir',
                  set: 'ice',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Caldera Lake',
                  set: 'tmp',
                },
                {
                  name: 'Salt Flats',
                  set: 'tmp',
                },
                {
                  name: 'Pine Barrens',
                  set: 'tmp',
                },
                {
                  name: 'Scabland',
                  set: 'tmp',
                },
                {
                  name: 'Skyshroud Forest',
                  set: 'tmp',
                },
                {
                  name: 'Geothermal Crevice',
                  set: 'inv',
                },
                {
                  name: 'Ancient Spring',
                  set: 'inv',
                },
                {
                  name: 'Irrigation Ditch',
                  set: 'inv',
                },
                {
                  name: 'Sulfur Vent',
                  set: 'inv',
                },
                {
                  name: 'Tinder Farm',
                  set: 'inv',
                },
                {
                  name: 'Coastal Tower',
                  set: 'inv',
                },
                {
                  name: 'Urborg Volcano',
                  set: 'inv',
                },
                {
                  name: 'Elfhame Palace',
                  set: 'inv',
                },
                {
                  name: 'Salt Marsh',
                  set: 'inv',
                },
                {
                  name: 'Shivan Oasis',
                  set: 'inv',
                },
                {
                  name: 'Archaeological Dig',
                  set: 'inv',
                },
                {
                  name: 'Grand Coliseum',
                  set: 'ons',
                },
              ],
            },
          ],
        },
        {
          label: 'Lands',
          color: 'l',
          sections: [
            {
              label: 'Utility Lands',
              cards: [
                {
                  name: 'Blasted Landscape',
                  set: 'usg',
                },
                {
                  name: 'Quicksand',
                  set: 'vis',
                },
                {
                  name: 'Stalking Stones',
                  set: 'tmp',
                },
              ],
            },
            {
              label: 'Basic Lands',
              cards: [
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Plains',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Island',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Swamp',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Mountain',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
                {
                  name: 'Forest',
                  set: 'ons',
                },
              ],
            },
          ],
        },
      ]}
    />
  )
}

export default DesertedTowerList
