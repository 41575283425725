import * as styles from './Response.module.scss'

import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { isPresentString } from 'utils/isPresentString'
import { cubeIDFromLink, urlForCube } from 'utils/cube-cobra'

import Indicator from 'components/loading/Indicator'
import CardHover from 'components/cards/CardHover'

import { Set, Response as ResponseType } from './data/types'

interface Props {
  set: Set
  response: ResponseType
  cube?: {
    id: string
    name?: string
    cards?: string[]
  }
  cards: {
    name: string
    inCube?: boolean
    rating: number | null
  }[]
}

export const Response: React.FC<Props> = (props) => {
  const { set, response, cards, cube } = props

  const loading = cube == null && cubeIDFromLink(response.cubeLink)

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loadingIndicator}>
          <Indicator />
        </div>
      )}

      <div>
        <div className={styles.title}>
          {cube != null && isPresentString(cube?.name) ? (
            <a href={urlForCube(cube.id)} target="__blank">
              {cube.name}
            </a>
          ) : isPresentString(response.cubeLink) ? (
            <>{response.cubeLink}</>
          ) : (
            <span className={styles.missingName}>Unknown Cube</span>
          )}{' '}
          -{' '}
          {set.articleSlug ? (
            <Link to={`/articles/${set.articleSlug}`}>{set.name}</Link>
          ) : (
            set.name
          )}
        </div>
      </div>

      <div className={styles.ratings}>
        {cards.map((card) => (
          <div
            key={card.name}
            className={classNames(styles.rating, {
              [styles.inCube]: card.inCube === true,
              [styles.notInCube]: card.inCube === false,
              [styles.unrated]: card.rating == null,
            })}
          >
            <CardHover card={card.name} set={response.set}>
              {card.name}
            </CardHover>{' '}
            {card.rating != null ? (
              <>- {card.rating}</>
            ) : (
              <span className={styles.flag}>Added</span>
            )}{' '}
            {card.inCube === false && (
              <span className={styles.flag}>Removed</span>
            )}
          </div>
        ))}
      </div>

      {response.additionalComments && (
        <div className={styles.comments}>{response.additionalComments}</div>
      )}
    </div>
  )
}
