import * as styles from './Field.module.scss'

import React from 'react'

interface Props {
  label: string
  name?: string
  hint?: string
  children: React.ReactNode
}

export const Field: React.FC<Props> = (props) => {
  const { label, name, hint, children } = props

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <div>{children}</div>
      {hint != null && <div className={styles.hint}>{hint}</div>}
    </div>
  )
}
