export const sets = [
  {
    code: 'eld',
    name: 'Throne of Eldraine',
    path: '/articles/cube-prospective-throne-of-eldraine/',
    iconURL: 'https://svgs.scryfall.io/sets/eld.svg?1655092800',
  },
  {
    code: 'thb',
    name: 'Theros: Beyond Death',
    path: '/articles/cube-prospective-theros-beyond-death/',
    iconURL: 'https://svgs.scryfall.io/sets/thb.svg?1655092800',
  },
  {
    code: 'iko',
    name: 'Ikoria: Lair of Behemoths',
    path: '/articles/cube-prospective-ikoria-lair-of-behemoths/',
    iconURL: 'https://svgs.scryfall.io/sets/iko.svg?1655092800',
  },
  {
    code: 'c20',
    name: 'Commander 2020',
    path: '/articles/cube-prospective-commander-2020/',
    iconURL: 'https://svgs.scryfall.io/sets/c20.svg?1655092800',
  },
  {
    code: 'm21',
    name: 'Core Set 2021',
    path: '/articles/cube-prospective-core-set-2021/',
    iconURL: 'https://svgs.scryfall.io/sets/m21.svg?1655092800',
  },
  {
    code: 'jmp',
    name: 'Jumpstart',
    path: '/articles/cube-prospective-jumpstart/',
    iconURL: 'https://svgs.scryfall.io/sets/jmp.svg?1655092800',
  },
]
