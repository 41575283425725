import MapLinks from "../../../../src/data/articles/mapping-the-magic-landscape/MapLinks.tsx";
import CardsAsDimensions from "../../../../src/data/articles/mapping-the-magic-landscape/CardsAsDimensions.tsx";
import LinearDimensionalReductionPlot from "../../../../src/data/articles/mapping-the-magic-landscape/LinearDimensionalReductionPlot.tsx";
import ExampleLists from "../../../../src/data/articles/mapping-the-magic-landscape/ExampleLists.tsx";
import ScatterPlot from "../../../../src/components/visualization/ScatterPlot.tsx";
import PCAPlotCommander from "../../../../src/data/articles/mapping-the-magic-landscape/PCAPlotCommander.tsx";
import PCAPlotCube from "../../../../src/data/articles/mapping-the-magic-landscape/PCAPlotCube.tsx";
import SpiralPlot from "../../../../src/data/articles/mapping-the-magic-landscape/SpiralPlot.tsx";
import ClusterPlot from "../../../../src/data/articles/mapping-the-magic-landscape/ClusterPlot.tsx";
import * as React from 'react';
export default {
  MapLinks,
  CardsAsDimensions,
  LinearDimensionalReductionPlot,
  ExampleLists,
  ScatterPlot,
  PCAPlotCommander,
  PCAPlotCube,
  SpiralPlot,
  ClusterPlot,
  React
};