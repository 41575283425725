import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'

export const AltTimeline: React.FC = () => {
  return (
    <Decklist
      label="Alternate Timeline"
      labelLink="https://cubecobra.com/cube/overview/alternatetimeline"
      name="Monday Afternoon Magic"
      description={
        <>
          This deck captured the essence of how I felt about the game as a
          beginning magician.
        </>
      }
      maindeck={[
        [
          { name: 'Brushland', set: 'BRO' },
          { name: 'Brushland', set: 'BRO' },
          { name: 'Battlefield Forge', set: 'BRO' },
          { name: 'Temple Garden', set: 'RTR' },
          { name: 'Sacred Foundry', set: 'GTC' },
          { name: 'Stomping Ground', set: 'GTC' },
          { name: 'Forest', set: 'TSP' },
          { name: 'Forest', set: 'TSP' },
          { name: 'Plains', set: 'TSP' },
          { name: 'Plains', set: 'TSP' },
          { name: 'Plains', set: 'TSP' },
          { name: 'Plains', set: 'TSP' },
          { name: 'Mountain', set: 'TSP' },
          { name: 'Mountain', set: 'TSP' },
          { name: 'Mountain', set: 'TSP' },
          { name: 'Mountain', set: 'TSP' },
          { name: 'Mountain', set: 'TSP' },
        ],
        [
          { name: 'Abundant Harvest' },
          { name: 'Path to Exile' },
          { name: 'Burst Lightning' },
          { name: 'Flame Slash' },
        ],
        [
          { name: 'Young Pyromancer' },
          { name: 'Kor Spiritdancer', set: 'ROE' },
          { name: 'Hero of Iroas' },
          { name: 'Deeproot Champion', set: 'XLN' },
          { name: 'Champion of the Flame' },
          { name: 'Argothian Enchantress' },
          { name: 'Phyrexian Revoker', set: 'MBS' },
          { name: 'Cathartic Pyre' },
          { name: 'Seal Away' },
          { name: 'Abrade' },
          { name: 'Journey to Nowhere' },
          { name: "Tilonalli's Crown" },
        ],
        [
          { name: 'Auramancer' },
          { name: "Sevinne's Reclamation" },
          { name: 'Spikeshot Goblin' },
          { name: 'Vithian Stinger' },
          { name: 'Spawnwrithe' },
          { name: 'Draconic Destiny', set: 'BRO' },
        ],
        [{ name: "Wren's Run Hydra" }],
      ]}
    />
  )
}
