import * as styles from './MechanicsGraph.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import * as d3 from 'd3'

import { Size, EdgeInsets } from 'utils/geometry'

import CardHover from 'components/cards/CardHover'

import { Tooltip } from './Tooltip'

const width = 800
const height = 380

interface Props {
  data: {
    name: string
    responsesPerCount: {
      cardsIncluded: number
      responseCount: number
      cards: {
        name: string
        count: number
        rarity: string
      }[]
    }[]
  }[]
  colors: string[]
}

/**
 * Bar graph for showing the number of responses including each number of cards
 * broken down by mechanics. Selecting a bar shows the list of cards included
 * in responses with that count.
 *
 * To generate a data set use the script at
 * scripts/prospective/analyze-response-themes.ts
 *
 * Some of this is tailored specifically to the ONE data and needs to be
 * abstracted to accommodate other data sets.
 */
export const MechanicsGraph: React.FC<Props> = (props) => {
  const { data, colors } = props

  const counts = useMemo(() => {
    return Array(24)
      .fill(0)
      .map((_, i) => i)
  }, [])

  const graphData = useMemo(() => {
    return data.map((series, index) => {
      return {
        ...series,
        color: colors[index],
      }
    })
  }, [colors, data])

  const margins = useMemo(() => new EdgeInsets(0, 0, 55, 55), [])

  const bounds = new Size(width, height).rect

  const contentRect = useMemo(() => {
    return bounds.inset(margins)
  }, [bounds, margins])

  const scale = useMemo(() => {
    const x = d3
      .scaleLinear()
      .domain([0, counts.length])
      .range([contentRect.x, contentRect.maxX])
      .nice()

    const allValues = data.flatMap((series) =>
      series.responsesPerCount.map((count) => count.responseCount),
    )
    const y = d3
      .scaleLog()
      .domain([0.5, d3.max(allValues)!])
      .range([contentRect.maxY, contentRect.y])

    return { x, y }
  }, [
    contentRect.maxX,
    contentRect.maxY,
    contentRect.x,
    contentRect.y,
    counts.length,
    data,
  ])

  const intervalWidth = contentRect.width / counts.length

  const barPadding = 3
  const barSpacing = 1
  const barWidth =
    (intervalWidth - barPadding * 2 - barSpacing * (graphData.length - 1)) /
    graphData.length

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const [cursorPosition, setCursorPosition] = useState([0, 0])

  const onMouseMove = useCallback((event: React.MouseEvent) => {
    setCursorPosition([event.clientX, event.clientY])
  }, [])

  const tooltipData = useMemo(() => {
    return hoveredIndex != null
      ? graphData.map((mechanic) => ({
          color: mechanic.color,
          name: mechanic.name,
          ...mechanic.responsesPerCount[hoveredIndex],
        }))
      : []
  }, [graphData, hoveredIndex])

  return (
    <div className={styles.container}>
      <div className={styles.graph}>
        <div className={styles.graphContainer}>
          <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            className={styles.svg}
            onMouseMove={onMouseMove}
          >
            {counts.map((count) => (
              <>
                <rect
                  key={count}
                  x={scale.x(count)}
                  y={contentRect.y}
                  width={intervalWidth}
                  height={contentRect.height + 32}
                  onMouseEnter={() => setHoveredIndex(count)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => setSelectedIndex(count)}
                  className={classNames(styles.backgroundBar, {
                    [styles.hovered]: count === hoveredIndex,
                    [styles.selected]: count === selectedIndex,
                  })}
                />

                <text
                  x={scale.x(count) + intervalWidth / 2}
                  y={contentRect.maxY + 20}
                  textAnchor="middle"
                  className={styles.tickLabel}
                >
                  {count}
                </text>

                {graphData.map((mechanic, mechanicIndex) => {
                  const { responseCount } = mechanic.responsesPerCount[count]

                  const x =
                    scale.x(count) +
                    mechanicIndex * barWidth +
                    barPadding +
                    mechanicIndex * barSpacing

                  return (
                    <>
                      <rect
                        key={mechanicIndex}
                        x={x}
                        y={scale.y(responseCount)}
                        width={barWidth}
                        height={contentRect.height - scale.y(responseCount)}
                        fill={mechanic.color}
                        className={styles.bar}
                      />
                    </>
                  )
                })}
              </>
            ))}

            <text
              x={contentRect.midX}
              y={contentRect.maxY + 50}
              textAnchor="middle"
              className={styles.axisLabel}
            >
              Cards Tested with Set Mechanics
            </text>

            <text
              textAnchor="middle"
              transform={`translate(${contentRect.x - 40} ${
                contentRect.midY
              }) rotate(-90)`}
              className={styles.axisLabel}
            >
              Number of Responses{' '}
              <tspan className={styles.unit}>(log 10)</tspan>
            </text>

            {[10, 100].map((value) => (
              <text
                key={value}
                textAnchor="middle"
                transform={`translate(${contentRect.x - 10} ${
                  contentRect.y + scale.y(value)
                }) rotate(-90)`}
                className={styles.tickLabel}
              >
                {value}
              </text>
            ))}

            {[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90,
              100, 110,
            ].map((value) => (
              <line
                key={value}
                x1={contentRect.x - 5}
                y1={contentRect.y + scale.y(value)}
                x2={contentRect.x}
                y2={contentRect.y + scale.y(value)}
                stroke="black"
                strokeWidth={2}
              />
            ))}
          </svg>
        </div>
      </div>

      {selectedIndex != null ? (
        <div className={styles.cardList}>
          <div className={styles.listHeading}>
            Tested by respondents with {selectedIndex}{' '}
            {selectedIndex === 1 ? 'card' : 'cards'} with set mechanics:
          </div>

          {graphData.map((mechanic, index) => (
            <div key={index} className={styles.mechanic}>
              <div className={styles.mechanicName}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: mechanic.color }}
                />
                {mechanic.name}
              </div>

              {mechanic.responsesPerCount[selectedIndex].cards.length > 0 ? (
                <div className={styles.cards}>
                  {mechanic.responsesPerCount[selectedIndex].cards.map(
                    (card, cardIndex) => (
                      <div key={cardIndex} className={styles.card}>
                        <span className={styles.cardCount}>{card.count}</span>
                        <CardHover>{card.name}</CardHover>
                      </div>
                    ),
                  )}
                </div>
              ) : (
                <div className={styles.noCards}>No Cards</div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noSelection}>Select a set to view cards</div>
      )}

      {hoveredIndex != null && (
        <div
          className={styles.tooltip}
          style={{ top: cursorPosition[1], left: cursorPosition[0] }}
        >
          <Tooltip data={tooltipData} />
        </div>
      )}
    </div>
  )
}
