import React from 'react'

interface Props {
  size: string
  color?: string
}

const Logo: React.FC<Props> = (props) => {
  const { size } = props

  const color = props.color || '#1b2433'

  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <path
        fill={color}
        d="M99.8,48.4L91.2,22l-27.3,8.9V2.1H36.1v28.7L8.8,22L0.2,48.4l27.3,8.9L10.6,80.5l22.5,16.3L50,73.6l16.9,23.2l22.5-16.3
    L72.5,57.3L99.8,48.4z M60.6,53.5l2.7,8.2l-0.9,0.6l-4.8-6.6L50,61.2l-7,5.1l-0.9-0.6L47,59L32.4,48.4l0.3-1l7.8,2.5l2.9-8.9l0,0
    l2.7-8.2h1.1v8.2h9.4h8.6l0.3,1l-7.8,2.5L60.6,53.5z"
      />
    </svg>
  )
}

export default Logo
