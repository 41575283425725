import * as styles from './Column.module.scss'

import React from 'react'
import classNames from 'classnames'

import { UnstyledCardHover } from 'components/cards/CardHover'

import { Color } from './Color'

export interface Props {
  label?: string
  color?: Color
  sections: Array<{
    label: string
    color?: Color
    cards: Array<{ name: string; set?: string; color?: Color }>
  }>
}

const Column: React.FC<Props> = (props) => {
  const { label, color, sections } = props

  return (
    <div className={styles.container}>
      {label && <h3 className={styles.label}>{label}</h3>}

      {sections.map((section, index) => (
        <div key={index}>
          <h4 className={styles.sectionLabel}>
            {section.cards.length} {section.label}
          </h4>

          {section.cards.length > 0 && (
            <div
              className={classNames(
                styles.cards,
                `color-${section.color || color || 'none'}`,
              )}
            >
              {section.cards.map((card, index) => (
                <div
                  key={index}
                  className={classNames(styles.card, {
                    [`color-${card.color || 'none'}`]: card.color,
                  })}
                >
                  <UnstyledCardHover card={card.name} set={card.set}>
                    {card.name}
                  </UnstyledCardHover>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Column
