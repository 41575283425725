import { useState, useEffect } from 'react'

export const useFetchText = (url: RequestInfo, options?: RequestInit) => {
  const [data, setData] = useState<string | null>(null)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetch(url, { ...options, signal })
      .then((response) => response.text())
      .then((data) => {
        setData(data)
      })
      .catch(() => {
        // no-op
      })

    return () => {
      controller.abort()
    }
  }, [url, options])

  return { data }
}

export const useFetchJSON = <T>(url: RequestInfo, options?: RequestInit) => {
  const [data, setData] = useState<T | null>(null)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetch(url, { ...options, signal })
      .then((response) => response.json())
      .then((data: T) => {
        setData(data)
      })

    return () => {
      controller.abort()
    }
  }, [url, options])

  return { data }
}
