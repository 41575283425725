import * as styles from './IndividualSetStatistics.module.scss'

import React, { useMemo, useState } from 'react'

import { useCSV } from 'utils/useCSV'

import { sets } from './sets'

import data from 'raw-loader!./set-statistics.csv'

import { TextButton } from 'components/controls'

import setCardPlayCounts from './set-card-play-counts.json'

import { SetStatistics } from 'components/set-retrospectives/set-statistics/SetStatistics'
import { Statistics } from 'components/set-retrospectives/set-statistics/types'

interface Props {
  setCode: string
}

const IndividualSetStatistics: React.FC<Props> = (props) => {
  const { setCode } = props

  const setStatistics = useCSV<Statistics>(data)

  const set = useMemo(() => {
    return {
      ...sets.find((set) => set.code === setCode),
      ...setStatistics?.find((set) => set.code === setCode),
      cardPlayCounts: (setCardPlayCounts as Record<string, number[]>)?.[
        setCode
      ],
    }
  }, [setCode, setStatistics])

  const [expanded, setExpanded] = useState(false)

  return (
    <div className={styles.container}>
      {expanded ? (
        <>
          <div className={styles.statistics}>
            <SetStatistics set={set as any} />
          </div>

          <TextButton onClick={() => setExpanded(false)}>
            Hide Statistics
          </TextButton>
        </>
      ) : (
        <TextButton onClick={() => setExpanded(true)}>
          Show Statistics
        </TextButton>
      )}
    </div>
  )
}

export default IndividualSetStatistics
