import * as styles from './Checkbox.module.scss'

import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const Checkbox: React.FC<Props> = (props) => {
  const { ...inputProps } = props

  return <input type="checkbox" className={styles.input} {...inputProps} />
}
