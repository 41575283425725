import React from 'react'

import data from './mechanics.json'

import { MechanicsGraph } from 'src/components/mechanics-graph/MechanicsGraph'

const colors = ['#653899', '#399997', '#bf1b6d', '#cc7a1f', '#089938']

export const LCIMechanicsGraph: React.FC = () => {
  return <MechanicsGraph data={data} colors={colors} />
}
