import { useEffect, useMemo, useState } from 'react'
import { fetchSets } from './fetchSets'
import { Set } from './types'

import { imageURLs } from './imageURL'

export function useImageURLs(cardName: string, set?: string, id?: string) {
  return useMemo(() => imageURLs({ cardName, set, id }), [cardName, set, id])
}

export function useSets() {
  const [sets, setSets] = useState<Set[] | null>(null)

  useEffect(() => {
    fetchSets().then((result) => {
      setSets(result)
    })
  }, [])

  return sets
}
