import * as styles from './CardHeading.module.scss'

import React from 'react'

import CardImage from 'components/cards/CardImage'

interface Props {
  rating: number
  card?: string
  children: string
}

export const CardHeading: React.FC<Props> = (props) => {
  const { rating, children } = props

  const card = props.card ?? children

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <CardImage canFlip>{card}</CardImage>
      </div>

      <div className={styles.info}>
        <h3>{children}</h3>
        <div className={styles.rating}>{rating}</div>
      </div>
    </div>
  )
}
