export function weightedRandom<T extends { weight: number }>(options: T[]): T {
  const weights = options.reduce((result, option, index) => {
    result.push(option.weight + (result[index - 1] ?? 0))
    return result
  }, [] as number[])

  const random = Math.random() * weights[weights.length - 1]

  return options[weights.findIndex((w) => w > random)]
}
