import * as styles from './TextButton.module.scss'

import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const TextButton: React.FC<Props> = (props) => {
  const { children, ...buttonProps } = props

  return (
    <button type="button" className={styles.button} {...buttonProps}>
      {children}
    </button>
  )
}
