import CardRankings from "../../../../src/components/card-rankings/CardRankings";
import woeRatings from "../../../../src/data/articles/cube-prospective-wilds-of-eldraine/woe-prospective-ratings.json";
import ProspectiveIntro from "../../../../src/components/article-elements/ProspectiveIntro.tsx";
import { WOEMechanicsGraph } from "../../../../src/data/articles/cube-prospective-wilds-of-eldraine/WOEMechanicsGraph.tsx";
import * as React from 'react';
export default {
  CardRankings,
  woeRatings,
  ProspectiveIntro,
  WOEMechanicsGraph,
  React
};