import * as styles from './TextInput.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  accent?: boolean
  unit?: string
}

export const TextInput: React.FC<Props> = (props) => {
  const { accent, unit, ...inputProps } = props

  return (
    <div className={styles.container}>
      <input
        type="text"
        {...inputProps}
        className={classNames(styles.input, {
          [styles.accent]: accent,
          [styles.withUnit]: unit != null,
        })}
      />
      {unit != null && <div className={styles.unit}>{unit}</div>}
    </div>
  )
}
