import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Decklist from 'components/decklists/Decklist'

export const NayaDeck: React.FC = () => {
  return (
    <HorizontalScrollingContainer>
      <Decklist
        maindeckLabel="Example Deck"
        maindeck={[
          [
            { name: 'Plains', set: 'otj', count: 7 },
            { name: 'Mountain', set: 'otj', count: 4 },
            { name: 'Arid Mesa' },
            { name: 'Windswept Heath' },
            { name: 'Razorverge Thicket' },
            { name: 'Stomping Ground' },
            { name: 'Brushland' },
            { name: 'Plateau' },
          ],

          [
            { name: 'Esper Sentinel' },
            { name: 'Firebolt' },
            { name: 'Shadowspear' },
            { name: 'Ephemerate' },
          ],

          [
            { name: 'Forth Eorlingas' },
            { name: 'Flametongue Yearling' },
            { name: 'Mawloc' },
            { name: 'Legion Extruder' },
            { name: 'Cathar Commando' },
            { name: "Collector's Cage" },
          ],

          [
            { name: 'Pre-War Formalwear' },
            { name: "Council's Judgment" },
            { name: 'Skyclave Apparition' },
            { name: 'Flickerwisp' },
            { name: 'Aven interrupter' },
            { name: 'Adeline, Resplendent Cathar' },
            { name: 'Arwen, Mortal Queen' },
            { name: 'Oltec Matterweaver' },
          ],

          [
            { name: 'Flametongue Kavu' },
            { name: 'Mine Collapse' },
            { name: 'Restoration Angel' },
          ],

          [
            { name: "Otharri, Suns' Glory" },
            { name: 'Fury' },
            { name: 'Roxanne, Starfall Savant' },
          ],
        ]}
      />
    </HorizontalScrollingContainer>
  )
}
