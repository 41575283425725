import React from 'react'

import CardSet from 'components/article-elements/card-set/CardSet'

const ExampleLists: React.FC = () => {
  return (
    <CardSet
      columns={[
        {
          label: 'List 1',
          color: 'w',
          sections: [
            {
              label: 'White',
              color: 'w',
              cards: [
                { name: 'Swords to Plowshares' },
                { name: 'Mother of Runes' },
                { name: 'Oblivion Ring' },
                { name: 'Wrath of God' },
              ],
            },
            {
              label: 'Green',
              color: 'g',
              cards: [
                { name: 'Birds of Paradise' },
                { name: 'Sylvan Library' },
                { name: 'Cultivate' },
                { name: 'Eternal Witness' },
              ],
            },
            {
              label: 'Red',
              color: 'r',
              cards: [
                { name: 'Faithless Looting' },
                { name: 'Lightning Bolt' },
                { name: 'Abrade' },
                { name: 'Chaos Warp' },
              ],
            },
            {
              label: 'Black',
              color: 'b',
              cards: [
                { name: 'Dark Ritual' },
                { name: 'Demonic Tutor' },
                { name: 'Toxic Deluge' },
                { name: 'Ravenous Chupacabra' },
              ],
            },
          ],
        },
        {
          label: 'List 2',
          color: 'w',
          sections: [
            {
              label: 'White',
              color: 'w',
              cards: [
                { name: 'Swords to Plowshares' },
                { name: 'Mother of Runes' },
                { name: 'Oblivion Ring' },
                { name: 'Wrath of God' },
              ],
            },
            {
              label: 'Blue',
              color: 'u',
              cards: [
                { name: 'Brainstorm' },
                { name: 'Ponder' },
                { name: 'Counterspell' },
                { name: 'Dig Through Time' },
              ],
            },
            {
              label: 'Red',
              color: 'r',
              cards: [
                { name: 'Faithless Looting' },
                { name: 'Lightning Bolt' },
                { name: 'Abrade' },
                { name: 'Chaos Warp' },
              ],
            },
            {
              label: 'Black',
              color: 'b',
              cards: [
                { name: 'Dark Ritual' },
                { name: 'Demonic Tutor' },
                { name: 'Toxic Deluge' },
                { name: 'Ravenous Chupacabra' },
              ],
            },
          ],
        },
        {
          label: 'List 3',
          color: 'w',
          sections: [
            {
              label: 'Blue',
              color: 'u',
              cards: [
                { name: 'Brainstorm' },
                { name: 'Ponder' },
                { name: 'Counterspell' },
                { name: 'Dig Through Time' },
              ],
            },
            {
              label: 'Green',
              color: 'g',
              cards: [
                { name: 'Birds of Paradise' },
                { name: 'Sylvan Library' },
                { name: 'Cultivate' },
                { name: 'Eternal Witness' },
              ],
            },
            {
              label: 'Red',
              color: 'r',
              cards: [
                { name: 'Faithless Looting' },
                { name: 'Lightning Bolt' },
                { name: 'Abrade' },
                { name: 'Chaos Warp' },
              ],
            },
            {
              label: 'Black',
              color: 'b',
              cards: [
                { name: 'Dark Ritual' },
                { name: 'Demonic Tutor' },
                { name: 'Toxic Deluge' },
                { name: 'Ravenous Chupacabra' },
              ],
            },
          ],
        },
      ]}
    />
  )
}

export default ExampleLists
