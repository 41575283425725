import * as styles from './GroupLabel.module.scss'

import React from 'react'

interface Props {
  children: string
}

export const GroupLabel: React.FC<Props> = (props) => {
  const { children } = props

  return <div className={styles.label}>{children}</div>
}
