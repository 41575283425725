import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'

const Chan: React.FC = () => {
  return (
    <Decklist
      name="2007 Invitational Cube Draft"
      drafter="Tiago Chan"
      description={
        <>
          Chan, based in Portugal at the time, would later admit relative
          inexperience in the North American fan-made format known as Cube, but
          his 1-2 record was enough to keep him in contention until he won the
          2007 Invitational.
        </>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Swamp', set: 'TMP' },
          { name: 'Reflecting Pool', set: 'TMP' },
          { name: 'Kjeldoran Outpost', set: 'ALL' },
        ],
        [
          { name: 'Mother of Runes', set: 'ULG' },
          { name: 'Tithe' },
          { name: 'Weathered Wayfarer', set: 'ONS' },
          { name: 'Genju of the Fields', set: 'BOK' },
        ],
        [
          { name: 'Ronom Unicorn', set: 'CSP' },
          { name: 'Kami of Ancient Law', set: 'CHK' },
          { name: 'Merfolk Looter', set: 'EXO' },
          { name: 'Mana Drain', set: 'LEG' },
          { name: 'Eight-and-a-Half-Tails', set: 'CHK' },
          { name: 'Counterspell', set: 'ICE' },
          { name: 'Mind Twist', set: '4ED' },
          { name: 'Disenchant', set: 'MMQ' },
          { name: 'Mind Stone', set: 'WTH' },
        ],
        [
          { name: 'Solemn Simulacrum', set: 'MRD' },
          { name: 'Eternal Dragon', set: 'SCG' },
          { name: 'Duplicant', set: 'MRD' },
        ],
        [
          { name: 'Phyrexian Processor', set: 'USG' },
          { name: "Akroma's Vengeance", set: 'ONS' },
          { name: 'Vedalken Shackles', set: '5DN' },
          { name: 'Opposition', set: 'UDS' },
        ],
      ]}
    />
  )
}

export default Chan
