export function getNodeText(node: React.ReactNode): string | null {
  if (typeof node === 'string') {
    return node
  }
  if (typeof node === 'number') {
    return node.toString()
  }
  if (node instanceof Array) {
    return node.map(getNodeText).join('')
  }
  if (typeof node === 'object' && node) {
    return getNodeText((node as any).props.children)
  }
  return null
}
