import React, { useState } from 'react'

import ScatterPlot from 'components/visualization/ScatterPlot'

import * as Typography from 'components/typography'

const LinearDimensionalReductionPlot: React.FC = () => {
  const [showDimension, setShowDimension] = useState(false)

  return (
    <>
      <ScatterPlot
        data={[
          [71, 75],
          [101, 103],
          [76, 75],
          [97, 94],
          [43, 35],
          [28, 25],
          [38, 36],
          [77, 74],
          [95, 93],
          [71, 75],
          [16, 13],
          [43, 41],
          [-1, -2],
          [12, 11],
          [5, 3],
          [88, 87],
          [12, 11],
          [54, 54],
          [6, 11],
          [89, 92],
          [65, 70],
          [75, 68],
          [36, 36],
          [24, 24],
          [14, 13],
          [70, 64],
          [85, 86],
          [61, 55],
          [57, 52],
          [72, 74],
          [28, 36],
          [11, 20],
          [2, 6],
          [96, 93],
          [40, 37],
          [30, 33],
          [39, 36],
          [4, 12],
          [60, 57],
          [69, 74],
          [10, 8],
          [33, 35],
          [17, 20],
          [4, 2],
          [2, -3],
          [67, 66],
          [21, 26],
          [51, 53],
          [57, 54],
          [21, 22],
          [22, 24],
          [68, 74],
          [21, 23],
          [79, 77],
          [60, 51],
          [48, 50],
          [78, 74],
          [57, 53],
          [64, 62],
          [94, 100],
          [80, 79],
          [81, 80],
          [5, 0],
          [50, 55],
          [8, 10],
          [6, 7],
          [32, 39],
          [41, 36],
          [49, 48],
          [71, 69],
          [40, 33],
          [25, 26],
          [44, 45],
          [59, 66],
          [24, 33],
          [9, 8],
          [58, 56],
          [10, 10],
          [60, 65],
          [70, 70],
          [47, 42],
          [65, 65],
          [57, 61],
          [1, 4],
          [82, 77],
          [48, 53],
          [80, 79],
          [60, 63],
          [6, 4],
          [79, 79],
          [35, 34],
          [55, 53],
          [28, 28],
          [22, 27],
          [85, 90],
          [92, 87],
          [15, 20],
          [71, 76],
          [78, 76],
          [46, 45],
        ]}
        horizontalAxisLabel="Dimension 1"
        verticalAxisLabel="Dimension 2"
        paths={showDimension ? ['M0,0L100,100'] : undefined}
      />
      <Typography.TextBlock>
        <button
          type="button"
          onClick={() => setShowDimension((value) => !value)}
        >
          Reveal best dimension
        </button>
      </Typography.TextBlock>
    </>
  )
}

export default LinearDimensionalReductionPlot
