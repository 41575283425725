import * as styles from './PrimaryHeading.module.scss'

import React, { useMemo } from 'react'
import { kebabCase } from 'lodash'

interface Props {
  children: React.ReactNode
}

const PrimaryHeading: React.FC<Props> = (props) => {
  const { children } = props

  const slug = useMemo(() => {
    return typeof children === 'string' ? kebabCase(children) : null
  }, [children])

  return (
    <div className={styles.heading} id={slug ?? undefined}>
      {children}
    </div>
  )
}

export default PrimaryHeading
