import * as styles from './SinglePath.module.scss'

import React, { useState } from 'react'
import { random } from 'lodash'

import { TreeDiagram, TreeNode } from './TreeDiagram'

export const SinglePath: React.FC = () => {
  const [tree, setTree] = useState(makeTree(6))

  return (
    <div className={styles.container} onClick={() => setTree(makeTree(6))}>
      <div className={styles.tree}>
        <TreeDiagram data={tree} />
      </div>
    </div>
  )
}

function makeTree(height: number, depth = 0, highlight = true): TreeNode {
  const childCount = depth === 0 ? 3 : Math.floor(random(1.9, 3.3, true))
  const highlightIndex = highlight ? random(0, childCount - 1) : -1
  const children =
    height > 1
      ? Array.from(Array(childCount)).map((_, index) =>
          makeTree(height - 1, depth + 1, index === highlightIndex),
        )
      : undefined

  return {
    name: 'node',
    highlight,
    children,
  }
}
