import CardRankings from "../../../../src/components/card-rankings/CardRankings";
import mh3Ratings from "../../../../src/data/articles/cube-prospective-modern-horizons-3/mh3-prospective-ratings.json";
import ProspectiveIntro from "../../../../src/components/article-elements/ProspectiveIntro.tsx";
import EldraziSeed from "../../../../src/data/articles/cube-prospective-modern-horizons-3/EldraziSeed.tsx";
import * as React from 'react';
export default {
  CardRankings,
  mh3Ratings,
  ProspectiveIntro,
  EldraziSeed,
  React
};