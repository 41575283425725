import * as styles from './Controls.module.scss'

import React from 'react'
import { upperFirst } from 'lodash'

import { rarities, RaritySelectionState } from './rarities'

interface Props {
  selectedRarities: RaritySelectionState
  setSelectedRarities: React.Dispatch<
    React.SetStateAction<RaritySelectionState>
  >
}

const Controls: React.FC<Props> = (props) => {
  const { selectedRarities, setSelectedRarities } = props

  return (
    <>
      <div className={styles.container}>
        <div className={styles.label}>Filter by rarity:</div>

        <div className={styles.options}>
          {rarities.map((rarity) => (
            <label key={rarity} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={selectedRarities[rarity]}
                onChange={(event) => {
                  setSelectedRarities({
                    ...selectedRarities,
                    [rarity]: event.currentTarget.checked,
                  })
                }}
              />{' '}
              {upperFirst(rarity)}
            </label>
          ))}
        </div>
      </div>
    </>
  )
}

export default Controls
