import * as styles from './DraftGraphic.module.scss'

import React from 'react'

const DraftGraphic: React.FC = () => {
  return (
    <div className={styles.container}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="493.9px"
        height="311px"
        viewBox="0 0 493.9 311"
        className={styles.svg}
      >
        <defs></defs>
        <circle cx="43.5" cy="18.5" r="18.5" />
        <circle cx="100.5" cy="18.5" r="18.5" />
        <circle cx="157.5" cy="18.5" r="18.5" />
        <circle cx="214.5" cy="18.5" r="18.5" />
        <circle cx="271.5" cy="18.5" r="18.5" />
        <circle cx="328.5" cy="18.5" r="18.5" />
        <circle cx="385.5" cy="18.5" r="18.5" />
        <circle cx="385.5" cy="74.4" r="18.5" />
        <g>
          <circle cx="442.5" cy="18.5" r="18.5" />
          <circle cx="442.5" cy="74.4" r="18.5" />
        </g>
        <line
          className={styles.line}
          x1="328.5"
          y1="74.4"
          x2="42.5"
          y2="74.4"
        />
        <path
          className={styles.line}
          d="M42.5,142.4c-18.8,0-34-15.2-34-34s15.2-34,34-34"
        />
        <path
          className={styles.line}
          d="M42.5,278.3c-18.8,0-34-15.2-34-34s15.2-34,34-34"
        />
        <path
          className={styles.line}
          d="M451.4,142.4c18.8,0,34,15.2,34,34s-15.2,34-34,34"
        />
        <line
          className={styles.line}
          x1="451.4"
          y1="142.4"
          x2="42.5"
          y2="142.4"
        />
        <line
          className={styles.line}
          x1="451.4"
          y1="210.3"
          x2="42.5"
          y2="210.3"
        />
        <line
          className={styles.line}
          x1="42.5"
          y1="278.3"
          x2="228.7"
          y2="278.3"
        />
        <polyline
          className={styles.line}
          points="204.6,302.5 228.7,278.3 204.6,254.1 "
        />
      </svg>
    </div>
  )
}

export default DraftGraphic
