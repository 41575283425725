import { Uniform } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/tree-diagrams/Uniform.tsx";
import { Linear } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/tree-diagrams/Linear.tsx";
import { SinglePath } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/tree-diagrams/SinglePath.tsx";
import { FlavorlessCards } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/flavorless-cards/FlavorlessCards.tsx";
import { RegularCubeList } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/RegularCubeList.tsx";
import { TurboCubeList } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/TurboCubeList.tsx";
import { Chaotic } from "../../../../src/data/articles/intellect-and-emotion-in-cube-design/tree-diagrams/Chaotic.tsx";
import * as React from 'react';
export default {
  Uniform,
  Linear,
  SinglePath,
  FlavorlessCards,
  RegularCubeList,
  TurboCubeList,
  Chaotic,
  React
};