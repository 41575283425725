import * as styles from './Controls.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  selectedSets: Record<string, boolean>
  setSelectedSets: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
}

const Controls: React.FC<Props> = (props) => {
  const { selectedSets, setSelectedSets } = props

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedSets({
      ...selectedSets,
      [event.currentTarget.value]: !selectedSets[event.currentTarget.value],
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.label}>Sort by change in play rate:</div>

      <div className={styles.sets}>
        {Object.keys(selectedSets).map((set) => (
          <button
            type="button"
            key={set}
            value={set}
            onClick={onClick}
            className={classNames(styles.button, {
              [styles.selected]: selectedSets[set],
            })}
          >
            {set}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Controls
