import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

export const PenroseCube: React.FC = () => {
  return (
    <Decklist
      label="Penrose Cube"
      labelLink="https://cubecobra.com/cube/list/penrose"
      name="Masticore Madness"
      description={
        <>
          After getting pummeled by{' '}
          <CardHover card="Masticore" set="uds">
            Masticore
          </CardHover>{' '}
          two days prior, I was ready to bring the pain myself.
        </>
      }
      maindeck={[
        [
          { name: 'Slagwoods Bridge', set: 'MH2' },
          { name: 'Drossforge Bridge', set: 'MH2' },
          { name: 'Sheltered Thicket', set: 'AKH' },
          { name: 'Hissing Quagmire', set: 'OGW' },
          { name: 'Swamp', set: 'RTR' },
          { name: 'Swamp', set: 'RTR' },
          { name: 'Swamp', set: 'RTR' },
          { name: 'Swamp', set: 'RTR' },
          { name: 'Forest', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
          { name: 'Mountain', set: 'RTR' },
        ],
        [
          { name: 'Squee, Goblin Nabob' },
          { name: 'Basking Rootwalla' },
          { name: 'Blazing Rootwalla' },
          { name: 'Monastery Swiftspear' },
          { name: 'Putrid Imp' },
          { name: "Wizard's Rockets" },
          { name: 'Soul-Guide Lantern' },
          { name: 'Fiery Temper', set: 'TOR' },
          { name: 'Fiery Temper', set: 'TOR' },
          { name: 'Galvanic Blast' },
        ],
        [
          { name: 'Magmatic Channeler' },
          { name: 'Scrapheap Scrounger', set: 'KLD' },
          { name: 'Sprouting Goblin' },
          { name: 'Conspiracy Theorist', set: 'STX' },
          { name: 'Cathartic Pyre' },
          { name: "Avacyn's Judgment" },
          { name: 'Murderous Cut', set: 'KTK' },
        ],
        [
          { name: 'Reckless Wurm', set: 'PLC' },
          { name: 'Mayhem Devil', set: 'WAR' },
          { name: 'Deathgorge Scavenger', set: 'XLN' },
          { name: 'Manic Vandal', set: 'M12' },
          { name: 'Grafted Wargear', set: '5DN' },
          { name: 'Price of Fame', set: 'GRN' },
          { name: 'Masticore', set: 'UDS' },
        ],
      ]}
    />
  )
}
