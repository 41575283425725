import * as styles from './SetList.module.scss'

import React from 'react'
import { Link } from 'gatsby'

interface Props {
  sets: {
    code: string
    name: string
    path: string
    iconURL: string
  }[]
}

export const SetList: React.FC<Props> = (props) => {
  const { sets } = props

  return (
    <div className={styles.container}>
      <p>
        Browse the first impressions and view the full survey data from each
        set:
      </p>

      <div className={styles.sets}>
        {sets.map((set) => (
          <Link className={styles.set} key={set.code} to={set.path}>
            <img className={styles.symbol} src={set.iconURL} alt="" />

            <div>{set.name}</div>
          </Link>
        ))}
      </div>
    </div>
  )
}
