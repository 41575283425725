import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const Hatebears: React.FC = () => {
  return (
    <Decklist
      name="Lurrus Hatebears"
      drafter="Feldmanny"
      description={
        <>
          The strength of this deck is in its sideboard, which has answers for
          every kind of threat it might come across.{' '}
          <CardHover card="Lurrus of the Dream-Den">Lurrus</CardHover>&rsquo;s
          ability is especially valuable in the <em>Degenerate Micro Cube</em>{' '}
          where it&rsquo;s much easier to run out of resources in a long game.
        </>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Swamp', set: 'TMP' },
          { name: 'Tendo Ice Bridge' },
          { name: 'Mental Misstep' },
        ],
        [
          { name: 'Mother of Runes', set: 'ULG' },
          { name: 'Giver of Runes' },
          { name: "Judge's Familiar", set: 'F13' },
          { name: 'Elixir of Immortality' },
        ],
        [
          { name: 'Thalia, Guardian of Thraben' },
          { name: 'Hushbringer' },
          { name: 'Agonizing Remorse' },
          { name: 'Brain Maggot' },
          { name: 'Kitesail Freebooter' },
        ],
      ]}
      sideboard={[
        { name: 'Giant Killer' },
        { name: 'Aegis of the Gods' },
        { name: 'Remorseful Cleric' },
        { name: 'Leonin Relic-Warder' },
        { name: 'Collective Brutality' },
        { name: 'Aether Vial' },
        { name: 'Ratchet Bomb' },
        { name: 'Thorn of Amethyst' },
        { name: 'Lurrus of the Dream-Den' },
      ]}
    />
  )
}

export default Hatebears
