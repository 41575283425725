import * as styles from './CreatureCheatMatrix.module.scss'

import React from 'react'

import CardHover from 'components/cards/CardHover'
import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'

const CreatureCheatMatrix: React.FC = () => {
  return (
    <>
      <HorizontalScrollingContainer>
        <table className={styles.container}>
          <thead>
            <tr>
              <th className={styles.labelCell}></th>
              <th className={styles.labelCell}>
                <CardHover card="Flash">Flash</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Tinker">Tinker</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Show and Tell">Show and Tell</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Reanimate">Reanimator</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Goblin Welder">Welder</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Sneak Attack">Sneak Attack</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Oath of Druids">Oath</CardHover>
              </th>
              <th className={styles.labelCell}>
                <CardHover card="Channel">Channel</CardHover>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Wurmcoil Engine">Wurmcoil Engine</CardHover>
              </td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Chancellor of the Annex">
                  Chancellor of the Annex
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Ashen Rider">Ashen Rider</CardHover>
              </td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Woodfall Primus">Woodfall Primus</CardHover>
              </td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Inkwell Leviathan">
                  Inkwell Leviathan
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Iona, Shield of Emeria">
                  Iona, Shield of Emeria
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Nullstone Gargoyle">
                  Nullstone Gargoyle
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Void Winnower">Void Winnower</CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>○</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Kozilek, Butcher of Truth">
                  Kozilek, Butcher of Truth
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>⭑</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Ulamog, the Infinite Gyre">
                  Ulamog, the Infinite Gyre
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>⭑</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Worldspine Wurm">Worldspine Wurm</CardHover>
              </td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Blightsteel Colossus">
                  Blightsteel Colossus
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}>●</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.labelCell}>
                <CardHover card="Emrakul, the Aeons Torn">
                  Emrakul, the Aeons Torn
                </CardHover>
              </td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>●</td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}></td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>⭑</td>
              <td className={styles.valueCell}>⭑</td>
            </tr>
          </tbody>
        </table>
      </HorizontalScrollingContainer>
      <div className={styles.legendContainer}>
        <div className={styles.legend}>
          <div>⭑ Premier Target</div>
          <div>● Viable Target</div>
          <div>○ Situational or Suboptimal Target</div>
        </div>
      </div>
    </>
  )
}

export default CreatureCheatMatrix
