export const sets = [
  {
    code: 'znr',
    name: 'Zendikar Rising',
    path: '/articles/cube-prospective-zendikar-rising/',
    iconURL: 'https://svgs.scryfall.io/sets/znr.svg?1655092800',
  },
  {
    code: 'cmr',
    name: 'Commander Legends',
    path: '/articles/cube-prospective-commander-legends/',
    iconURL: 'https://svgs.scryfall.io/sets/cmr.svg?1655092800',
  },
  {
    code: 'khm',
    name: 'Kaldheim',
    path: '/articles/cube-prospective-kaldheim/',
    iconURL: 'https://svgs.scryfall.io/sets/khm.svg?1655092800',
  },
  {
    code: 'stx',
    name: 'Strixhaven',
    path: '/articles/cube-prospective-strixhaven-school-of-mages/',
    iconURL: 'https://svgs.scryfall.io/sets/stx.svg?1655092800',
  },
  {
    code: 'c21',
    name: 'Commander 2021',
    path: '/articles/cube-prospective-commander-2021/',
    iconURL: 'https://svgs.scryfall.io/sets/c21.svg?1655092800',
  },
  {
    code: 'mh2',
    name: 'Modern Horizons 2',
    path: '/articles/cube-prospective-modern-horizons-2/',
    iconURL: 'https://svgs.scryfall.io/sets/mh2.svg?1655092800',
  },
  {
    code: 'afr',
    name: 'Adventures in the Forgotten Realms',
    path: '/articles/cube-prospective-adventures-in-the-forgotten-realms/',
    iconURL: 'https://svgs.scryfall.io/sets/afr.svg?1655092800',
  },
  {
    code: 'afc',
    name: 'Forgotten Realms Commander',
    path: '/articles/cube-prospective-forgotten-realms-commander/',
    iconURL: 'https://svgs.scryfall.io/sets/afc.svg?1655092800',
  },
]
