import React from 'react'

export const Offsite: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="0.75em">
      <g fill="currentColor">
        <polygon points="11.02 9.58 11.02 13.4 2.6 13.4 2.6 4.98 6.42 4.98 8.37 3.03 .65 3.03 .65 15.35 12.97 15.35 12.97 7.63 11.02 9.58" />
        <polygon points="13.5 .48 9.44 .48 9.44 2.5 11.92 2.5 4.65 9.78 6.22 11.35 13.5 4.08 13.5 6.56 15.52 6.56 15.52 2.5 15.52 .48 13.5 .48" />
      </g>
    </svg>
  )
}
