import * as styles from './Tooltip.module.scss'

import React, { useState, useMemo, useCallback } from 'react'
import ReactDOM from 'react-dom'

export const useTooltip = (Content: React.ComponentType) => {
  const [visible, setVisible] = useState(false)
  const [cursorPosition, setCursorPosition] = useState([0, 0])
  const [clientCursorPosition, setClientCursorPosition] = useState([0, 0])

  const onMouseEnter = useCallback(() => {
    setVisible(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setVisible(false)
  }, [])

  const onMouseMove = useCallback((event: React.MouseEvent) => {
    setCursorPosition([event.pageX, event.pageY])
    setClientCursorPosition([event.clientX, event.clientY])
  }, [])

  const mouseEvents = useMemo(() => {
    return {
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
    }
  }, [onMouseEnter, onMouseLeave, onMouseMove])

  const positionStyles = useMemo(() => {
    if (typeof window === 'undefined') {
      return { top: 0, left: 0 }
    }

    return clientCursorPosition[0] > window.innerWidth - 200
      ? { top: cursorPosition[1], right: window.innerWidth - cursorPosition[0] }
      : { top: cursorPosition[1], left: cursorPosition[0] }
  }, [clientCursorPosition, cursorPosition])

  const portal = useMemo(() => {
    if (!visible) {
      return null
    }

    return ReactDOM.createPortal(
      <div style={positionStyles} className={styles.tooltip}>
        <div className={styles.content}>
          <Content />
        </div>
      </div>,
      document.getElementById('modal-overlay')!,
    )
  }, [visible, positionStyles, Content])

  return useMemo(() => {
    return {
      mouseEvents,
      portal,
    }
  }, [mouseEvents, portal])
}

interface Props {
  content: React.ComponentType
  children: React.ReactNode
}

export const Tooltip: React.FC<Props> = (props) => {
  const { content, children } = props

  const { mouseEvents, portal } = useTooltip(content)

  return (
    <>
      <div {...mouseEvents}>{children}</div>
      {portal}
    </>
  )
}
