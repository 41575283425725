import * as styles from './TableHeading.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  name: string
  title?: string
  sort?: {
    column: string
    ascending: boolean
  }
  onClick?(name: string): void
  children?: React.ReactNode
  center?: boolean
  borderless?: boolean
}

const TableHeading: React.FC<Props> = (props) => {
  const {
    name,
    title,
    sort,
    children,
    onClick,
    center = false,
    borderless = false,
  } = props

  return (
    <th
      className={classNames(styles.container, {
        [styles.centered]: center,
        [styles.borderless]: borderless,
        [styles.sortable]: onClick != null,
      })}
      onClick={onClick ? () => onClick(name) : undefined}
      title={title}
    >
      {children}
      {sort && (
        <span className={styles.sortIcon}>
          {sort.column === name ? sort.ascending ? '▲' : '▼' : <>&nbsp;</>}
        </span>
      )}
    </th>
  )
}

export default TableHeading
