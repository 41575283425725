import React from 'react'

interface Props {
  color?: string
}

export const Check: React.FC<Props> = (props) => {
  const { color } = props

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      style={{ width: '100%', height: '100%' }}
    >
      <polyline
        style={{ fill: 'none', stroke: color || '#2b39ff', strokeWidth: 3 }}
        points="6.8,15.2 13.1,20.4 22.4,9.4"
      />
    </svg>
  )
}
