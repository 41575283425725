import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

export const AtomicCube: React.FC = () => {
  return (
    <Decklist
      label="Atomic Cube"
      labelLink="https://cubecobra.com/cube/overview/atomic"
      name="Glint-Eyed Greed"
      description={
        <>
          <CardHover card="Field of the Dead">Field of the Dead</CardHover> has
          a way of derailing an otherwise disciplined Jund deck. I also got to
          live the dream of{' '}
          <CardHover card="Orcish Bowmasters">Bowmaster-ing</CardHover> a{' '}
          <CardHover card="Brainstorm">Brainstorm</CardHover>.
        </>
      }
      maindeck={[
        [
          { name: 'Scalding Tarn', set: 'ZNE' },
          { name: 'Scalding Tarn', set: 'MH2' },
          { name: 'Windswept Heath', set: 'ZNE' },
          { name: 'Wooded Foothills', set: 'KTK' },
          { name: 'Misty Rainforest', set: 'MH2' },
          { name: 'Breeding Pool', set: 'DIS' },
          { name: 'Overgrown Tomb', set: 'RAV' },
          { name: 'Godless Shrine', set: 'GPT' },
          { name: 'Stomping Ground', set: 'GPT' },
          { name: 'Mountain', set: '40K' },
          { name: 'Island', set: '40K' },
          { name: 'Swamp', set: '40K' },
          { name: 'Swamp', set: '40K' },
          { name: 'Forest', set: '40K' },
          { name: 'Forest', set: '40K' },
          { name: 'Forest', set: '40K' },
          { name: 'Field of the Dead', set: 'M20' },
        ],
        [
          { name: 'Deathrite Shaman', set: 'RTR' },
          { name: 'Noble Hierarch' },
          { name: 'Knight of the Ebon Legion', set: 'M20' },
          { name: 'Grim Lavamancer' },
          { name: 'Inquisition of Kozilek' },
          { name: 'Cling to Dust' },
          { name: 'Unholy Heat' },
          { name: 'Cut Down' },
          { name: 'Fatal Push' },
        ],
        [
          { name: "Misery's Shadow" },
          { name: 'Grim Flayer', set: 'EMN' },
          { name: 'Witherbloom Command' },
          { name: 'Mosswood Dreadknight', set: 'WOE' },
          { name: 'Orcish Bowmasters' },
          { name: "Smuggler's Copter" },
          { name: 'Tarmogoyf', set: 'TSR' },
          { name: 'Territorial Kavu', set: 'MH2' },
          { name: 'Heartless Act', set: 'IKO' },
        ],
        [
          { name: 'Tireless Tracker', set: 'SOI' },
          { name: 'Mawloc', set: '40K' },
          { name: 'Liliana, the Last Hope', set: 'EMN' },
          { name: 'Grist, the Hunger Tide', set: 'MH2' },
          { name: "Uro, Titan of Nature's Wrath", set: 'THB' },
        ],
      ]}
    />
  )
}
