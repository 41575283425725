import * as styles from './Modal.module.scss'

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

import { useAccentColor } from 'utils/accent-color/AccentColorContext'

interface Props {
  children: React.ReactNode
  presented: boolean
  dismiss(): void
  buttonColor?: 'light' | 'dark'
}

export const Modal: React.FC<Props> = (props) => {
  const { children, presented, dismiss, buttonColor = 'dark' } = props

  const accentColor = useAccentColor()

  useEffect(() => {
    if (presented) {
      const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.stopPropagation()
          dismiss()
        }
      }
      const options = { capture: true }
      document.addEventListener('keydown', onKeyDown, options)

      return () => {
        document.removeEventListener('keydown', onKeyDown, options)
      }
    }
  }, [presented, dismiss])

  if (!presented) {
    return null
  }

  const domElement = document.getElementById('modal-overlay')

  if (domElement == null) {
    throw new Error('Missing modal container #modal-overlay')
  }

  return ReactDOM.createPortal(
    <div className={styles.container} style={accentColor.containerStyles}>
      <div className={styles.overlay} onClick={dismiss} />
      <div className={styles.boxWrapper}>
        <div className={styles.box} role="dialog">
          <div>{children}</div>

          <button
            className={classNames(styles.close, {
              [styles.light]: buttonColor === 'light',
              [styles.dark]: buttonColor === 'dark',
            })}
            onClick={dismiss}
          >
            ×
          </button>
        </div>
      </div>
    </div>,
    domElement,
  )
}

export default Modal
