import IndividualSetStatistics2022 from "../../../../src/data/articles/2022-standard-rotation-cube-retrospective/IndividualSetStatistics.tsx";
import CardsVisualization2022 from "../../../../src/data/articles/2022-standard-rotation-cube-retrospective/CardsVisualization.tsx";
import SetStatistics2022 from "../../../../src/data/articles/2022-standard-rotation-cube-retrospective/SetStatistics.tsx";
import SetList2022 from "../../../../src/data/articles/2022-standard-rotation-cube-retrospective/SetList.tsx";
import * as React from 'react';
export default {
  IndividualSetStatistics2022,
  CardsVisualization2022,
  SetStatistics2022,
  SetList2022,
  React
};