import React from 'react'

import { ExpandingCardSet } from 'src/components/article-elements/card-set/ExpandingCardSet'

export const PulpNouveauList: React.FC = () => {
  return (
    <ExpandingCardSet
      title="Pulp Nouveau"
      columns={[
        {
          label: 'White',
          color: 'w',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: "Stonebinder's Familiar",
                  set: 'stx',
                },
                {
                  name: 'Thraben Inspector',
                  set: 'soi',
                },
                {
                  name: 'Thraben Inspector',
                  set: 'soi',
                },
                {
                  name: 'Weathered Wayfarer',
                  set: 'ons',
                },
                {
                  name: 'Containment Priest',
                  set: 'c14',
                },
                {
                  name: 'Giada, Font of Hope',
                  set: 'snc',
                },
                {
                  name: 'Illuminator Virtuoso',
                  set: 'snc',
                },
                {
                  name: 'Professor of Symbology',
                  set: 'stx',
                },
                {
                  name: 'Professor of Symbology',
                  set: 'stx',
                },
                {
                  name: "Raffine's Informant",
                  set: 'snc',
                },
                {
                  name: "Raffine's Informant",
                  set: 'snc',
                },
                {
                  name: 'Serra Avenger',
                  set: 'tsr',
                },
                {
                  name: 'Sungold Sentinel',
                  set: 'mid',
                },
                {
                  name: 'Thalia, Guardian of Thraben',
                  set: 'dka',
                },
                {
                  name: 'Angelic Sleuth',
                  set: 'ncc',
                },
                {
                  name: 'Inspiring Overseer',
                  set: 'snc',
                },
                {
                  name: 'Resplendent Marshal',
                  set: 'khm',
                },
                {
                  name: 'Stalwart Valkyrie',
                  set: 'khm',
                },
                {
                  name: 'Scrapwork Cohort',
                  set: 'bro',
                },
                {
                  name: 'Yotian Frontliner',
                  set: 'bro',
                },
                {
                  name: 'Thraben Heretic',
                  set: 'dka',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Gods Willing',
                  set: 'ths',
                },
                {
                  name: 'Fateful Absence',
                  set: 'mid',
                },
                {
                  name: 'Study Break',
                  set: 'stx',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Guiding Voice',
                  set: 'stx',
                },
                {
                  name: 'Sunlance',
                  set: 'plc',
                },
                {
                  name: 'Declaration in Stone',
                  set: 'soi',
                },
                {
                  name: 'Recommission',
                  set: 'bro',
                },
                {
                  name: 'Reduce to Memory',
                  set: 'stx',
                },
                {
                  name: "Sevinne's Reclamation",
                  set: 'c19',
                },
              ],
            },
            {
              label: 'Artifacts',
              cards: [
                {
                  name: "Citizen's Crowbar",
                  set: 'snc',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Cast Out',
                  set: 'akh',
                },
                {
                  name: 'Conclave Tribunal',
                  set: 'grn',
                },
                {
                  name: 'Murder Investigation',
                  set: 'gtc',
                },
                {
                  name: 'Journey to Oblivion',
                  set: 'znr',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Secluded Steppe',
                  set: 'ons',
                },
                {
                  name: 'Secluded Steppe',
                  set: 'ons',
                },
                {
                  name: 'Secluded Steppe',
                  set: 'ons',
                },
              ],
            },
          ],
        },
        {
          label: 'Blue',
          color: 'u',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Benthic Biomancer',
                  set: 'rna',
                },
                {
                  name: 'Cobbled Lancer',
                  set: 'vow',
                },
                {
                  name: 'Enclave Cryptologist',
                  set: 'roe',
                },
                {
                  name: 'Pteramander',
                  set: 'rna',
                },
                {
                  name: 'Deranged Assistant',
                  set: 'isd',
                },
                {
                  name: 'Faerie Vandal',
                  set: 'eld',
                },
                {
                  name: 'Merfolk Looter',
                  set: 'a25',
                },
                {
                  name: 'Nightveil Sprite',
                  set: 'grn',
                },
                {
                  name: 'Wharf Infiltrator',
                  set: 'emn',
                },
                {
                  name: 'Dream Strix',
                  set: 'stx',
                },
                {
                  name: 'Skaab Ruinator',
                  set: 'isd',
                },
                {
                  name: 'Curator of Mysteries',
                  set: 'akh',
                },
                {
                  name: 'Body Double',
                  set: 'plc',
                },
                {
                  name: 'Drownyard Behemoth',
                  set: 'emn',
                },
                {
                  name: 'Vexing Scuttler',
                  set: 'emn',
                },
                {
                  name: 'Combat Courier',
                  set: 'bro',
                },
                {
                  name: 'Combat Courier',
                  set: 'bro',
                },
                {
                  name: 'Omen Hawker',
                  set: 'mom',
                },
                {
                  name: 'Cloudfin Raptor',
                  set: 'gtc',
                },
                {
                  name: 'Ghostly Pilferer',
                  set: 'm21',
                },
                {
                  name: 'Bazaar Trademage',
                  set: 'mh1',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Obsessive Search',
                  set: 'tor',
                },
                {
                  name: 'Thought Scour',
                  set: 'dka',
                },
                {
                  name: 'A Little Chat',
                  set: 'snc',
                },
                {
                  name: 'Just the Wind',
                  set: 'soi',
                },
                {
                  name: 'Logic Knot',
                  set: 'tsr',
                },
                {
                  name: 'Make Disappear',
                  set: 'snc',
                },
                {
                  name: 'Mission Briefing',
                  set: 'grn',
                },
                {
                  name: 'Remove Soul',
                  set: '6ed',
                },
                {
                  name: 'Unexplained Disappearance',
                  set: 'grn',
                },
                {
                  name: 'Circular Logic',
                  set: 'tor',
                },
                {
                  name: 'Divide by Zero',
                  set: 'stx',
                },
                {
                  name: 'Hieroglyphic Illumination',
                  set: 'akh',
                },
                {
                  name: 'Sinister Sabotage',
                  set: 'grn',
                },
                {
                  name: 'Thought Scour',
                  set: '2x2',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Hard Evidence',
                  set: 'mh2',
                },
                {
                  name: 'Chart a Course',
                  set: 'xln',
                },
                {
                  name: 'Deep Analysis',
                  set: 'ema',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Drake Haven',
                  set: 'akh',
                },
                {
                  name: 'Witness Protection',
                  set: 'snc',
                },
                {
                  name: 'In Too Deep',
                  set: 'ncc',
                },
              ],
            },
            {
              label: 'Planeswalkers',
              cards: [
                {
                  name: 'Jace, Cunning Castaway',
                  set: 'xln',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Lonely Sandbar',
                  set: 'ons',
                },
                {
                  name: 'Lonely Sandbar',
                  set: 'ons',
                },
                {
                  name: 'Lonely Sandbar',
                  set: 'ons',
                },
              ],
            },
          ],
        },
        {
          label: 'Black',
          color: 'b',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Ruthless Cullblade',
                  set: 'wwk',
                },
                {
                  name: 'Deadeye Tracker',
                  set: 'xln',
                },
                {
                  name: "Death's Shadow",
                  set: 'wwk',
                },
                {
                  name: 'Eyetwitch',
                  set: 'stx',
                },
                {
                  name: 'Night Market Lookout',
                  set: 'kld',
                },
                {
                  name: 'Shadow Alley Denizen',
                  set: 'gtc',
                },
                {
                  name: "Thieves' Guild Enforcer",
                  set: 'm21',
                },
                {
                  name: "Ashnod's Harvester",
                  set: 'bro',
                },
                {
                  name: 'Asylum Visitor',
                  set: 'soi',
                },
                {
                  name: 'Bloodghast',
                  set: 'zen',
                },
                {
                  name: 'Crooked Custodian',
                  set: 'snc',
                },
                {
                  name: 'Dogged Detective',
                  set: 'ncc',
                },
                {
                  name: 'Heir of Falkenrath',
                  set: 'soi',
                },
                {
                  name: 'Nullpriest of Oblivion',
                  set: 'znr',
                },
                {
                  name: 'Priest of Forgotten Gods',
                  set: 'rna',
                },
                {
                  name: 'Skirsdag High Priest',
                  set: 'isd',
                },
                {
                  name: 'Skyclave Shade',
                  set: 'znr',
                },
                {
                  name: 'Syndicate Trafficker',
                  set: 'kld',
                },
                {
                  name: 'Blood Operative',
                  set: 'grn',
                },
                {
                  name: 'Renegade Reaper',
                  set: 'khm',
                },
                {
                  name: 'Ruin Raider',
                  set: 'xln',
                },
                {
                  name: 'Sanguine Spy',
                  set: 'snc',
                },
                {
                  name: 'Braids, Cabal Minion',
                  set: 'ema',
                },
                {
                  name: 'Kitchen Imp',
                  set: 'mh2',
                },
                {
                  name: 'Cleaving Reaper',
                  set: 'khm',
                },
                {
                  name: 'Ogre Slumlord',
                  set: 'gtc',
                },
                {
                  name: 'Abundant Maw',
                  set: 'emn',
                },
                {
                  name: 'Distended Mindbender',
                  set: 'emn',
                },
                {
                  name: 'Shadow of Mortality',
                  set: 'snc',
                },
                {
                  name: 'Bloodlord of Vaasgoth',
                  set: 'pm12',
                },
                {
                  name: 'Cutthroat Contender',
                  set: 'snc',
                },
                {
                  name: 'Vampire Lacerator',
                  set: 'a25',
                },
                {
                  name: 'Shadowborn Demon',
                  set: 'm14',
                },
                {
                  name: 'Hell Mongrel',
                  set: 'mh2',
                },
                {
                  name: "Olivia's Dragoon",
                  set: 'emn',
                },
                {
                  name: 'Scorn-Blade Berserker',
                  set: 'mom',
                },
                {
                  name: 'Mari, the Killing Quill',
                  set: 'ncc',
                },
                {
                  name: 'Demonic Taskmaster',
                  set: 'avr',
                },
                {
                  name: 'Soulflayer',
                  set: 'frf',
                },
                {
                  name: 'Scrapheap Scrounger',
                  set: 'kld',
                },
                {
                  name: 'Dread Wanderer',
                  set: 'akh',
                },
                {
                  name: 'Faerie Dreamthief',
                  set: 'woe',
                },
                {
                  name: 'Ovalchase Daredevil',
                  set: '2xm',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Dark Ritual',
                  set: 'snc',
                },
                {
                  name: 'Tragic Fall',
                  set: 'mh2',
                },
                {
                  name: 'Tragic Fall',
                  set: 'mh2',
                },
                {
                  name: 'Tragic Fall',
                  set: 'mh2',
                },
                {
                  name: 'Last Gasp',
                  set: 'rav',
                },
                {
                  name: 'Price of Fame',
                  set: 'grn',
                },
                {
                  name: 'Price of Fame',
                  set: 'grn',
                },
                {
                  name: 'Price of Fame',
                  set: 'grn',
                },
                {
                  name: 'Price of Fame',
                  set: 'grn',
                },
                {
                  name: 'Village Rites',
                  set: 'sta',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Duress',
                  set: 'usg',
                },
                {
                  name: 'Grisly Sigil',
                  set: 'snc',
                },
                {
                  name: 'Unearth',
                  set: 'ulg',
                },
                {
                  name: 'Hunt for Specimens',
                  set: 'stx',
                },
                {
                  name: 'Blood for Bones',
                  set: 'm20',
                },
                {
                  name: 'Thwart the Grave',
                  set: 'znr',
                },
                {
                  name: 'Ransack the Lab',
                  set: 'mh1',
                },
              ],
            },
            {
              label: 'Artifacts',
              cards: [
                {
                  name: "Transmogrant's Crown",
                  set: 'bro',
                },
                {
                  name: 'Mukotai Soulripper',
                  set: 'neo',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Call the Bloodline',
                  set: 'soi',
                },
                {
                  name: 'Gift of Fangs',
                  set: 'vow',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Dakmor Salvage',
                  set: 'fut',
                },
              ],
            },
          ],
        },
        {
          label: 'Red',
          color: 'r',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Blazing Rootwalla',
                  set: 'mh2',
                },
                {
                  name: 'Falkenrath Gorger',
                  set: 'soi',
                },
                {
                  name: 'Falkenrath Pit Fighter',
                  set: 'mid',
                },
                {
                  name: 'Goldhound',
                  set: 'snc',
                },
                {
                  name: 'Grim Lavamancer',
                  set: 'dmr',
                },
                {
                  name: 'Insolent Neonate',
                  set: 'soi',
                },
                {
                  name: 'Unlucky Witness',
                  set: 'snc',
                },
                {
                  name: 'Unlucky Witness',
                  set: 'snc',
                },
                {
                  name: 'Flameblade Adept',
                  set: 'akh',
                },
                {
                  name: 'Conspiracy Theorist',
                  set: 'stx',
                },
                {
                  name: 'Earthshaker Khenra',
                  set: 'hou',
                },
                {
                  name: 'Magmatic Channeler',
                  set: 'znr',
                },
                {
                  name: 'Riveteers Requisitioner',
                  set: 'snc',
                },
                {
                  name: 'Young Pyromancer',
                  set: 'm14',
                },
                {
                  name: 'Feldon of the Third Path',
                  set: 'c14',
                },
                {
                  name: 'Squee, Goblin Nabob',
                  set: 'uma',
                },
                {
                  name: 'Tuktuk the Explorer',
                  set: 'roe',
                },
                {
                  name: 'Anger',
                  set: 'uma',
                },
                {
                  name: 'Jaxis, the Troublemaker',
                  set: 'snc',
                },
                {
                  name: 'Retriever Phoenix',
                  set: 'stx',
                },
                {
                  name: 'Reckless Wurm',
                  set: 'tsr',
                },
                {
                  name: 'Scrapwork Mutt',
                  set: 'bro',
                },
                {
                  name: 'Scrapwork Mutt',
                  set: 'bro',
                },
                {
                  name: 'Ox of Agonas',
                  set: 'thb',
                },
                {
                  name: 'Furyblade Vampire',
                  set: 'emn',
                },
                {
                  name: "Dragon's Rage Channeler",
                  set: 'mh2',
                },
                {
                  name: 'Skophos Reaver',
                  set: 'mh2',
                },
                {
                  name: 'Bogardan Dragonheart',
                  set: 'mh1',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Academic Dispute',
                  set: 'stx',
                },
                {
                  name: 'Fiery Temper',
                  set: 'f16',
                },
                {
                  name: 'Unholy Heat',
                  set: 'mh2',
                },
                {
                  name: 'Heartfire',
                  set: 'war',
                },
                {
                  name: 'Thrill of Possibility',
                  set: 'sta',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Demonfire',
                  set: 'dis',
                },
                {
                  name: 'Faithless Looting',
                  set: 'dka',
                },
                {
                  name: 'Mugging',
                  set: 'gtc',
                },
                {
                  name: 'Pillar of Flame',
                  set: 'avr',
                },
                {
                  name: 'Strangle',
                  set: 'snc',
                },
                {
                  name: "Avacyn's Judgment",
                  set: 'soi',
                },
                {
                  name: "Light 'Em Up",
                  set: 'snc',
                },
                {
                  name: 'Rob the Archives',
                  set: 'snc',
                },
                {
                  name: 'Igneous Inspiration',
                  set: 'stx',
                },
                {
                  name: 'Igneous Inspiration',
                  set: 'stx',
                },
                {
                  name: "Nahiri's Wrath",
                  set: 'emn',
                },
                {
                  name: 'Start from Scratch',
                  set: 'stx',
                },
              ],
            },
            {
              label: 'Artifacts',
              cards: [
                {
                  name: "Mishra's Research Desk",
                  set: 'bro',
                },
                {
                  name: "Mishra's Research Desk",
                  set: 'bro',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Bitter Reunion',
                  set: 'bro',
                },
              ],
            },
            {
              label: 'Planeswalkers',
              cards: [
                {
                  name: 'Tibalt, Rakish Instigator',
                  set: 'war',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Forgotten Cave',
                  set: 'ons',
                },
                {
                  name: 'Forgotten Cave',
                  set: 'ons',
                },
                {
                  name: 'Forgotten Cave',
                  set: 'ons',
                },
              ],
            },
          ],
        },
        {
          label: 'Green',
          color: 'g',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Jaspera Sentinel',
                  set: 'khm',
                },
                {
                  name: 'Basking Rootwalla',
                  set: 'tor',
                },
                {
                  name: 'Experiment One',
                  set: 'gtc',
                },
                {
                  name: 'Blanchwood Prowler',
                  set: 'bro',
                },
                {
                  name: 'Fauna Shaman',
                  set: 'm11',
                },
                {
                  name: 'Gala Greeters',
                  set: 'snc',
                },
                {
                  name: 'Growth-Chamber Guardian',
                  set: 'rna',
                },
                {
                  name: 'Growth-Chamber Guardian',
                  set: 'rna',
                },
                {
                  name: 'Growth-Chamber Guardian',
                  set: 'rna',
                },
                {
                  name: 'Noose Constrictor',
                  set: 'emn',
                },
                {
                  name: 'Noose Constrictor',
                  set: 'f17',
                },
                {
                  name: 'Venom Connoisseur',
                  set: 'snc',
                },
                {
                  name: 'Deathgorge Scavenger',
                  set: 'xln',
                },
                {
                  name: 'Jadelight Ranger',
                  set: 'rix',
                },
                {
                  name: 'Jewel Thief',
                  set: 'snc',
                },
                {
                  name: 'Splinterfright',
                  set: 'isd',
                },
                {
                  name: 'Elegant Entourage',
                  set: 'snc',
                },
                {
                  name: 'Kessig Cagebreakers',
                  set: 'isd',
                },
                {
                  name: 'Honored Hydra',
                  set: 'akh',
                },
                {
                  name: 'Hooting Mandrills',
                  set: 'ktk',
                },
                {
                  name: 'Ghoultree',
                  set: 'dka',
                },
                {
                  name: 'Mockery of Nature',
                  set: 'emn',
                },
                {
                  name: 'Narnam Renegade',
                  set: 'aer',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: "Bouncer's Beatdown",
                  set: 'snc',
                },
                {
                  name: 'Luxurious Libation',
                  set: 'snc',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Caravan Vigil',
                  set: 'isd',
                },
                {
                  name: 'Channel',
                  set: 'ima',
                },
                {
                  name: 'Edge of Autumn',
                  set: 'fut',
                },
                {
                  name: 'Call of the Herd',
                  set: 'ody',
                },
                {
                  name: 'Containment Breach',
                  set: 'stx',
                },
                {
                  name: 'Eldritch Evolution',
                  set: 'emn',
                },
                {
                  name: 'Life from the Loam',
                  set: 'uma',
                },
                {
                  name: 'Winding Way',
                  set: 'mh1',
                },
              ],
            },
            {
              label: 'Artifacts',
              cards: [
                {
                  name: 'Dodgy Jalopy',
                  set: 'ncc',
                },
                {
                  name: 'Birthing Pod',
                  set: 'nph',
                },
              ],
            },
            {
              label: 'Planeswalkers',
              cards: [
                {
                  name: 'Vivien on the Hunt',
                  set: 'snc',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Tranquil Thicket',
                  set: 'ons',
                },
                {
                  name: 'Tranquil Thicket',
                  set: 'ons',
                },
                {
                  name: 'Tranquil Thicket',
                  set: 'ons',
                },
              ],
            },
          ],
        },
        {
          label: 'Multicolor',
          color: 'm',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Scheming Fence',
                  set: 'snc',
                },
                {
                  name: 'Temmet, Vizier of Naktamun',
                  set: 'akh',
                },
                {
                  name: 'Rix Maadi Reveler',
                  set: 'rna',
                },
                {
                  name: 'Alesha, Who Smiles at Death',
                  set: 'frf',
                },
                {
                  name: 'Lazav, the Multifarious',
                  set: 'grn',
                },
                {
                  name: 'Snooping Newsie',
                  set: 'snc',
                },
                {
                  name: 'Marchesa, the Black Rose',
                  set: '2x2',
                },
                {
                  name: 'Dimir Doppelganger',
                  set: 'rav',
                },
                {
                  name: 'Prized Amalgam',
                  set: 'soi',
                },
                {
                  name: 'Eloise, Nephalia Sleuth',
                  set: 'mic',
                },
                {
                  name: 'Oskar, Rubbish Reclaimer',
                  set: 'ncc',
                },
                {
                  name: 'Asmoranomardicadaistinaculdacar',
                  set: 'mh2',
                },
                {
                  name: 'Grenzo, Dungeon Warden',
                  set: 'a25',
                },
                {
                  name: 'Judith, the Scourge Diva',
                  set: 'rna',
                },
                {
                  name: 'Black Market Tycoon',
                  set: 'snc',
                },
                {
                  name: 'Dryad Militant',
                  set: 'rtr',
                },
                {
                  name: 'Park Heights Pegasus',
                  set: 'snc',
                },
                {
                  name: 'Knight of Autumn',
                  set: 'grn',
                },
                {
                  name: 'Elas il-Kor, Sadistic Pilgrim',
                  set: 'dmu',
                },
                {
                  name: 'High Priest of Penance',
                  set: 'gtc',
                },
                {
                  name: 'Priest of Fell Rites',
                  set: 'mh2',
                },
                {
                  name: 'Blistercoil Weird',
                  set: 'rtr',
                },
                {
                  name: 'Eruth, Tormented Prophet',
                  set: 'vow',
                },
                {
                  name: 'Jori En, Ruin Diver',
                  set: 'ogw',
                },
                {
                  name: 'Wandering Mind',
                  set: 'vow',
                },
                {
                  name: 'Slitherhead',
                  set: 'rtr',
                },
                {
                  name: 'Grim Flayer',
                  set: 'emn',
                },
                {
                  name: 'Dreg Mangler',
                  set: 'prtr',
                },
                {
                  name: 'Sarulf, Realm Eater',
                  set: 'khm',
                },
                {
                  name: 'Swiftblade Vindicator',
                  set: 'grn',
                },
                {
                  name: 'Coiling Oracle',
                  set: 'dis',
                },
                {
                  name: 'Lonis, Cryptozoologist',
                  set: 'mh2',
                },
                {
                  name: 'Slogurk, the Overslime',
                  set: 'mid',
                },
                {
                  name: 'Prime Speaker Vannifar',
                  set: 'rna',
                },
                {
                  name: 'Lashweed Lurker',
                  set: 'emn',
                },
                {
                  name: "Spara's Adjudicators",
                  set: 'snc',
                },
                {
                  name: 'Toluz, Clever Conductor',
                  set: 'snc',
                },
                {
                  name: 'Shattered Seraph',
                  set: 'snc',
                },
                {
                  name: 'Maestros Diabolist',
                  set: 'snc',
                },
                {
                  name: 'Glamorous Outlaw',
                  set: 'snc',
                },
                {
                  name: 'Thraximundar',
                  set: 'sld',
                },
                {
                  name: "Ognis, the Dragon's Lash",
                  set: 'snc',
                },
                {
                  name: 'Masked Bandits',
                  set: 'snc',
                },
                {
                  name: "Jinnie Fay, Jetmir's Second",
                  set: 'snc',
                },
                {
                  name: 'Rakish Revelers',
                  set: 'snc',
                },
                {
                  name: 'Karador, Ghost Chieftain',
                  set: 'cmd',
                },
                {
                  name: 'Sidisi, Brood Tyrant',
                  set: 'ktk',
                },
                {
                  name: 'Rakdos, Lord of Riots',
                  set: 'rtr',
                },
                {
                  name: 'Linvala, Shield of Sea Gate',
                  set: 'znr',
                },
                {
                  name: 'Errant and Giada',
                  set: 'mom',
                },
                {
                  name: 'Kaalia of the Vast',
                  set: '2x2',
                },
                {
                  name: 'Renegade Rallier',
                  set: 'aer',
                },
                {
                  name: 'Radha, Heart of Keld',
                  set: 'm21',
                },
                {
                  name: 'Ghor-Clan Rampager',
                  set: 'f13',
                },
                {
                  name: 'Battlewing Mystic',
                  set: 'dmu',
                },
                {
                  name: 'Body Dropper',
                  set: 'snc',
                },
              ],
            },
            {
              label: 'Instants',
              cards: [
                {
                  name: 'Warrant // Warden',
                  set: 'rna',
                },
                {
                  name: 'Unlicensed Disintegration',
                  set: 'kld',
                },
                {
                  name: 'Unlicensed Disintegration',
                  set: 'kld',
                },
                {
                  name: 'Mortify',
                  set: 'gpt',
                },
                {
                  name: 'Mortify',
                  set: 'gpt',
                },
                {
                  name: 'Izzet Charm',
                  set: 'ddj',
                },
                {
                  name: 'Lightning Helix',
                  set: 'rav',
                },
                {
                  name: 'Prepare // Fight',
                  set: 'akh',
                },
                {
                  name: 'Putrefy',
                  set: 'rav',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Claim // Fame',
                  set: 'hou',
                },
                {
                  name: 'Vigor Mortis',
                  set: 'rav',
                },
                {
                  name: 'Thought Erasure',
                  set: 'grn',
                },
                {
                  name: 'Thought Erasure',
                  set: 'grn',
                },
                {
                  name: 'Connive // Concoct',
                  set: 'grn',
                },
                {
                  name: 'Cut // Ribbons',
                  set: 'sld',
                },
                {
                  name: 'Grave Upheaval',
                  set: 'c16',
                },
                {
                  name: "Domri's Ambush",
                  set: 'war',
                },
                {
                  name: 'Travel Preparations',
                  set: 'isd',
                },
                {
                  name: "Can't Stay Away",
                  set: 'mid',
                },
                {
                  name: 'Inkling Summoning',
                  set: 'stx',
                },
                {
                  name: 'Unburial Rites',
                  set: 'isd',
                },
                {
                  name: 'Elemental Summoning',
                  set: 'stx',
                },
                {
                  name: 'Maelstrom Pulse',
                  set: 'arb',
                },
                {
                  name: 'Fractal Summoning',
                  set: 'stx',
                },
                {
                  name: 'Neoform',
                  set: 'war',
                },
                {
                  name: 'Pest Summoning',
                  set: 'stx',
                },
                {
                  name: 'Spirit Summoning',
                  set: 'stx',
                },
              ],
            },
            {
              label: 'Artifacts',
              cards: [
                {
                  name: "Courier's Briefcase",
                  set: 'snc',
                },
              ],
            },
            {
              label: 'Enchantments',
              cards: [
                {
                  name: 'Prosperous Partnership',
                  set: 'ncc',
                },
                {
                  name: 'Brokers Ascendancy',
                  set: 'snc',
                },
              ],
            },
            {
              label: 'Planeswalkers',
              cards: [
                {
                  name: 'Domri, Anarch of Bolas',
                  set: 'war',
                },
                {
                  name: 'Nahiri, the Harbinger',
                  set: 'soi',
                },
              ],
            },
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Adarkar Wastes',
                  set: 'dmu',
                },
                {
                  name: 'Azorius Chancery',
                  set: '2x2',
                },
                {
                  name: 'Glacial Fortress',
                  set: 'm10',
                },
                {
                  name: 'Dimir Aqueduct',
                  set: '2x2',
                },
                {
                  name: 'Drowned Catacomb',
                  set: 'm10',
                },
                {
                  name: 'Underground River',
                  set: 'bro',
                },
                {
                  name: 'Dragonskull Summit',
                  set: 'm10',
                },
                {
                  name: 'Rakdos Carnarium',
                  set: '2x2',
                },
                {
                  name: 'Sulfurous Springs',
                  set: 'dmu',
                },
                {
                  name: 'Gruul Turf',
                  set: '2x2',
                },
                {
                  name: 'Karplusan Forest',
                  set: 'dmu',
                },
                {
                  name: 'Rootbound Crag',
                  set: 'm10',
                },
                {
                  name: 'Brushland',
                  set: 'bro',
                },
                {
                  name: 'Selesnya Sanctuary',
                  set: '2x2',
                },
                {
                  name: 'Sunpetal Grove',
                  set: 'm10',
                },
                {
                  name: 'Caves of Koilos',
                  set: 'dmu',
                },
                {
                  name: 'Isolated Chapel',
                  set: 'isd',
                },
                {
                  name: 'Orzhov Basilica',
                  set: '2x2',
                },
                {
                  name: 'Izzet Boilerworks',
                  set: '2x2',
                },
                {
                  name: 'Shivan Reef',
                  set: 'dmu',
                },
                {
                  name: 'Sulfur Falls',
                  set: 'isd',
                },
                {
                  name: 'Golgari Rot Farm',
                  set: '2x2',
                },
                {
                  name: 'Llanowar Wastes',
                  set: 'bro',
                },
                {
                  name: 'Woodland Cemetery',
                  set: 'isd',
                },
                {
                  name: 'Battlefield Forge',
                  set: 'bro',
                },
                {
                  name: 'Boros Garrison',
                  set: '2x2',
                },
                {
                  name: 'Clifftop Retreat',
                  set: 'isd',
                },
                {
                  name: 'Hinterland Harbor',
                  set: 'isd',
                },
                {
                  name: 'Simic Growth Chamber',
                  set: '2x2',
                },
                {
                  name: 'Yavimaya Coast',
                  set: 'dmu',
                },
                {
                  name: 'Llanowar Wastes',
                  set: 'ori',
                },
                {
                  name: 'Underground River',
                  set: 'bro',
                },
                {
                  name: 'Sulfurous Springs',
                  set: 'dmu',
                },
                {
                  name: 'Caves of Koilos',
                  set: 'm15',
                },
              ],
            },
          ],
        },
        {
          label: 'Colorless',
          color: 'c',
          sections: [
            {
              label: 'Creatures',
              cards: [
                {
                  name: 'Hollow One',
                  set: 'hou',
                },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                {
                  name: 'Environmental Sciences',
                  set: 'stx',
                },
                {
                  name: 'Environmental Sciences',
                  set: 'stx',
                },
                {
                  name: 'Environmental Sciences',
                  set: 'stx',
                },
                {
                  name: 'Environmental Sciences',
                  set: 'stx',
                },
                {
                  name: 'Expanded Anatomy',
                  set: 'stx',
                },
                {
                  name: 'Expanded Anatomy',
                  set: 'stx',
                },
                {
                  name: 'Introduction to Prophecy',
                  set: 'stx',
                },
                {
                  name: 'Mascot Exhibition',
                  set: 'stx',
                },
                {
                  name: 'Introduction to Annihilation',
                  set: 'stx',
                },
              ],
            },
            {
              label: 'Artifacts',
              cards: [
                {
                  name: 'Chromatic Star',
                  set: 'brr',
                },
                {
                  name: 'Scrabbling Claws',
                  set: 'rna',
                },
                {
                  name: 'The Underworld Cookbook',
                  set: 'mh2',
                },
                {
                  name: 'The Underworld Cookbook',
                  set: 'mh2',
                },
                {
                  name: 'Getaway Car',
                  set: 'snc',
                },
                {
                  name: 'Scrabbling Claws',
                  set: 'mrd',
                },
                {
                  name: 'Fleetwheel Cruiser',
                  set: 'kld',
                },
                {
                  name: 'Daredevil Dragster',
                  set: 'aer',
                },
              ],
            },
          ],
        },
        {
          label: 'Lands',
          color: 'l',
          sections: [
            {
              label: 'Lands',
              cards: [
                {
                  name: 'Hallowed Fountain',
                  set: 'dis',
                },
                {
                  name: 'Watery Grave',
                  set: 'rav',
                },
                {
                  name: 'Blood Crypt',
                  set: 'dis',
                },
                {
                  name: 'Stomping Ground',
                  set: 'gpt',
                },
                {
                  name: 'Temple Garden',
                  set: 'rav',
                },
                {
                  name: 'Godless Shrine',
                  set: 'gpt',
                },
                {
                  name: 'Steam Vents',
                  set: 'gpt',
                },
                {
                  name: 'Overgrown Tomb',
                  set: 'rav',
                },
                {
                  name: 'Sacred Foundry',
                  set: 'rav',
                },
                {
                  name: 'Breeding Pool',
                  set: 'dis',
                },
                {
                  name: 'Ash Barrens',
                  set: 'c16',
                },
                {
                  name: 'Ash Barrens',
                  set: 'c16',
                },
                {
                  name: 'Ash Barrens',
                  set: 'c16',
                },
                {
                  name: 'Fabled Passage',
                  set: 'eld',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Hall of Oracles',
                  set: 'stx',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Island',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Mountain',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
                {
                  name: 'Spire of Industry',
                  set: 'aer',
                },
                {
                  name: 'Thran Portal',
                  set: 'dmu',
                },
                {
                  name: "Guildmages' Forum",
                  set: 'grn',
                },
                {
                  name: 'Uncharted Haven',
                  set: 'neo',
                },
                {
                  name: 'Uncharted Haven',
                  set: 'neo',
                },
                {
                  name: 'Uncharted Haven',
                  set: 'neo',
                },
                {
                  name: 'Forest',
                  set: 'snc',
                },
                {
                  name: 'Plains',
                  set: 'snc',
                },
              ],
            },
          ],
        },
      ]}
    />
  )
}
