import * as styles from './MapLinks.module.scss'

import React from 'react'

import BannerRow from 'components/home/BannerRow'

import cubeMapBannerImage from 'components/home/cube-map-banner.png'
import edhMapBannerImage from 'components/home/edh-map-banner.png'

const MapLinks: React.FC = () => {
  return (
    <div className={styles.container}>
      <BannerRow
        to="/resources/cube-map/"
        backgroundImage={cubeMapBannerImage}
        title="Explore the Cube Universe"
        subtitle="Discover just how diverse the Cube format can be, themes you never expected, and where your own cube fits."
      />

      <BannerRow
        to="/resources/commander-map/"
        backgroundImage={edhMapBannerImage}
        title="Commander Map"
        subtitle="Explore trends and patterns in an interactive visualization of over two and a half million Commander decks organized by the cards they contain."
      />
    </div>
  )
}

export default MapLinks
