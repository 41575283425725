import * as styles from './HorizontalScrollingContainer.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  noFitWidth?: boolean
}

const HorizontalScrollingContainer: React.FC<Props> = (props) => {
  const { children, noFitWidth } = props

  return (
    <div
      className={classNames(styles.container, {
        [styles.noFitWidth]: noFitWidth,
      })}
    >
      {children}
    </div>
  )
}

export default HorizontalScrollingContainer
