// extracted by mini-css-extract-plugin
export var container = "CardHover-module--container--123c5";
export var hover = "CardHover-module--hover--afcb8";
export var hoverContent = "CardHover-module--hoverContent--84afa";
export var hoverContentLeft = "CardHover-module--hoverContentLeft--7421a";
export var hoverContentUp = "CardHover-module--hoverContentUp--25c25";
export var hoverDoubleFaced = "CardHover-module--hoverDoubleFaced--10ef0";
export var hoverImage = "CardHover-module--hoverImage--68d45";
export var modal = "CardHover-module--modal--8b4c6";
export var modalDoubleFaced = "CardHover-module--modalDoubleFaced--e39b3";
export var modalImage = "CardHover-module--modalImage--40bdb";