import * as styles from './YearHeading.module.scss'

import React from 'react'

interface Props {
  year: string
  heading?: string
  notes?: string
}

export const YearHeading: React.FC<Props> = (props) => {
  const { year, heading, notes } = props

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.year}>
          <div>
            {year}
            {heading && <div className={styles.subheading}>{heading}</div>}
          </div>
        </h2>

        {notes && <div className={styles.notes}>{notes}</div>}
      </div>
    </div>
  )
}
