import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Decklist from 'components/decklists/Decklist'

export const MonoBlackDeck: React.FC = () => {
  return (
    <HorizontalScrollingContainer>
      <Decklist
        maindeckLabel="Example Deck"
        maindeck={[
          [
            { name: 'Swamp', set: 'mkm', count: 14 },
            { name: 'Wasteland' },
            { name: 'Rishadan Port' },
          ],

          [
            { name: 'Bloodsoaked Champion' },
            { name: 'Gutterbones' },
            { name: 'Shadowspear' },
          ],

          [
            { name: 'Tenacious Underdog' },
            { name: 'Vampire Hexmage' },
            { name: 'Scrapheap Scrounger' },
            { name: 'Bloodghast' },
            { name: 'Dauthi Voidwalker' },
            { name: 'Outrageous Robbery' },
            { name: 'Eliminate' },
            { name: 'Case of the Stashed Skeleton' },
          ],

          [
            { name: 'Sedgemoor Witch' },
            { name: 'Woe Strider' },
            { name: 'Recurring Nightmare', set: 'exo' },
            { name: 'Liliana, the Last Hope' },
            { name: 'Liliana of the Veil' },
          ],

          [
            { name: 'Massacre Girl, Known Killer' },
            { name: 'Lethal Scheme' },
            { name: 'Pile On' },
          ],

          [
            { name: 'Shriekmaw' },
            { name: 'Batterskull' },
            { name: 'Vein Ripper' },
            { name: 'Skysovereign, Consul Flagship' },
            { name: 'Foreboding Steamboat' },
          ],
        ]}
      />
    </HorizontalScrollingContainer>
  )
}
