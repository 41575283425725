import * as styles from './ProspectiveIntro.module.scss'

import React from 'react'
import { Link } from 'gatsby'

const ProspectiveIntro: React.FC = () => {
  return (
    <div className={styles.container}>
      <p className={styles.summary}>
        This article is part of our community-sourced Set Prospective series. We
        survey Cube designers before the set&rsquo;s official release to
        document their first impressions of new cards.
      </p>
      <div className={styles.links}>
        <Link to="/set-prospectives/" className={styles.link}>
          Read more about the methodology behind our prospective articles &rarr;
        </Link>
        <br />
        <Link to="/newsletter/" className={styles.link}>
          Sign up for our newsletter to participate in future surveys &rarr;
        </Link>
      </div>
    </div>
  )
}

export default ProspectiveIntro
