import React from 'react'

import { SetList } from 'components/set-retrospectives/set-list/SetList'

import { sets } from './sets'

const ArticleSetsList: React.FC = () => {
  return <SetList sets={sets} />
}

export default ArticleSetsList
