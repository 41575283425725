import * as styles from './DecklistWithMetaData.module.scss'

import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'

import Decklist from './Decklist'

interface Props {
  maindeck: { name: string; set?: string; id?: string }[][]
  sideboard?: { name: string; set?: string; id?: string }[]
  name: string
  label?: string
  labelLink?: string
  drafter?: string
  description?: React.ReactNode
}

const DecklistWithMetaData: React.FC<Props> = (props) => {
  const {
    name,
    drafter,
    label = 'Example Decklist',
    labelLink,
    description,
    ...decklistProps
  } = props

  return (
    <section className={styles.container}>
      <div className={styles.metadata}>
        <div>
          <div className={styles.label}>
            {labelLink ? <a href={labelLink}>{label}</a> : <>{label}</>}
          </div>
          <h3 className={styles.name}>{name}</h3>
          {drafter && (
            <div className={styles.drafter}>Drafted by {drafter}</div>
          )}
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <HorizontalScrollingContainer>
        <Decklist {...decklistProps} />
      </HorizontalScrollingContainer>
    </section>
  )
}

export default DecklistWithMetaData
