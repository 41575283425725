import * as styles from './Histogram.module.scss'

import React, { useMemo } from 'react'
import * as d3 from 'd3'

import { EdgeInsets, Rect } from 'utils/geometry'

import { HistogramBar } from './HistogramBar'

interface Props {
  data: number[]
  maxX?: number
  maxY?: number
}

const axisInsets = new EdgeInsets(0, 0, 13, 0)
const bounds = new Rect(0, 0, 400, 120)
const contentRect = bounds.inset(axisInsets)

export const HistogramUnmemoized: React.FC<Props> = (props) => {
  const { data } = props

  const maxX = useMemo(
    () => props.maxX ?? Math.max(...data),
    [props.maxX, data],
  )

  const bins = useMemo(() => {
    return data.reduce((result, datum) => {
      result[Math.min(datum, maxX)]++
      return result
    }, new Array(maxX + 1).fill(0) as number[])
  }, [data, maxX])

  const lastBinIncludesExcess = useMemo(() => {
    return Math.max(...data) > bins.length
  }, [bins.length, data])

  const barWidth = useMemo(() => contentRect.width / maxX - 1.5, [maxX])

  const maxY = useMemo(
    () => props.maxY ?? Math.max(...bins),
    [props.maxY, bins],
  )

  const scaleX = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, maxX])
        .range([contentRect.x, contentRect.maxX - barWidth]),
    [barWidth, maxX],
  )

  const scaleY = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, maxY])
        .range([contentRect.maxY, contentRect.y]),
    [maxY],
  )

  return (
    <div className={styles.container}>
      <svg
        viewBox={`0 0 ${bounds.width} ${bounds.height}`}
        className={styles.svg}
      >
        <text className={styles.axisLabel} x={scaleX(0)} y={bounds.height}>
          0
        </text>
        <text
          className={styles.axisLabel}
          x={scaleX(maxX) + barWidth}
          y={bounds.height}
          textAnchor="end"
        >
          {maxX}
          {lastBinIncludesExcess && '+'}
        </text>

        {bins.map((datum, index) =>
          datum !== 0 ? (
            <HistogramBar
              key={index}
              x={scaleX(index)}
              y={scaleY(datum)}
              width={barWidth}
              height={contentRect.height - scaleY(datum)}
              label={`${datum.toString()} cubes (${(
                (datum / data.length) *
                100
              ).toFixed(0)}%) with ${index} cards`}
            />
          ) : null,
        )}
      </svg>

      <div className={styles.caption}>
        Distribution of Cards Among Respondents&apos; Cubes
      </div>
    </div>
  )
}

export const Histogram = React.memo(HistogramUnmemoized)
