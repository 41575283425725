import BackLink from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/BackLink.tsx";
import DegenerateMicroCubeList from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/DegenerateMicroCubeList.tsx";
import CreatureCheatMatrix from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/CreatureCheatMatrix.tsx";
import BantControl from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/BantControl.tsx";
import EmryShops from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/EmryShops.tsx";
import Hatebears from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/Hatebears.tsx";
import UBCheat from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/UBCheat.tsx";
import Tinker from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/Tinker.tsx";
import Bomberman from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/Bomberman.tsx";
import SneakAndDepths from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/SneakAndDepths.tsx";
import TurboChannel from "../../../../src/data/articles/an-introduction-to-the-degenerate-micro-cube/SampleDecks/TurboChannel.tsx";
import * as React from 'react';
export default {
  BackLink,
  DegenerateMicroCubeList,
  CreatureCheatMatrix,
  BantControl,
  EmryShops,
  Hatebears,
  UBCheat,
  Tinker,
  Bomberman,
  SneakAndDepths,
  TurboChannel,
  React
};