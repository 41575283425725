import * as styles from './PreformattedText.module.scss'

import React from 'react'

import TextBlock from './TextBlock'

interface Props {
  children: React.ReactNode
}

const PreformattedText: React.FC<Props> = (props) => {
  return (
    <TextBlock>
      <pre className={styles.preformattedText}>{props.children}</pre>
    </TextBlock>
  )
}

export default PreformattedText
