import * as styles from './MapPreview.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import image from './map.png'

const MapPreview: React.FC = () => {
  return (
    <div className={styles.container}>
      <Link to="/resources/commander-map/">
        <img src={image} alt="Commander Map" className={styles.image} />
      </Link>
      <div className={styles.icon}>&rarr;</div>
    </div>
  )
}

export default MapPreview
