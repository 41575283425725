import * as styles from './SmallButtonLink.module.scss'

import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

interface Props {
  children: React.ReactNode
  to: string
  secondary?: boolean
  state: any
  disabled?: boolean
}

export const SmallButtonLink: React.FC<Props> = (props) => {
  const { secondary = false, disabled = false, ...buttonProps } = props

  return (
    <Link
      {...buttonProps}
      className={classNames(styles.button, {
        [styles.primary]: !secondary,
        [styles.secondary]: secondary,
        [styles.disabled]: disabled,
      })}
    />
  )
}
