import { useState, useEffect } from 'react'

export function isTouchDevice() {
  return window.matchMedia('(pointer: coarse)').matches
}

/**
 * This hook always returns `false` on the first render if `defer` is true
 * avoid SSR issues.
 */
export const useIsTouchDevice = (defer = true) => {
  const [isTouchDeviceState, setIsTouchDeviceState] = useState(
    defer ? false : isTouchDevice(),
  )

  useEffect(() => {
    setIsTouchDeviceState(isTouchDevice())
  }, [])

  return isTouchDeviceState
}
