import * as styles from './Label.module.scss'

import React from 'react'

interface Props {
  title: string
  children: React.ReactNode
}

export const Label: React.FC<Props> = (props) => {
  const { title, children } = props

  return (
    <label className={styles.container}>
      <div className={styles.label}>{title}</div>
      {children}
    </label>
  )
}
