import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const TurboChannel: React.FC = () => {
  return (
    <Decklist
      name="Turbo Channel"
      drafter="Andy Mangold"
      description={
        <>
          This deck&rsquo;s primary plan is to{' '}
          <CardHover card="Channel">Channel</CardHover> out threats ahead of
          curve with fast mana courtesy of{' '}
          <CardHover card="Lotus Petal">Lotus Petal</CardHover> and{' '}
          <CardHover card="Chrome Mox">Chrome Mox</CardHover>. A dash of
          interaction protects your combo or disrupts your opponent and the
          backup plan is looping everything with{' '}
          <CardHover card="Careful Study">Careful Study</CardHover> and{' '}
          <CardHover card="Kozilek, Butcher of Truth">Kozilek</CardHover> or
          grinding out a win with{' '}
          <CardHover card="Deathrite Shaman">Deathrite Shaman</CardHover> and{' '}
          <CardHover card="Scavenging Ooze">Scavenging Ooze</CardHover>.
        </>
      }
      maindeck={[
        [
          { name: 'Island', set: 'TMP' },
          { name: 'Forest', set: 'TMP' },
          { name: 'Mana Confluence' },
          { name: 'Chrome Mox' },
          { name: 'Lotus Petal', set: 'TMP' },
          { name: 'Mental Misstep' },
          { name: 'Once Upon a Time' },
        ],
        [
          { name: 'Careful Study' },
          { name: 'Stubborn Denial' },
          { name: 'Deathrite Shaman' },
        ],
        [
          { name: 'Ratchet Bomb' },
          { name: 'Scavenging Ooze' },
          { name: 'Channel', set: 'LEB' },
        ],
        [
          { name: 'Wurmcoil Engine' },
          { name: 'Kozilek, Butcher of Truth', set: 'ROE' },
        ],
      ]}
      sideboard={[
        { name: 'Tinker' },
        { name: "Red Sun's Zenith" },
        { name: 'Fastbond', set: 'LEB' },
        { name: 'Aether Vial' },
        { name: 'Chromatic Star' },
        { name: "Feldon's Cane" },
        { name: "Thespian's Stage" },
      ]}
    />
  )
}

export default TurboChannel
