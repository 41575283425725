import React from 'react'

import ResponseCard from './ResponseCard'
import CardHover from 'components/cards/CardHover'

const Responses: React.FC = () => {
  return (
    <div>
      <ResponseCard
        id="john-terrill"
        designer="John Terrill of Cultic Cube"
        cubeName="Ravnica Extended Cube"
        cubeLink="https://cubecobra.com/cube/overview/ravnicaextended"
        answerOne={
          <p>
            The Ravnica Extended cube was designed for competitive play at
            CubeCon, where it would be run in traditional eight person draft
            pods; 360 cards makes sense for this environment, to facilitate
            resetting the cube between events. Small cubes can grow stale more
            quickly than large cubes due to decreased variance in the card pool,
            but this was not an issue for a cube that was meant to be featured
            at a weekend-long event.
          </p>
        }
        answerTwo={
          <p>
            As this is a Ravnica extended cube, I want to feature elements of
            all the blocks that have been set on the plane, from Ravnica: City
            of Guilds to War of the Spark. A challenge was to provide mechanical
            unity to such diverse sets from different moments in WOTC design. I
            allowed myself some leeway in using cards from supplemental sets or
            from core sets whose art or flavor is Ravnican. (A superb resource
            for those who face a similar conundrum is{' '}
            <a href="https://www.mtg-multiverse.com/#/ravnica/">
              The Multiverse Project
            </a>
            ). Furthermore, to increase plane-appropriate fixing, I broke
            singleton for{' '}
            <CardHover card="Dimir Guildgate">Guildgates</CardHover>. This
            allows me, of course, to develop a Gates-matters theme as well.
          </p>
        }
        answerThree={
          <p>
            I wanted the format to feel like a high powered retail limited
            environment, along the lines of a masters set. The format thus tends
            toward shades of midrange, it is bomb-driven (though the power level
            of the bombs are attenuated), games develop at an orthodox pace
            (little inexpensive mana acceleration, no cheat), and planeswalkers
            are scarce.
          </p>
        }
        answerFour={
          <p>
            Ravnica is definitionally a multi-color world. It is extremely
            important to me that gold card as-fan in packs closely approximates
            Wizards of the Coast’s formula for recent Ravnica sets. I want to
            incentivize people to adopt a guild, but not necessarily to reward
            everyone for playing rainbow decks. Thus, fixing is largely tied up
            in lands. Dorks rarely fix for color, and I eschew{' '}
            <CardHover card="Izzet Signet">Signets</CardHover> in favor of the
            more expensive{' '}
            <CardHover card="Selesnya Keyrune">Keyrunes</CardHover> or{' '}
            <CardHover card="Golgari Locket">Lockets</CardHover>. I work hard to
            create guild identities that allow beloved cards from the history of
            Ravnica sets to be showcased without feeling like a schizophrenic
            mash-up of competing mechanics. A few one-off build-arounds exist,
            but deck strategies are principally tied up in maximizing
            guild-based archetypes and in finding cross-guild synergies.
          </p>
        }
      />

      <ResponseCard
        id="alexis-janson"
        designer="Alexis Janson"
        cubeName="High-End Synergy Cube"
        cubeLink="https://cubecobra.com/cube/overview/alexis"
        answerOne={
          <p>
            Right now, playing heads-up draft with my roommate is the primary
            target. We see about half of the ~400 card cube per draft.
            We&rsquo;re experienced as both Magic and cube players, so I want to
            layer in as much viable variety as possible. Complexity is a
            feature, not a bug. Cards can start out as &ldquo;pet cards&rdquo;
            but eventually have to demonstrate some results. It&rsquo;s
            important that the cube remain theoretically viable for an 8-person
            draft for the future, when I still want to see a variety of colors
            and archetypes represented across 8 decks, and most or all of the
            cube is used.
          </p>
        }
        answerTwo={
          <>
            <p>
              I&rsquo;m following singleton restrictions, but otherwise any
              physically printed Magic card is in-bounds, including
              silver-border and playtest cards.
            </p>
            <p>
              The goal of the cube is to maximize gameplay variety thru synergy,
              so every card should provide multiple angles to consider and
              require thoughtful deckbuilding to maximize. I don&rsquo;t want
              cards that are generically powerful with little regard to your
              other picks, so no{' '}
              <CardHover card="Lightning Bolt">Lightning Bolt</CardHover>,{' '}
              <CardHover card="Questing Beast">Questing Beast</CardHover>,{' '}
              <CardHover card="Thoughtseize">Thoughtseize</CardHover>, etc. This
              cuts out a ton of &ldquo;cube staples&rdquo; and impacts
              interaction the most. Interaction generally requires setup effort
              or a higher mana cost than most cubes. One minor exception — a
              subtheme of the cube is maximizing visual variety and I&rsquo;ve
              allowed a handful of simpler cards due to unique frames or
              presentations. Mana fixing also has relaxed complexity rules.
            </p>
          </>
        }
        answerThree={
          <>
            <p>
              As powerful as it can be without sacrificing other goals. I
              don&rsquo;t want &ldquo;must pick at any stage&rdquo; cards. I
              originally had <CardHover card="Mana Crypt">Mana Crypt</CardHover>
              , <CardHover card="Sol Ring">Sol Ring</CardHover>, and{' '}
              <CardHover card="Mox Sapphire">Moxen</CardHover> as they
              theoretically have lots of interesting synergies, but in practice
              those never came up and instead they overshadowed and overpowered
              the synergistic gameplay. These are the only cards I&rsquo;ve cut
              for pure power reasons, although I&rsquo;m considering cutting{' '}
              <CardHover card="Recurring Nightmare">
                Recurring Nightmare
              </CardHover>
              .
            </p>
            <p>
              The cube currently has{' '}
              <CardHover card="Skullclamp">Skullclamp</CardHover>,{' '}
              <CardHover card="Fastbond">Fastbond</CardHover>,{' '}
              <CardHover card="Balance">Balance</CardHover>,{' '}
              <CardHover card="Winter Orb">Winter Orb</CardHover>, etc. so there
              is no shortage of powerful plays as long as they still increase
              overall gameplay variety rather than homogenize it. The best cards
              have high ceilings and low floors. The wider the band of power for
              a card, the better. For example, a card like{' '}
              <CardHover card="Ephemerate">Ephemerate</CardHover> ranges from
              &ldquo;really bad combat trick&rdquo; to &ldquo;W: draw 4 cards
              via Mulldrifter&rdquo;. I don&rsquo;t want cards that consistently
              underperform outside of Magical Christmasland, but I do want cards
              that require effort to maximize
            </p>
          </>
        }
        answerFour={
          <>
            <p>
              Draft: Experienced drafters should never feel like they&rsquo;re
              &ldquo;on rails&rdquo;. Instead, there should be multiple layers
              to their choices. I don&rsquo;t want complete red herrings, but
              scrubbing out because you chased a minimally-supported archetype
              is an acceptable cost. Mana fixing should support two color decks;
              anything beyond a splash in a third color should require
              sacrifices in the draft. A drafter should be able to find cards
              that mitigate screw and/or flood if they are willing to prioritize
              that.
            </p>
            <p>
              Gameplay: True board stalls should be rare, because most cards
              will offer options beyond plain combat. Barring bad mulligans,
              players should feel like their drafting and gameplay decisions
              mattered. &ldquo;Big finishes&rdquo; (combo, locks, etc.) should
              take effort to set up, conscious deckbuilding, and/or play out
              over multiple turns. Being surprised by strange interactions is
              expected to occur regularly.
            </p>
          </>
        }
      />

      <ResponseCard
        id="parker-lamascus"
        designer="Parker LaMascus"
        cubeName="Mordor&rsquo;s Cube"
        cubeLink="https://cubecobra.com/cube/overview/1313"
        answerOne={
          <p>
            I primarily play with 1-3 other people, so 360 is the right size for
            my cube. I am the most experienced Magic player among my group, so
            it’s important that my archetypes and cards are easily communicated
            to newcomers (no customs, no alters, updated English-language rules
            text).
          </p>
        }
        answerTwo={
          <p>
            I adhere to a “singleton art” restriction — my cube has multiple
            copies of{' '}
            <CardHover card="Llanowar Elves">Llanowar Elves</CardHover>, for
            example, but each has a different art. This helps me stay on a
            budget of 20/month for cube upgrades.
          </p>
        }
        answerThree={
          <p>
            I try to maximize power of the core archetypes of Magic — aggro,
            midrange, and control — while maintaining a balance between them. A
            card like{' '}
            <CardHover card="Goblin Rabblemaster">
              Goblin Rabblemaster
            </CardHover>{' '}
            represents an average 3-drop in my cube, while{' '}
            <CardHover card="Oko, Thief of Crowns">
              Oko Thief of Crowns
            </CardHover>{' '}
            is the most powerful. I try to trim anything that offers more power
            for less mana than{' '}
            <CardHover card="Oko, Thief of Crowns">Oko</CardHover> (
            <CardHover card="Mox Jet">Moxen</CardHover>,{' '}
            <CardHover card="Sol Ring">Sol Ring</CardHover>,{' '}
            <CardHover card="Mana Drain">Mana Drain</CardHover>), or anything
            which has a much lower failstate than{' '}
            <CardHover card="Goblin Rabblemaster">
              Goblin Rabblemaster
            </CardHover>{' '}
            (<CardHover card="Curse of Predation">Curse of Predation</CardHover>
            , <CardHover card="Drake Haven">Drake Haven</CardHover>).
          </p>
        }
        answerFour={
          <p>
            I find close, tight games to be the most fun thing about Magic,
            where player decisions matter on both sides of the table. I maximize
            the opportunities for players to exercise agency through a
            higher-than-average amount of interaction, cantrips, and manafixing.
            Combo actively works against that goal, so I have eliminated it from
            my environment. Finally, I try not to include “microarchetypes” like
            aristocrats or +1/+1 counters matter, as a way to design towards my
            goal of approachability for new players.
          </p>
        }
      />

      <ResponseCard
        id="usman-jamil"
        designer="Usman Jamil"
        cubeName="Pauper Cube"
        cubeLink="https://www.cubetutor.com/viewcube/10790"
        answerOne={
          <p>
            Usually it&rsquo;s my local playgroup of about 5ish regulars, and I
            take others into account as a content producer when making changes
            to the finalized lists.
          </p>
        }
        answerTwo={
          <p>
            This cube is a Pauper cube, so I am restricted to cards printed at
            common. I also adhere to singleton restriction and the basics are
            snow basics. Honestly, it&rsquo;s nothing hugely groundbreaking.
          </p>
        }
        answerThree={
          <p>
            I honestly hadn&rsquo;t given it a lot of thought for the majority
            of my time in cube — perhaps this was a naive view of balancing. My
            general cube ideology is that every card in a cube should have a
            mission statement — “if you take me, you shouldn&rsquo;t be
            surprised if this goes in a 3-0 list” — even though individual cards
            are certainly more powerful than others, like a{' '}
            <CardHover card="Shock">Shock</CardHover> or a{' '}
            <CardHover card="Galvanic Blast">Galvanic Blast</CardHover> is less
            powerful than a{' '}
            <CardHover card="Lightning Bolt">Lightning Bolt</CardHover>.
          </p>
        }
        answerFour={
          <p>
            Ideal gameplay is where someone can get done with a draft and not
            feel like they messed up by being in an archetype, or worse, being
            in a lane in an archetype but finding out that it&rsquo;s a bad
            archetype, and then having a bad time. Although, I&rsquo;m fine with
            cards that are lopsided, like the protection swords and whatnot,
            which may be just something that I&rsquo;ve accepted from playing
            the game for 32847982374 years. Maybe it&rsquo;s just the Desi in
            me, but I think I&rsquo;d define ideal gameplay in cube is where
            everyone has a good time while competing with powerful cards, and if
            there&rsquo;s cool/interesting gameplay in there, that&rsquo;s even
            better.
          </p>
        }
      />

      <ResponseCard
        id="andy-mangold"
        designer="Andy Mangold"
        cubeName="Bun Magic Cube"
        cubeLink="https://cubecobra.com/cube/overview/andymangold"
        answerOne={
          <p>
            My cube is designed to be played primarily with three different
            groups of people: in person with my large, local playgroup in
            Baltimore, the <i>Bun Magic Kitchen Table Pro Tour</i> (6-8 player
            pods), online with players from various Cube communities (6-8 player
            pods), and one-on-one with friends (2 players). I aim for my cube to
            represent what I enjoy most about the game so I am able to share
            that experience directly with others.
          </p>
        }
        answerTwo={
          <p>
            I maintain a spirit of singleton restriction, not running multiple
            copies of the same card or cards that are strictly worse than others
            I include, with very few exceptions, though I do break singleton for{' '}
            <CardHover card="Steam Vents">shock lands</CardHover>. Other than
            that, my card choices are unrestricted.
          </p>
        }
        answerThree={
          <p>
            I aim to optimize my cube for power and consistency within the scope
            of what I deem to be &ldquo;fair&rdquo; strategies. I exclude
            individual cards which offer their caster an outsized advantage with
            minimal or no deckbuilding cost (The Power Nine, hyper-efficient
            ramp like <CardHover card="Sol Ring">Sol Ring</CardHover> and{' '}
            <CardHover card="Mana Crypt">Mana Crypt</CardHover>,{' '}
            <CardHover card="Mind Twist">Mind Twist</CardHover>,{' '}
            <CardHover card="Mana Drain">Mana Drain</CardHover>, etc.),
            strategies that are linear and largely non-interactive (combo and
            storm), and cards that cheat mana costs (
            <CardHover card="Tinker">Tinker</CardHover>,{' '}
            <CardHover card="Natural Order">Natural Order</CardHover>,
            reanimator, etc.). My goal is to push the core strategies of the
            game — aggro, midrange, and control — to their most powerful limits
            while still remaining in balance with one another.
          </p>
        }
        answerFour={
          <p>
            I want to enable my players to express their skill and empower them
            to control their fate through their draft, deckbuilding, and
            gameplay decisions. While I believe variance is an inherent and
            essential part of the game, I want to offer my players the
            opportunity to minimize that variance with an abundance of mana
            fixing, modal cards, and efficient card selection. My ideal games
            are realtively fast, with the deciding plays happening before turn
            five, and narrow wins that are one decision or card away from the
            opposite outcome. I accept the occasional non-games and swingy plays
            as a byproduct of the power-level at which I choose to play, but
            only if those non-games are the result of conscious deckbuilding and
            gameplay decisions, like the successful execution of an{' '}
            <CardHover card="Upheaval">Upheaval</CardHover> deck, rather than on
            the back of singular, powerful cards, like{' '}
            <CardHover card="Mind Twist">Mind Twist</CardHover>ing away your
            opponents hand. I am especially interested in
            &ldquo;symmetrical&rdquo; effects that can be abused with selective
            deckbuilding.
          </p>
        }
      />

      <ResponseCard
        id="anthony-mattox"
        designer="Anthony Mattox"
        cubeName="Regular Cube"
        cubeLink="https://cubecobra.com/cube/overview/regular"
        answerOne={
          <p>
            The Regular Cube is designed for players in my local group,
            especially those who love limited, and have a range of experience.
            It&rsquo;s intended to have a relatively high floor, allowing les
            experienced players to draft a function deck, but high strategic
            complexity to offer depth to the more experienced. My goal is for it
            to be &lsquo;Spike hardened&rsquo;, not only supporting complex,
            synergistic strategies but ensuring that they are also optimal.
          </p>
        }
        answerTwo={
          <p>
            The cube is between 400 to 450 cards. There are no formal
            restrictions on rarity, era, or budget. It is more loosely
            restricted to contemporary Magic design philosophy and the power
            level of what would be good uncommons and lower tier rares in a
            premier set. Singleton is generally preferred, including functional
            duplicates, but I&rsquo;m willing to break any rules if there is a
            good reason to.
          </p>
        }
        answerThree={
          <p>
            I want the cube to be a bit north of a special set like Modern
            Horizons or Double Masters. I want to support a number of
            synergistic strategies possible at this power level. Cards that are
            powerful enough in isolation to make synergistic and strategic power
            less important are avoided.
          </p>
        }
        answerFour={
          <p>
            Ideally the cube should play like a powered up limited set. The
            gameplay still revolves around creatures and combat, making you work
            to generate card or tempo advantage. Strategic and board complexity
            is significantly higher than a premier set. I want to support as
            much variation between drafts and games as possible and am somewhat
            tolerant to variance as long as the overall feel of the game is that
            players are still making decisions that matter most of the time.
          </p>
        }
      />
    </div>
  )
}

export default Responses
