import * as styles from './LineGraph.module.scss'

import React, { useEffect, useRef, useMemo } from 'react'
import * as d3 from 'd3'
import { flatMap } from 'lodash'

import { expandRangeByFraction } from './utils/ranges'
import { Size, EdgeInsets } from 'utils/geometry'

interface Props {
  verticalAxisLabel: string | null
  horizontalAxisLabel: string | null
  data: {
    series: {
      name: string
      color: string
      values: number[]
    }[]
  }
  domain?: { x: [number, number]; y: [number, number] }
}

const bounds = new Size(650, 250).rect

const LineGraph: React.FC<Props> = (props) => {
  const { verticalAxisLabel, horizontalAxisLabel, data, domain } = props

  const margins = useMemo(
    () =>
      new EdgeInsets(
        5,
        5,
        horizontalAxisLabel != null ? 40 : 20,
        verticalAxisLabel != null ? 40 : 20,
      ),
    [horizontalAxisLabel, verticalAxisLabel],
  )

  const svgElement = useRef<SVGSVGElement | null>(null)
  const legendElement = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if ((svgElement.current == null, legendElement.current == null)) {
      return
    }

    const svg = d3.select(svgElement.current)
    const legend = d3.select(legendElement.current)

    svg.selectAll('g').remove()

    const contentRect = bounds.inset(margins)

    const scaleX = d3
      .scaleLinear()
      .domain(
        domain?.x ||
          expandRangeByFraction(
            [0, Math.max(...data.series.map((s) => s.values.length))],
            0.05,
          ),
      )
      .range([contentRect.x, contentRect.maxX])

    const scaleY = d3
      .scaleLinear()
      .domain(
        domain?.y ||
          expandRangeByFraction(
            d3.extent(flatMap(data.series, (s) => s.values)) as [
              number,
              number,
            ],
            0.1,
          ),
      )
      .range([contentRect.maxY, contentRect.y])

    const valueLine = d3
      .line()
      .x((_, index) => scaleX(index))
      .y((d) => scaleY(d as any))

    const xAxis = d3.axisBottom(scaleX)
    const yAxis = d3.axisLeft(scaleY).ticks(4)

    svg
      .append('g')
      .attr('transform', `translate(0, ${contentRect.maxY})`)
      .call(xAxis)

    if (horizontalAxisLabel) {
      svg
        .append('g')
        .append('text')
        .attr('class', styles.axisLabel)
        .attr('x', contentRect.midX)
        .attr('y', contentRect.maxY + 35)
        .text(horizontalAxisLabel)
    }

    if (verticalAxisLabel) {
      svg
        .append('g')
        .append('text')
        .attr('class', styles.axisLabel)
        .attr('x', -contentRect.midY)
        .attr('y', 10)
        .attr('transform', 'rotate(-90)')
        .text(verticalAxisLabel)
    }

    svg
      .append('g')
      .attr('transform', `translate(${contentRect.x}, 0)`)
      .call(yAxis)

    svg
      .append('g')
      .selectAll(`.${styles.path}`)
      .data(data.series)
      .join('path')
      .attr('class', styles.path)
      .attr('stroke', (d) => d.color)
      .attr('d', (d) => valueLine(d.values as any))

    const legendItems = legend
      .selectAll(`.${styles.legendItem}`)
      .data(data.series)
      .join('div')
      .attr('class', styles.legendItem)

    legendItems
      .append('div')
      .attr('class', styles.legendIcon)
      .style('background', (d) => d.color)
    legendItems.append('span').text((d) => d.name)
  }, [data, horizontalAxisLabel, verticalAxisLabel, margins, domain])

  return (
    <div className={styles.container}>
      <svg
        ref={svgElement}
        viewBox={`0 0 ${bounds.width} ${bounds.height}`}
        xmlns="http://www.w3.org/2000/svg"
      />
      <div className={styles.legend} ref={legendElement} />
    </div>
  )
}

export default LineGraph
