export interface RaritySelectionState {
  common: boolean
  uncommon: boolean
  rare: boolean
  mythic: boolean
}

export const rarities: (keyof RaritySelectionState)[] = [
  'common',
  'uncommon',
  'rare',
  'mythic',
]
