import * as styles from './CardHover.module.scss'

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

import { useIsTouchDevice } from 'utils/useIsTouchDevice'
import { useImageURLs } from 'utils/scryfall/hooks'

/**
 * Hook to add a card hover, showing an image of a card when mousing over an
 * element. Pass all the returned props to an element and render the "portal"
 * somewhere adjacent to it.
 *
 * Front and back faces will be fetched for cards with "//" in the name.
 */
export const useCardHover = (card: string, set?: string, id?: string) => {
  const [visible, setVisible] = useState(false)
  const [cursorPosition, setCursorPosition] = useState([0, 0])
  const [clientCursorPosition, setClientCursorPosition] = useState([0, 0])

  const [backFaceFailed, setBackFaceFailed] = useState(false)

  const isTouchDevice = useIsTouchDevice(true)

  const imageURLs = useImageURLs(card, set, id)
  const isDoubleFaced = imageURLs.back != null && !backFaceFailed

  const renderCardImage =
    typeof document !== 'undefined' &&
    visible &&
    (isTouchDevice || (cursorPosition[0] > 0 && cursorPosition[1] > 0))

  useEffect(() => {
    if (renderCardImage) {
      const onScroll = () => {
        setVisible(false)
      }
      window.addEventListener('scroll', onScroll)

      return () => {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [renderCardImage])

  const portal = renderCardImage
    ? ReactDOM.createPortal(
        isTouchDevice ? (
          <div
            className={classNames(styles.modal, {
              [styles.modalDoubleFaced]: isDoubleFaced,
            })}
            onClick={(event) => {
              event.preventDefault()
              setVisible(false)
            }}
          >
            <img className={styles.modalImage} src={imageURLs.front} />
            {imageURLs.back && !backFaceFailed && (
              <img
                className={styles.modalImage}
                src={imageURLs.back}
                onError={() => setBackFaceFailed(true)}
              />
            )}
          </div>
        ) : (
          <div
            className={classNames(styles.hover, {
              [styles.hoverDoubleFaced]: isDoubleFaced,
            })}
            style={{ top: cursorPosition[1], left: cursorPosition[0] }}
          >
            <div
              className={classNames(styles.hoverContent, {
                [styles.hoverContentUp]:
                  clientCursorPosition[1] > window.innerHeight - 330,
                [styles.hoverContentLeft]:
                  clientCursorPosition[0] >
                  window.innerWidth - (isDoubleFaced ? 480 : 240),
              })}
            >
              <img className={styles.hoverImage} src={imageURLs.front} />
              {imageURLs.back && !backFaceFailed && (
                <img
                  className={styles.hoverImage}
                  src={imageURLs.back}
                  onError={() => setBackFaceFailed(true)}
                />
              )}
            </div>
          </div>
        ),
        document.getElementById('card-hover-overlay')!,
      )
    : null

  return {
    hoverProps: isTouchDevice
      ? {
          onClick: () => setVisible(true),
        }
      : {
          onMouseEnter: () => setVisible(true),
          onMouseLeave: () => setVisible(false),
          onMouseMove: (event: React.MouseEvent) => {
            setCursorPosition([event.pageX, event.pageY])
            setClientCursorPosition([event.clientX, event.clientY])
          },
        },
    portal,
  }
}

interface Props {
  card?: string
  set?: string
  id?: string
  children: React.ReactNode
  disabled?: boolean
}

export const UnstyledCardHover: React.FC<Props> = (props) => {
  const { card, set, id, children, disabled } = props

  const { hoverProps, portal } = useCardHover(
    card ?? (children as any),
    set,
    id,
  )

  return (
    <>
      <div {...hoverProps}>{children}</div>
      {!disabled && portal}
    </>
  )
}

const CardHover: React.FC<Props> = (props) => {
  const { card, set, id, children, disabled } = props

  const { hoverProps, portal } = useCardHover(
    card ?? (children as any),
    set,
    id,
  )

  return (
    <>
      <span className={styles.container} {...hoverProps}>
        {children}
      </span>
      {!disabled && portal}
    </>
  )
}

export default CardHover
