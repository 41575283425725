import * as styles from './AllSets.module.scss'

import React, { useMemo } from 'react'

import { useCSV } from 'utils/useCSV'

import { SetStatistics } from './SetStatistics'
import { Statistics } from './types'

interface Props {
  sets: {
    code: string
    name: string
    path: string
    iconURL: string
  }[]
  statisticsData: string
  setCardPlayCounts?: Record<string, number[]>
  maxCardPlayCount?: number
}

export const AllSets: React.FC<Props> = (props) => {
  const { sets, statisticsData, setCardPlayCounts } = props

  const setStatistics = useCSV<Statistics>(statisticsData)

  if (setStatistics == null) {
    throw new Error('Unable to load set statistics')
  }

  const maxCardPlayCount = useMemo(() => {
    return (
      props.maxCardPlayCount ??
      (setCardPlayCounts
        ? Math.max(...Object.values(setCardPlayCounts).flatMap((d) => d))
        : 0)
    )
  }, [props.maxCardPlayCount, setCardPlayCounts])

  const setsWithStatistics = useMemo(() => {
    return sets.map((set) => {
      const statistics = setStatistics.find((stats) => stats.code === set.code)

      if (statistics == null) {
        throw new Error(`Unable to find statistics for set ${set.code}`)
      }

      return {
        ...set,
        ...statistics,
        cardPlayCounts: setCardPlayCounts?.[set.code],
      }
    })
  }, [setCardPlayCounts, setStatistics, sets])

  return (
    <div className={styles.container}>
      <div className={styles.sets}>
        {setsWithStatistics.map((set) => {
          return (
            <SetStatistics
              key={set.code}
              set={set}
              maxCardPlayCount={maxCardPlayCount}
            />
          )
        })}
      </div>
    </div>
  )
}
