import { useMemo } from 'react'
import * as d3 from 'd3'

import { useFetchText } from 'utils/useFetch'

// Internally types could be improved. Technically d3's auto type function which
// is used to transform rows by default can return null or undefined, but that's
// made opaque.

export const useCSV = <T>(
  data: string | null,
  transformRow: (row: Record<string, string>, index: number) => T = (d3 as any)
    .autoType,
): T[] | null => {
  const parsedData: T[] = useMemo(() => {
    if (!data) {
      return null
    }

    return d3.csvParse(data, transformRow as any) as any
    // TODO: Ensure this doesn't re-execute safely without excluding the
    // transform function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return parsedData
}

export const useCSVRows = <T>(data: string): T[] => {
  const parsedData: T[] = useMemo(() => {
    return d3.csvParseRows(data, (d3 as any).autoType) as any
  }, [data])

  return parsedData
}

export const useFetchCSV = <T>(
  url: string,
  transformRow?: (row: Record<string, string>, index: number) => T,
): T[] | null => {
  const { data } = useFetchText(url)

  return useCSV<T>(data, transformRow)
}
