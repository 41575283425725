import React from 'react'
import ScatterPlot from 'components/visualization/ScatterPlot'

const SpiralPlot: React.FC = () => {
  return (
    <div>
      <ScatterPlot
        data={[
          [45.7, 23.9],
          [42.7, 31.8],
          [41.4, 34.7],
          [38.8, 34.7],
          [38.8, 41.2],
          [30.9, 41.4],
          [22, 41.4],
          [23.3, 43.5],
          [26.7, 43.5],
          [6.9, 33.8],
          [19, 9.1],
          [19, 20],
          [17.2, 41.2],
          [9.8, 41.2],
          [9.5, 33],
          [27.7, 44.4],
          [11.6, 38.8],
          [14.6, 38.3],
          [25.1, 42.6],
          [30.4, 43.2],
          [5.6, 32.5],
          [6.9, 24.7],
          [6.9, 16.1],
          [12.8, 11.6],
          [18.1, 21.2],
          [17.2, 24.3],
          [18.1, 28.5],
          [21.5, 31.6],
          [24.2, 29.9],
          [16, 27],
          [26, 29.9],
          [31.2, 29.5],
          [33.9, 23.4],
          [35.1, 19.1],
          [32.1, 16.1],
          [30.9, 11.3],
          [27.7, 11.3],
          [25.5, 9.8],
          [18.1, 8.5],
          [21, 9.5],
          [33.1, 26.4],
          [9.2, 12.4],
          [7.7, 19.1],
          [4.7, 26.7],
          [4.4, 21.7],
          [16.9, 10.9],
          [23.6, 21.2],
          [10, 37.7],
          [6.8, 18.5],
          [33.3, 14.9],
          [16.9, 27.1],
          [19, 42.3],
          [27.2, 32.5],
          [35.7, 39.7],
          [29.5, 31.4],
          [19, 30.4],
          [20.9, 20.8],
          [23.6, 22.8],
          [22.5, 19.5],
          [16.4, 22.5],
          [14.6, 9.1],
          [14.9, 11.3],
          [10.7, 13.6],
          [11.2, 11.3],
          [12.4, 9],
          [22.5, 12.4],
          [22.8, 8.5],
          [27.7, 6.9],
          [30.9, 13],
          [33.3, 19.5],
          [36, 26.5],
          [40.4, 37.3],
          [44.8, 30.2],
          [44.3, 27.6],
          [45.4, 28.1],
          [34.2, 41.4],
          [33.1, 44.2],
          [17.2, 44.4],
          [24.5, 46.7],
          [3.5, 30.4],
          [6, 29.3],
          [6, 20.8],
          [4.5, 16.8],
          [9.2, 15.8],
          [7.3, 34],
          [13.3, 40.6],
          [24.2, 32.3],
          [6, 38.2],
          [5, 24.2],
          [33.6, 17.5],
          [35.1, 13],
          [28.6, 14.9],
          [34.5, 20],
          [33.9, 28.6],
          [39, 37.9],
          [44.4, 32.5],
          [20.6, 43.7],
          [8.6, 36.5],
          [15.1, 42.3],
        ]}
        horizontalAxisLabel="Dimension 1"
        verticalAxisLabel="Dimension 2"
        paths={[
          'M22.6,23.2c0.5-0.2,0.8-0.5,0.9-0.9c0.1-0.8-0.4-1.5-0.9-1.8c-0.9-0.6-2-0.6-3-0.2c-1.4,0.6-2.3,1.8-2.7,3c-0.6,1.7-0.3,3.6,0.6,5.1c1.1,1.8,3,3.1,5.1,3.6c2.5,0.6,5.1-0.1,7.2-1.5c2.4-1.7,4-4.3,4.4-7.2c0.5-3.2-0.5-6.5-2.4-9.1c-2.2-2.9-5.5-4.8-9.1-5.3c-4-0.6-8.1,0.7-11.2,3.1C8,14.8,5.8,18.9,5.3,23.2c-0.5,4.8,1,9.6,4.1,13.3c3.2,4,8.1,6.5,13.3,6.9c5.4,0.5,11.2-1.4,15.3-4.9c4.5-3.8,7.4-9.5,7.8-15.3',
        ]}
      />
    </div>
  )
}

export default SpiralPlot
