import * as styles from './Column.module.scss'

import React from 'react'
import classNames from 'classnames'

import CardImage from 'components/cards/CardImage'
import CardHover from 'components/cards/CardHover'

interface Props {
  cards: { name: string; set?: string; id?: string; count?: number }[]
}

const Column: React.FC<Props> = (props) => {
  const { cards } = props

  return (
    <div>
      {cards.map((card, index) => (
        <div
          className={classNames(styles.card, {
            [styles.withCount]: card.count != null,
          })}
          key={index}
        >
          <CardHover card={card.name} set={card.set} id={card.id}>
            <CardImage set={card.set} id={card.id}>
              {card.name}
            </CardImage>
          </CardHover>

          {card.count && <div className={styles.count}>{card.count}x</div>}
        </div>
      ))}
    </div>
  )
}

export default Column
