import * as styles from './Divider.module.scss'

import React from 'react'
import Logo from 'components/Logo'

const Divider: React.FC = () => {
  return (
    <div className={styles.divider}>
      <Logo size="1.5em" />
    </div>
  )
}

export default Divider
