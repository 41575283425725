import * as styles from './CardsAsDimensions.module.scss'

import React from 'react'

import ScatterPlot from 'components/visualization/ScatterPlot'

import CardSetColumn, {
  Props as ColumnProps,
} from 'components/article-elements/card-set/Column'

import ContrivedDecklists from './ContrivedDecklists'

const Tooltip: React.FC<{ x: number; y: number; column: ColumnProps }> = (
  props,
) => {
  const { column } = props

  return (
    <div className={styles.tooltip}>
      <CardSetColumn {...column} />
    </div>
  )
}

const CardsAsDimensions: React.FC = () => {
  return (
    <div>
      <ScatterPlot
        data={[
          {
            x: 0,
            y: 0,
            column: {
              label: 'List 1',
              sections: [
                {
                  label: 'Empty List',
                  cards: [],
                },
              ],
            },
          },
          {
            x: 1,
            y: 0,
            column: {
              label: 'List 3',
              sections: [
                {
                  label: 'Card',
                  cards: [{ name: 'Lightning Bolt', color: 'r' }],
                },
              ],
            },
          },
          {
            x: 0,
            y: 1,
            column: {
              label: 'List 2',
              sections: [
                {
                  label: 'Card',
                  cards: [{ name: 'Ponder', color: 'u' }],
                },
              ],
            },
          },
          {
            x: 1,
            y: 1,
            column: {
              label: 'List 4',
              sections: [
                {
                  label: 'Cards',
                  cards: [
                    { name: 'Ponder', color: 'u' },
                    { name: 'Lightning Bolt', color: 'r' },
                  ],
                },
              ],
            },
          },
        ]}
        horizontalAxisLabel="Lightning Bolt"
        verticalAxisLabel="Ponder"
        showIntervals={true}
        renderTooltip={Tooltip}
      />

      <ContrivedDecklists />
    </div>
  )
}

export default CardsAsDimensions
