import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

export const MarduTriggers: React.FC = () => {
  return (
    <Decklist
      name="Mardu Triggers"
      drafter="Parker LaMascus"
      description={
        <>
          My deck’s <CardHover card="Drannith Stinger">Cycling</CardHover>{' '}
          <CardHover card="Unearth">cards</CardHover> help mitigate variance,
          but its <CardHover card="Irencrag Pyromancer">power</CardHover>{' '}
          <CardHover card="Sevinne's Reclamation">outliers</CardHover> and{' '}
          <CardHover card="Hall of Oracles">roleplayers</CardHover> give those
          anti-mana-screw tools a massive boost of synergy.{' '}
        </>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'ZNR' },
          { name: 'Plains', set: 'ZNR' },
          { name: 'Swamp', set: 'ZNR' },
          { name: 'Swamp', set: 'ZNR' },
          { name: 'Mountain', set: 'ZNR' },
          { name: 'Mountain', set: 'ZNR' },
          { name: 'Mountain', set: 'ZNR' },
          { name: 'Mountain', set: 'ZNR' },
          { name: 'Access Tunnel', set: 'STX' },
          { name: 'Petrified Field' },
          { name: 'Hall of Oracles', set: 'STX' },
          { name: 'Ash Barrens', set: 'C16' },
          { name: 'Ramunap Ruins', set: 'HOU' },
          { name: 'Silent Clearing', set: 'MH1' },
          { name: 'Nurturing Peatland', set: 'MH1' },
          { name: 'Kabira Takedown // Kabira Plateau', set: 'ZNR' },
        ],
        [
          { name: 'Drannith Stinger' },
          { name: 'Drannith Stinger' },
          { name: 'Go for Blood' },
          { name: 'Memory Leak' },
          { name: 'Flourishing Fox' },
          { name: 'Deadeye Tracker' },
          { name: 'Unholy Heat' },
          { name: 'Bone Shards' },
          { name: 'Burst Lightning' },
          { name: 'Unearth' },
        ],
        [
          { name: 'Magmatic Channeler' },
          { name: 'Conspiracy Theorist' },
          { name: 'Electrostatic Infantry' },
          { name: 'Fearless Fledgling' },
          { name: 'Baird, Argivian Recruiter' },
          { name: 'Bloodghast' },
          { name: 'Balance' },
        ],
        [
          { name: 'Irencrag Pyromancer' },
          { name: 'Subira, Tulzidi Caravanner' },
          { name: "Sevinne's Reclamation" },
          { name: 'Lagomos, Hand of Hatred' },
        ],
        [
          { name: 'Queen Marchesa' },
          { name: "Basri's Lieutenant" },
          { name: 'Braids, Cabal Minion' },
        ],
      ]}
    />
  )
}
