import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

export const Museum1: React.FC = () => {
  return (
    <Decklist
      label="Museum of Modern"
      labelLink="https://cubecobra.com/cube/overview/ModernMuseum"
      name="Naya Zoo"
      description={
        <>
          The deck that got{' '}
          <CardHover card="Wild Nacatl">Wild Nacatl</CardHover> banned.
        </>
      }
      maindeck={[
        [
          { name: 'Misty Rainforest', set: 'MH2' },
          { name: 'Verdant Catacombs', set: 'MH2' },
          { name: 'Scalding Tarn', set: 'MH2' },
          { name: 'Scalding Tarn', set: 'MH2' },
          { name: 'Arid Mesa', set: 'MH2' },
          { name: 'Arid Mesa', set: 'MH2' },
          { name: 'Wooded Foothills', set: 'KTK' },
          { name: 'Windswept Heath', set: 'KTK' },
          { name: 'Godless Shrine', set: 'GTC' },
          { name: 'Stomping Ground', set: 'GTC' },
          { name: 'Stomping Ground', set: 'GTC' },
          { name: 'Sacred Foundry', set: 'GTC' },
          { name: 'Temple Garden', set: 'RTR' },
          { name: 'Temple Garden', set: 'RTR' },
          { name: 'Copperline Gorge', set: 'SOM' },
          { name: 'Forest', set: 'RTR' },
        ],
        [
          { name: 'Narnam Renegade' },
          { name: 'Narnam Renegade' },
          { name: 'Wild Nacatl' },
          { name: 'Goblin Guide' },
          { name: 'Steppe Lynx' },
          { name: 'Experiment One' },
          { name: 'Lightning Bolt' },
          { name: 'Dismember' },
          { name: 'Path to Exile', set: 'CON' },
        ],
        [
          { name: 'Tarmogoyf', set: 'MMA' },
          { name: 'Burning-Tree Emissary', set: 'GTC' },
          { name: 'Burning-Tree Emissary', set: 'GTC' },
          { name: 'Voltaic Brawler' },
          { name: 'Voltaic Brawler' },
          { name: 'Thalia, Guardian of Thraben', set: 'DKA' },
          { name: 'Harsh Mentor' },
          { name: "Dromoka's Command" },
          { name: 'Ghor-Clan Rampager', set: 'GTC' },
        ],
        [
          { name: 'Lingering Souls' },
          { name: 'Kitchen Finks' },
          { name: 'Woolly Thoctar', set: 'ALA' },
          { name: 'Loxodon Smiter', set: 'RTR' },
        ],
        [
          { name: 'Bloodbraid Elf', set: 'ARB' },
          { name: 'Bloodbraid Elf', set: 'ARB' },
        ],
      ]}
    />
  )
}
