import * as styles from './Lands.module.scss'

import React, { useState } from 'react'

import CardImage from 'components/cards/CardImage'

const lands = [
  ['Plains', 'Island', 'Swamp', 'Mountain', 'Forest'],
  [
    'Coastal Tower',
    'Salt Marsh',
    'Urborg Volcano',
    'Shivan Oasis',
    'Elfhame Palace',
  ],
]

const columns = lands[0].length
const rows = lands.length

const Lands: React.FC = () => {
  const [hovered, setHovered] = useState<[number, number] | null>(null)

  return (
    <div className={styles.container}>
      {lands.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          {row.map((card, columnIndex) => {
            const position = {
              x: columnIndex - (columns - 1) / 2,
              y: rowIndex - (rows - 1) / 2,
            }

            const transform = `translate(0, ${
              100 + position.y * 12
            }vw) rotate(${
              position.x * (position.y > 0 ? 8 : 7.5)
            }deg) translate(0, ${-100}vw) rotate(${-position.x * 3}deg)`

            const hoverStyle =
              hovered && hovered[0] === rowIndex && hovered[1] === columnIndex
                ? {
                    transform: `rotate(${
                      -(position.x * (position.y > 0 ? 8 : 7.5)) +
                      position.x * 3
                    }deg) scale(1.2, 1.2)`,
                  }
                : {}

            return (
              <div
                key={columnIndex}
                className={styles.card}
                style={{
                  transform,
                }}
              >
                <div
                  className={styles.cardImage}
                  onMouseEnter={() => setHovered([rowIndex, columnIndex])}
                  onMouseLeave={() => setHovered(null)}
                  style={hoverStyle}
                >
                  <CardImage set="inv">{card}</CardImage>
                </div>
              </div>
            )
          })}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Lands
