import React from 'react'

import ScatterPlot from 'components/visualization/ScatterPlot'

import { useCSVRows } from 'utils/useCSV'

import data from 'raw-loader!./pca-sampled-data-commander.csv'

const PCAPlot: React.FC = () => {
  const points = useCSVRows<[number, number]>(data)

  return (
    <ScatterPlot
      data={points}
      horizontalAxisLabel="Dimension 1 (2% of Information)"
      verticalAxisLabel="Dimension 2 (1% of Information)"
      pointSize={2}
      strokePoints={false}
    />
  )
}

export default PCAPlot
