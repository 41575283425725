import * as styles from './ResponseCard.module.scss'

import React from 'react'

interface Props {
  id: string
  designer: string
  cubeName: string
  cubeLink: string
  answerOne: React.ReactNode
  answerTwo: React.ReactNode
  answerThree: React.ReactNode
  answerFour: React.ReactNode
}

const ResponseCard: React.FC<Props> = (props) => {
  const {
    id,
    designer,
    cubeName,
    cubeLink,
    answerOne,
    answerTwo,
    answerThree,
    answerFour,
  } = props

  return (
    <section id={id} className={styles.container}>
      <div className={styles.metaRow}>
        <div className={styles.metaRowLabel}>Cube</div>
        <div>
          <a className={styles.metaRowValueLink} href={cubeLink}>
            {cubeName}
          </a>
        </div>
      </div>
      <div className={styles.metaRow}>
        <div className={styles.metaRowLabel}>Designer</div>
        <div>{designer}</div>
      </div>
      <div className={styles.questions}>
        <div className={styles.questionsLabel}>Context</div>
        <div className={styles.questionsAnswer}>{answerOne}</div>
        <div className={styles.questionsLabel}>Restrictions</div>
        <div className={styles.questionsAnswer}>{answerTwo}</div>
        <div className={styles.questionsLabel}>Power</div>
        <div className={styles.questionsAnswer}>{answerThree}</div>
        <div className={styles.questionsLabel}>Gameplay</div>
        <div className={styles.questionsAnswer}>{answerFour}</div>
      </div>
    </section>
  )
}

export default ResponseCard
