import * as styles from './Banner.module.scss'

import React, { useMemo } from 'react'
import { Link } from 'gatsby'

import sets from './sets.json'

const width = 1200
const height = 400

function positionFor(index: number) {
  const radius = index * 2.3 + 10
  const angle = Math.sqrt(index) * 4.9
  const size = Math.sqrt(radius) * 3.2

  return {
    left: width / 2 + Math.cos(angle) * radius,
    top: height / 2 + Math.sin(angle) * radius,
    width: `${size}px`,
    height: `${size}px`,
  }
}

type PointStyles = ReturnType<typeof positionFor>

function pointInContainer(point: PointStyles) {
  const size = parseInt(point.width) / 2 + 5

  return (
    Math.sqrt(
      Math.pow(point.top - height / 2, 2) +
        Math.pow((point.left - width / 2) * 0.21, 2),
    ) <
    height / 2 - size
  )
}

export const SetCubeBanner: React.FC = () => {
  const points = useMemo(() => {
    const result: {
      set?: { name: string; code: string; icon_svg_uri: string }
      styles: PointStyles
    }[] = []

    let index = 0
    let setIndex = 0
    while (index < 500) {
      const styles = positionFor(index)

      if (pointInContainer(styles) && setIndex < sets.length) {
        result.push({
          set: sets[setIndex],
          styles,
        })
        setIndex++
      }

      if (styles.top < -50 && styles.left < -50) {
        break
      }

      index++
    }

    return result
  }, [])

  return (
    <div className={styles.container} style={{ height }}>
      <div className={styles.setsContainer} style={{ width, height }}>
        <div className={styles.sets}>
          {points.map((point, index) => (
            <Link
              to="/resources/set-cube-builder/"
              state={{ setCode: point.set?.code }}
              className={styles.set}
              style={point.styles}
              key={index}
              title={
                point.set != null
                  ? `Build a ${point.set?.name} Cube`
                  : undefined
              }
            >
              <img
                src={
                  point.set?.icon_svg_uri ??
                  'https://svgs.scryfall.io/sets/default.svg'
                }
                className={styles.icon}
              />
            </Link>
          ))}
        </div>
      </div>

      <div className={styles.content}>
        <Link to="/resources/set-cube-builder/" className={styles.title}>
          Build a Set Cube List &rarr;
        </Link>
      </div>
    </div>
  )
}
