import * as styles from './Ratings.module.scss'

import React from 'react'

import CardHover from 'src/components/cards/CardHover'

import ratings from './ratings.json'

export const OTJRatings: React.FC = () => {
  return (
    <div className={styles.container}>
      {ratings.map((category) => (
        <div key={category.name} className={styles.category}>
          <div className={styles.categoryHeading}>{category.name}</div>
          <div className={styles.categoryCards}>
            {category.cards.map((card) => (
              <div key={card.name}>
                <CardHover>{card.name}</CardHover> - {card.rating}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
