import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'

export const MedAncTimes: React.FC = () => {
  return (
    <Decklist
      label="Medium Ancient Times"
      labelLink="https://cubecobra.com/cube/overview/mediumancienttimes"
      name="Parallax Junk"
      description={
        <>More or less trying to make our whole team impossible to kill.</>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'INV' },
          { name: 'Plains', set: 'INV' },
          { name: 'Plains', set: 'INV' },
          { name: 'Swamp', set: 'INV' },
          { name: 'Swamp', set: 'INV' },
          { name: 'Swamp', set: 'INV' },
          { name: 'Forest', set: 'INV' },
          { name: 'Forest', set: 'INV' },
          { name: 'Forest', set: 'INV' },
          { name: 'Forest', set: 'INV' },
          { name: 'City of Brass', set: '5ED' },
          { name: 'City of Brass', set: '5ED' },
          { name: 'Thran Quarry' },
          { name: 'Thran Quarry' },
          { name: "Treva's Ruins", set: 'PLS' },
          { name: 'Gemstone Mine', set: 'WTH' },
          {
            name: 'Yavimaya, Cradle of Growth',
            set: 'MH2',
            id: 'b0063a44-6cde-4d7e-81e1-61bae08831ee',
          },
        ],
        [
          { name: 'Birds of Paradise', set: '5ED' },
          {
            name: 'Ignoble Hierarch',
            set: 'MH2',
            id: '9417f14c-a853-42c6-a992-29139484260a',
          },
          { name: 'Cursed Scroll', set: 'TMP' },
          { name: 'Rancor', set: 'ULG' },
        ],
        [
          { name: 'River Boa', set: '6ED' },
          { name: 'Kavu Titan', set: 'INV' },
          { name: 'Quirion Elves', set: 'INV' },
          {
            name: 'Whitemane Lion',
            set: 'DMR',
            id: '0fbe919e-13c8-44de-a778-8e6ce0b16a58',
          },
          { name: 'Rofellos, Llanowar Emissary', set: 'UDS' },
          { name: 'Spectral Lynx', set: 'APC' },
          { name: 'Animate Dead', set: '5ED' },
        ],
        [
          { name: 'Soltari Visionary' },
          { name: 'Auramancer', set: 'ODY' },
          { name: 'Lieutenant Kirtar' },
          { name: 'Soltari Champion', set: 'STH' },
          { name: 'Soul Sculptor' },
          { name: 'Banishing Light', set: 'tsr' },
          { name: 'Squirrel Nest', set: 'ODY' },
        ],
        [
          { name: 'Faceless Butcher', set: 'TOR' },
          { name: 'Parallax Wave', set: 'NEM' },
        ],
        [
          { name: 'Karmic Guide', set: 'ULG' },
          { name: 'Phyrexian Plaguelord', set: 'ULG' },
          { name: 'Rhox', set: 'NEM' },
        ],
      ]}
    />
  )
}
