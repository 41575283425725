import * as styles from './Indicator.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  size?: string
  inline?: boolean
}

const Indicator: React.FC<Props> = (props) => {
  const { size, inline = false } = props

  return (
    <div
      className={classNames(styles.indicator, {
        [styles.inline]: inline,
      })}
      style={size ? { width: size, height: size } : {}}
    />
  )
}

export default Indicator
