import * as styles from './UnorderedList.module.scss'

import React from 'react'

import TextBlock from './TextBlock'

interface Props {
  children: React.ReactNode
}

const UnorderedList: React.FC<Props> = (props) => {
  const { children } = props

  return (
    <TextBlock>
      <ul className={styles.list}>{children}</ul>
    </TextBlock>
  )
}

export default UnorderedList
