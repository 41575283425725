import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const BantControl: React.FC = () => {
  return (
    <Decklist
      name="Bomberman"
      drafter="Andy Mangold"
      description={
        <>
          <CardHover card="Auriok Salvagers">Bomberman</CardHover> is a bit
          slower than other combos in the <em>Degenerate Micro Cube</em>, but
          what it lacks in speed it makes up for in resilience: all that matters
          is that you are able to resolve{' '}
          <CardHover card="Auriok Salvagers">Auriok Salvagers</CardHover>, it
          doesn&rsquo;t matter whether the other combo pieces are in your hand,
          in play, in your graveyard, or even in your library. It&rsquo;s also
          one of the only combos that can win without passing the turn.
        </>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Mana Confluence' },
          { name: 'Tolarian Academy', set: 'USG' },
        ],
        [
          { name: 'Jeweled Amulet', set: 'ICE' },
          { name: "Lion's Eye Diamond", set: 'MIR' },
          { name: 'Scrabbling Claws' },
          { name: 'Aether Spellbomb' },
          { name: 'Pyrite Spellbomb' },
        ],
        [
          { name: 'Path to Exile', set: 'PWP09' },
          { name: 'Balance', set: 'LEB' },
          { name: "Memory's Journey" },
          { name: "Council's Judgment" },
        ],
        [{ name: 'Auriok Salvagers', set: '5DN' }],
      ]}
      sideboard={[
        { name: 'Mana Tithe' },
        { name: 'Giver of Runes' },
        { name: 'Mother of Runes', set: 'ULG' },
        { name: 'Hushbringer' },
        { name: 'Remorseful Cleric' },
        { name: 'Pacification Array', set: 'AER' },
        { name: 'Engineered Explosives' },
        { name: 'Inkwell Leviathan' },
      ]}
    />
  )
}

export default BantControl
