import React from 'react'

import CardSet from 'components/article-elements/card-set/CardSet'

const DegenerateMicroCubeList: React.FC = () => {
  return (
    <CardSet
      columns={[
        {
          label: 'White',
          color: 'w',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Giant Killer' },
                { name: 'Giver of Runes' },
                { name: 'Mother of Runes', set: 'ULG' },
                { name: 'Aegis of the Gods' },
                { name: 'Containment Priest' },
                { name: 'Hushbringer' },
                { name: 'Leonin Relic-Warder' },
                { name: 'Remorseful Cleric' },
                { name: 'Thalia, Guardian of Thraben' },
                { name: 'Vryn Wingmare' },
                { name: 'Auriok Salvagers' },
              ],
            },
            {
              label: 'Planeswalker',
              cards: [{ name: 'Gideon of the Trials' }],
            },
            {
              label: 'Enchantment',
              cards: [{ name: 'Journey to Nowhere' }],
            },
            {
              label: 'Instants',
              cards: [
                { name: 'Mana Tithe' },
                { name: 'Path to Exile' },
                { name: 'Swords to Plowshares' },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                { name: 'Balance' },
                { name: "Council's Judgment" },
                { name: "Sevinne's Reclamation" },
              ],
            },
          ],
        },
        {
          label: 'Blue',
          color: 'u',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Gilded Drake' },
                { name: 'Snapcaster Mage' },
                { name: 'Emry, Lurker of the Loch' },
                { name: 'Sphinx of Foresight' },
              ],
            },
            {
              label: 'Artifact',
              cards: [{ name: 'Aether Spellbomb' }],
            },
            {
              label: 'Instants',
              cards: [
                { name: 'Daze' },
                { name: 'Disrupting Shoal' },
                { name: 'Foil' },
                { name: 'Force of Negation' },
                { name: 'Force of Will' },
                { name: 'Ancestral Recall' },
                { name: 'Brainstorm' },
                { name: 'Concerted Defense' },
                { name: 'Flusterstorm' },
                { name: 'Force Spike' },
                { name: 'Miscast' },
                { name: 'Spell Pierce' },
                { name: 'Stubborn Denial' },
                { name: 'Unsummon' },
                { name: 'Echoing Truth' },
                { name: 'Flash' },
                { name: 'Mana Drain' },
                { name: 'Mana Leak' },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                { name: 'Careful Study' },
                { name: 'Time Walk' },
                { name: 'Show and Tell' },
                { name: 'Tinker' },
              ],
            },
          ],
        },
        {
          label: 'Black',
          color: 'b',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Brain Maggot' },
                { name: 'Kitesail Freebooter' },
                { name: 'Mesmeric Fiend' },
                { name: 'Vampire Hexmage' },
                { name: 'Murderous Rider' },
              ],
            },
            {
              label: 'Planeswalkers',
              cards: [
                { name: 'Liliana of the Veil' },
                { name: 'Liliana, the Last Hope' },
              ],
            },
            {
              label: 'Enchantment',
              cards: [{ name: 'Animate Dead' }],
            },
            {
              label: 'Instants',
              cards: [
                { name: 'Force of Despair' },
                { name: 'Dark Ritual' },
                { name: 'Entomb' },
                { name: 'Diabolic Edict' },
                { name: "Liliana's Triumph" },
              ],
            },
            {
              label: 'Sorceries',
              cards: [
                { name: 'Unmask' },
                { name: 'Duress' },
                { name: 'Inquisition of Kozilek' },
                { name: 'Mind Twist' },
                { name: 'Reanimate' },
                { name: 'Thoughtseize' },
                { name: 'Agonizing Remorse' },
                { name: 'Collective Brutality' },
                { name: 'Toxic Deluge' },
              ],
            },
          ],
        },
        {
          label: 'Red',
          color: 'r',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Goblin Welder' },
                { name: 'Goblin Cratermaker' },
                { name: 'Goblin Engineer' },
                { name: 'Greater Gargadon' },
              ],
            },
            {
              label: 'Artifact',
              cards: [{ name: 'Pyrite Spellbomb' }],
            },
            {
              label: 'Enchantment',
              cards: [{ name: 'Sneak Attack' }],
            },
            {
              label: 'Instant',
              cards: [{ name: 'Abrade' }],
            },
            {
              label: 'Sorceries',
              cards: [
                { name: 'Faithless Looting' },
                { name: "Red Sun's Zenith" },
              ],
            },
          ],
        },
        {
          label: 'Green',
          color: 'g',
          sections: [
            {
              label: 'Creature',
              cards: [{ name: 'Scavenging Ooze' }],
            },
            {
              label: 'Enchantment',
              cards: [{ name: 'Oath of Druids' }],
            },
            {
              label: 'Instants',
              cards: [{ name: 'Crop Rotation' }, { name: "Nature's Claim" }],
            },
            {
              label: 'Sorceries',
              cards: [{ name: 'Serene Remembrance' }, { name: 'Channel' }],
            },
          ],
        },
        {
          label: 'Miscellaneous',
          color: 'm',
          sections: [
            {
              label: 'Colorless Cards',
              cards: [
                { name: 'Gitaxian Probe' },
                { name: 'Mental Misstep' },
                { name: 'Noxious Revival' },
                { name: 'Once Upon a Time' },
                { name: 'Dismember' },
                { name: 'Elvish Spirit Guide' },
                { name: 'Simian Spirit Guide' },
              ],
            },
            {
              label: 'Hybrid Cards',
              cards: [
                { name: 'Deathrite Shaman' },
                { name: "Judge's Familiar" },
                { name: "Memory's Journey" },
                { name: 'Lurrus of the Dream-Den' },
                { name: 'Unburial Rites' },
              ],
            },
            {
              label: 'Multicolored Cards',
              cards: [
                { name: 'Abrupt Decay' },
                { name: "Assassin's Trophy" },
                { name: 'Oko, Thief of Crowns' },
              ],
            },
            {
              label: 'Cheat Targets',
              cards: [
                { name: 'Wurmcoil Engine' },
                { name: 'Chancellor of the Annex' },
                { name: 'Ashen Rider' },
                { name: 'Woodfall Primus' },
                { name: 'Inkwell Leviathan' },
                { name: 'Iona, Shield of Emeria' },
                { name: 'Nullstone Gargoyle' },
                { name: 'Void Winnower' },
                { name: 'Kozilek, Butcher of Truth' },
                { name: 'Ulamog, the Infinite Gyre' },
                { name: 'Worldspine Wurm' },
                { name: 'Blightsteel Colossus' },
                { name: 'Emrakul, the Aeons Torn' },
              ],
            },
          ],
        },
        {
          label: 'Artifacts',
          color: 'c',
          sections: [
            {
              label: 'Creatures',
              cards: [
                { name: 'Stonecoil Serpent' },
                { name: 'Walking Ballista' },
                { name: 'Hope of Ghirapur' },
                { name: 'Phyrexian Revoker' },
                { name: 'Phyrexian Metapmorph' },
                { name: 'Lodestone Golem' },
              ],
            },
            {
              label: 'Non-Creatures',
              cards: [
                { name: 'Chrome Mox' },
                { name: 'Engineered Explosives' },
                { name: 'Jeweled Amulet' },
                { name: "Lion's Eye Diamond" },
                { name: 'Lotus Petal' },
                { name: 'Mox Diamond' },
                { name: 'Mox Opal' },
                { name: 'Aether Vial' },
                { name: 'Chromatic Star' },
                { name: "Conjurer's Bauble" },
                { name: 'Elixir of Immortality' },
                { name: "Feldon's Cane" },
                { name: 'Mana Vault' },
                { name: 'Pacification Array' },
                { name: 'Pithing Needle' },
                { name: 'Scrabbling Claws' },
                { name: "Sensei's Divining Top" },
                { name: 'Sol Ring' },
                { name: 'Ratchet Bomb' },
                { name: 'Sorcerous Spyglass' },
                { name: 'Sphere of Resistance' },
                { name: 'Thorn of Amethyst' },
                { name: 'Ensnaring Bridge' },
                { name: 'Tangle Wire' },
                { name: 'Trinisphere' },
              ],
            },
          ],
        },
        {
          label: 'Lands',
          color: 'l',
          sections: [
            {
              label: 'Fixing Lands',
              cards: [
                { name: 'City of Brass' },
                { name: 'Forbidden Orchard' },
                { name: 'Gemstone Caverns' },
                { name: 'Gemstone Mine' },
                { name: 'Mana Confluence' },
                { name: 'Tarnished Citadel' },
                { name: 'Tendo Ice Bridge' },
                { name: 'Undiscovered Paradise' },
              ],
            },
            {
              label: 'Utility & Ramp Lands',
              cards: [
                { name: 'Ancient Tomb' },
                { name: 'Bazaar of Baghdad' },
                { name: 'City of Traitors' },
                { name: 'Dark Depths' },
                { name: "Mishra's Factory" },
                { name: "Mishra's Workshop" },
                { name: 'Rishadan Port' },
                { name: "Thespian's Stage" },
                { name: 'Tolarian Academy' },
                { name: 'Wasteland' },
              ],
            },
          ],
        },
      ]}
    />
  )
}

export default DegenerateMicroCubeList
