import CardRankings from "../../../../src/components/card-rankings/CardRankings";
import otjRatings from "../../../../src/data/articles/cube-prospective-outlaws-of-thunder-junction/otj-prospective-ratings.json";
import ProspectiveIntro from "../../../../src/components/article-elements/ProspectiveIntro.tsx";
import { OTJMechanicsGraph } from "../../../../src/data/articles/cube-prospective-outlaws-of-thunder-junction/OTJMechanicsGraph.tsx";
import * as React from 'react';
export default {
  CardRankings,
  otjRatings,
  ProspectiveIntro,
  OTJMechanicsGraph,
  React
};