import * as styles from './LabelledCubeMap.module.scss'

import React from 'react'

import { LabelledImage } from 'components/maps/labelled-image/LabelledImage'

import mapImage from './cube-map.png'

const width = 2748 / 2 + 60
const height = 2128 / 2 + 60

const features = [
  { x: -11.59511, y: 2.59216, name: 'ONE', setCode: 'ONE' },
  { x: -12.00891, y: 3.52199, name: 'BRO', setCode: 'BRO' },
  { x: -12.15078, y: 4.11066, name: 'DMU', setCode: 'DMU' },
  { x: -11.66409, y: 4.59722, name: 'SNC', setCode: 'SNC' },
  { x: -6.59108, y: 13.73376, name: 'NEO', setCode: 'NEO' },
  { x: -14.72713, y: 3.8033, name: 'VOW', setCode: 'VOW' },
  { x: -15.13327, y: 3.06406, name: 'MID', setCode: 'MID' },
  { x: -15.99274, y: -1.48356, name: 'AFR', setCode: 'AFR' },
  { x: -12.95974, y: 2.93357, name: 'STX', setCode: 'STX' },
  { x: -8.81402, y: -7.67114, name: 'KHM', setCode: 'KHM' },
  { x: -12.00216, y: 0.86738, name: 'ZNR', setCode: 'ZNR' },
  { x: -10.15214, y: 3.32222, name: 'IKO', setCode: 'IKO' },
  { x: -7.10382, y: 7.31899, name: 'THB', setCode: 'THB' },
  { x: -10.87438, y: 3.69319, name: 'ELD', setCode: 'ELD' },
  { x: -9.79867, y: 2.82753, name: 'WAR', setCode: 'WAR' },
  { x: -7.72744, y: -4.0311, name: 'RNA', setCode: 'RNA' },
  { x: -13.08861, y: 4.18268, name: 'DOM', setCode: 'DOM' },
  { x: -12.11548, y: 0.15703, name: 'RIX', setCode: 'RIX' },
  { x: -4.67155, y: 5.48037, name: 'AKH', setCode: 'AKH' },
  { x: -5.91761, y: 5.49059, name: 'KLD', setCode: 'KLD' },
  { x: -12.00064, y: 0.88005, name: 'ZNR', setCode: 'ZNR' },
  { x: -10.14867, y: 3.30162, name: 'IKO', setCode: 'IKO' },
  { x: -9.90457, y: 4.1022, name: 'THB', setCode: 'THB' },
  { x: -10.87682, y: 3.69612, name: 'ELD', setCode: 'ELD' },
  { x: -9.806, y: 2.8285, name: 'WAR', setCode: 'WAR' },
  { x: -13.08913, y: 4.18817, name: 'DOM', setCode: 'DOM' },
  { x: -12.12413, y: 0.16105, name: 'XLN', setCode: 'XLN' },
  { x: -16.0913, y: -8.86731, name: 'UST', setCode: 'UST' },
  { x: -4.67587, y: 5.4789, name: 'AKH', setCode: 'AKH' },
  { x: -5.93203, y: 5.4911, name: 'KLD', setCode: 'KLD' },
  { x: -16.84486, y: 4.12515, name: 'SOI', setCode: 'SOI' },
  { x: -11.91895, y: 1.2191, name: 'BFZ', setCode: 'BFZ' },
  { x: -5.90071, y: 7.17533, name: 'KTK', setCode: 'KTK' },
  { x: -12.9878, y: -1.19937, name: 'CNS', setCode: 'CNS' },
  { x: -5.2299, y: 3.43492, name: 'RTR', setCode: 'RTR' },
  { x: 1.81799, y: 9.92973, name: 'ISD', setCode: 'ISD' },
  { x: -11.81487, y: 2.38411, name: 'SOM', setCode: 'SOM' },
  { x: -2.93857, y: 9.81172, name: 'ROE', setCode: 'ROE' },
  { x: -12.25206, y: 1.34215, name: 'ZEN', setCode: 'ZEN' },
  { x: -12.40033, y: 5.36837, name: 'ALA', setCode: 'ALA' },
  { x: -6.76678, y: -6.78969, name: 'LRW', setCode: 'LRW' },
  { x: -8.8632, y: -7.19372, name: 'CSP', setCode: 'CSP' },
  { x: -7.7194, y: -4.05927, name: 'RAV', setCode: 'RAV' },
  { x: -10.18296, y: -9.92593, name: 'CHK', setCode: 'CHK' },
  { x: -11.81287, y: 2.37937, name: 'NPH', setCode: 'NPH' },
  { x: -12.12923, y: 2.19326, name: 'MRD', setCode: 'MRD' },
  { x: -11.64256, y: -5.72371, name: 'ODY', setCode: 'ODY' },
  { x: -11.36936, y: -5.54835, name: 'ONS', setCode: 'ONS' },
  { x: -14.74271, y: 5.49727, name: 'MMQ', setCode: 'MMQ' },
  { x: -15.81448, y: 6.84647, name: 'HML', setCode: 'HML' },
  { x: -15.85434, y: 6.9983, name: 'FEM', setCode: 'FEM' },
  { x: -14.72038, y: 6.06164, name: 'UGL', setCode: 'UGL' },
  { x: -14.81803, y: 7.57058, name: 'LEA', setCode: 'LEA' },
  { x: -14.79676, y: 6.16796, name: 'TMP', setCode: 'TMP' },
  { x: -15.84992, y: -8.05585, name: 'UNF', setCode: 'UNF' },
  { x: -4.05891, y: 3.16438, name: 'JMP', setCode: 'JMP' },
  { x: -12.08671, y: -9.29875, name: 'MB1', setCode: 'planeswalker' },
  { x: -13.35887, y: 8.57596, name: 'VMA', setCode: 'vma' },
].map((feature) => ({
  ...feature,
  iconURL: feature.setCode
    ? `https://svgs.scryfall.io/sets/${feature.setCode.toLowerCase()}.svg`
    : undefined,
  x: (feature.x + 24) * 43.7,
  y: (feature.y + 14.3) * 37.5,
}))

export const LabelledCubeMap: React.FC = () => {
  return (
    <div className={styles.container}>
      <LabelledImage
        image={mapImage}
        width={width}
        height={height}
        features={features}
      />
    </div>
  )
}
