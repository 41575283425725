import React from 'react'

import { AllSets } from 'components/set-retrospectives/set-statistics/AllSets'

import { sets } from './sets'

import data from 'raw-loader!./set-statistics.csv'

const ArticleSetStatistics: React.FC = () => {
  return <AllSets sets={sets} statisticsData={data} />
}

export default ArticleSetStatistics
