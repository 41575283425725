import * as styles from './SegmentedControl.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  segments: Record<string, string>
  value: string
  onChange(event: React.MouseEvent<HTMLButtonElement>): void
}

export const SegmentedControl: React.FC<Props> = (props) => {
  const { segments, value, onChange } = props

  return (
    <div className={styles.container}>
      {Object.keys(segments).map((segment) => (
        <button
          type="button"
          key={segment}
          value={segment}
          onClick={onChange}
          className={classNames(styles.segment, {
            [styles.selected]: segment === value,
          })}
        >
          {segments[segment]}
        </button>
      ))}
    </div>
  )
}
