export function expandRangeByFraction(
  range: [number, number],
  fraction: number,
): [number, number] {
  const padding = ((range[1] - range[0]) * fraction) / 2
  return [range[0] - padding, range[1] + padding]
}

export function expand2dRangeByFraction(
  range: [[number, number], [number, number]],
  fraction: number,
): [[number, number], [number, number]] {
  return [
    expandRangeByFraction(range[0], fraction),
    expandRangeByFraction(range[1], fraction),
  ]
}

export function expandRangeBy(
  range: [number, number],
  padding: number,
): [number, number] {
  return [range[0] - padding, range[1] + padding]
}
