import React from 'react'

import { Field } from './Field'
import { NumberInput } from './NumberInput'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
  name: string
  unit?: string
  hint?: string
  accent?: boolean
}

export const NumberInputField: React.FC<Props> = (props) => {
  const { label, name, unit, hint, accent, ...inputProps } = props

  return (
    <Field label={label} name={name} hint={hint}>
      <NumberInput {...inputProps} accent={accent} name={name} unit={unit} />
    </Field>
  )
}
