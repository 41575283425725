import CardRankings from "../../../../src/components/card-rankings/CardRankings";
import mkmRatings from "../../../../src/data/articles/cube-prospective-murders-at-karlov-manor/mkm-prospective-ratings.json";
import ProspectiveIntro from "../../../../src/components/article-elements/ProspectiveIntro.tsx";
import { MKMMechanicsGraph } from "../../../../src/data/articles/cube-prospective-murders-at-karlov-manor/MKMMechanicsGraph.tsx";
import * as React from 'react';
export default {
  CardRankings,
  mkmRatings,
  ProspectiveIntro,
  MKMMechanicsGraph,
  React
};