import * as styles from './FeaturedLinks.module.scss'

import React from 'react'
import { Link } from 'gatsby'

interface Props {
  keyLinks: {
    text: string
    path: string
  }[]
  secondaryLinks?: {
    text: string
    path: string
  }[]
}

export const FeaturedLinks: React.FC<Props> = (props) => {
  const { keyLinks, secondaryLinks } = props

  return (
    <div className={styles.container}>
      {keyLinks.map((link, index) => (
        <Link key={index} className={styles.keyLink} to={link.path}>
          {link.text}
        </Link>
      ))}

      {secondaryLinks && (
        <div className={styles.moreLinks}>
          {secondaryLinks.map((link, index) => (
            <Link key={index} className={styles.link} to={link.path}>
              {link.text}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}
