import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'

export const DoWN: React.FC = () => {
  return (
    <Decklist
      label="Dragons of Winter&rsquo;s Night"
      labelLink="https://cubecobra.com/cube/overview/down"
      name="Fire and Ice"
      description={
        <>
          The challenge in this land-restricted cube was in maximizing the power
          of my deck&rsquo;s threats.
        </>
      }
      maindeck={[
        [
          { name: 'Rimewood Falls', set: 'KHM' },
          { name: 'Highland Forest', set: 'KHM' },
          { name: 'Alpine Meadow', set: 'KHM' },
          { name: 'Sulfurous Mire', set: 'KHM' },
          { name: 'Volatile Fjord', set: 'KHM' },
          { name: 'Woodland Chasm', set: 'KHM' },
          { name: 'Cragcrown Pathway' },
          { name: 'Clearwater Pathway' },
          { name: 'Boulderloft Pathway' },
          { name: 'Wooded Foothills', set: 'KTK' },
          { name: 'Prismatic Vista', set: 'MH1' },
          { name: 'Prismatic Vista', set: 'MH1' },
          { name: 'Snow-Covered Forest', set: 'KHM' },
          { name: 'Snow-Covered Forest', set: 'KHM' },
          { name: 'Snow-Covered Forest', set: 'KHM' },
          { name: 'Snow-Covered Island', set: 'KHM' },
          { name: 'Snow-Covered Swamp', set: 'KHM' },
        ],
        [
          { name: 'Boreal Druid' },
          { name: 'Foulmire Knight' },
          { name: 'Frost Bite' },
          { name: 'Skred' },
          { name: 'Chromatic Star' },
          { name: "Arcum's Astrolabe" },
        ],
        [
          { name: 'Rime Tender', set: 'MH1' },
          { name: 'Saddled Rimestag', set: 'MH1' },
          { name: 'Ice-Fang Coatl', set: 'MH1' },
          { name: 'Winterthorn Blessing' },
          { name: 'Mazemind Tome' },
          { name: 'Savage Punch', set: 'KTK' },
        ],
        [
          { name: 'Master of Death' },
          { name: 'Eternal Witness', set: 'TSR' },
          { name: 'Boreal Outrider', set: 'KHM' },
          { name: 'Yasova Dragonclaw', set: 'FRF' },
          { name: 'Glittering Frost', set: 'KHM' },
          { name: "Sevinne's Reclamation", set: 'DMR' },
        ],
        [
          { name: 'Ebondeath, Dracolich' },
          { name: 'Chill to the Bone' },
          { name: 'Binding the Old Gods' },
          { name: 'Primal Command' },
          { name: 'Inferno of the Star Mounts' },
        ],
      ]}
    />
  )
}
