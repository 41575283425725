import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const EmryShops: React.FC = () => {
  return (
    <Decklist
      name="Emry Shops Lock"
      drafter="Internet"
      description={
        <>
          This deck is built around potent recursion provided by{' '}
          <CardHover card="Goblin Welder">Goblin Welder</CardHover> and{' '}
          <CardHover card="Emry, Lurker of the Loch">
            Emry, Lurker of the Loch
          </CardHover>{' '}
          and frequently locked its opponents out of the game by looping{' '}
          <CardHover card="Tangle Wire">Tangle Wire</CardHover>.{' '}
          <CardHover card="Lodestone Golem">Lodestone Golem</CardHover> and{' '}
          <CardHover card="Stonecoil Serpent">Stonecoil Serpent</CardHover>{' '}
          provide win conditions and the whole deck is supercharged by{' '}
          <CardHover card="Mishra's Workshop">
            Mishra&rsquo;s Workshop
          </CardHover>
          .
        </>
      }
      maindeck={[
        [
          { name: 'Island', set: 'TMP' },
          { name: 'Mountain', set: 'TMP' },
          { name: "Mishra's Workshop", set: 'ATQ' },
          { name: "Mishra's Factory", set: 'ATQ' },
          { name: 'Rishadan Port', set: 'MMQ' },
          { name: 'Ancient Tomb', set: 'TMP' },
          { name: 'Mox Opal', set: 'SOM' },
        ],
        [
          { name: 'Goblin Welder', set: 'ULG' },
          { name: 'Emry, Lurker of the Loch' },
        ],
        [
          { name: 'Aether Spellbomb' },
          { name: 'Sphere of Resistance', set: 'EXO' },
          { name: 'Phyrexian Revoker' },
          { name: 'Tangle Wire' },
          { name: 'Lodestone Golem' },
          { name: 'Stonecoil Serpent' },
        ],
      ]}
      sideboard={[
        { name: 'Pyrite Spellbomb' },
        { name: 'Scrabbling Claws' },
        { name: 'Vampire Hexmage' },
        { name: 'Greater Gargadon' },
        { name: 'Goblin Cratermaker' },
        { name: 'Crop Rotation' },
        { name: 'Once Upon a Time' },
      ]}
    />
  )
}

export default EmryShops
