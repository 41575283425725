import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Decklist from 'components/decklists/Decklist'

export const MDFCdeck: React.FC = () => {
  return (
    <HorizontalScrollingContainer>
      <Decklist
        maindeckLabel="Example Deck"
        maindeck={[
          [
            { name: 'Plateau' },
            { name: 'Scrubland' },
            { name: 'Sulfurous Springs' },
            { name: 'Sunbaked Canyon' },
            { name: 'Caves of Koilos' },
            { name: 'Arena of Glory' },
            { name: 'Sundering Eruption' },
            { name: 'Razorgrass Ambush' },
            { name: 'Plains', set: 'mh3', count: 6 },
            { name: 'Mountain', set: 'mh3', count: 3 },
          ],
          [
            { name: 'Esper Sentinel' },
            { name: 'Kytheon, Hero of Akros' },
            { name: 'Ocelot Pride' },
          ],

          [
            { name: 'Intrepid Adversary' },
            { name: 'Phelia, Exuberant Shepherd' },
            { name: 'Thalia, Guardian of Thraben' },
            { name: 'Scrapheap Scrounger' },
            { name: 'White Orchid Phantom' },
            { name: 'Ajani, Nacatl Pariah' },
            { name: 'Orcish Bowmasters' },
            { name: 'Cathar Commando' },
            { name: 'Invasion of Gobakhan' },
            { name: 'Prismatic Ending' },
          ],

          [
            { name: 'Ranger-Captain of Eos' },
            { name: 'Detective’s Phoenix' },
            { name: 'Flickerwisp' },
            { name: 'Steel Seraph' },
            { name: 'Tangle Wire' },
            { name: 'Phlage, Titan of Fire’s Fury' },
          ],

          [
            { name: 'The Wandering Emperor' },
            { name: 'Serra Paragon' },
            { name: 'Fury' },
            { name: 'The Flux' },
          ],
        ]}
      />
    </HorizontalScrollingContainer>
  )
}
