/**
 * Normalize a set of valid data into a set of series. Valid input includes an
 * array of x, y tuples, array of arbitrary data objects including properties
 * `x` and `y`, and sets of series of values with names, colors, and points in
 * either form.
 */
export function normalizeSeriesData<Datum extends { x: number; y: number }>(
  data:
    | [number, number][]
    | Datum[]
    | {
        name?: string
        color?: string
        values: [number, number][]
      }[]
    | {
        name?: string
        color?: string
        values: Datum[]
      }[]
    | null,
): {
  name?: string
  color?: string
  values: Datum[]
}[] {
  if (data == null) {
    return []
  }

  const series:
    | {
        name?: string
        color?: string
        values: [number, number][]
      }[]
    | {
        name?: string
        color?: string
        values: Datum[]
      }[] = (
    Object.keys(data[0]).includes('values') ? data : [{ values: data }]
  ) as any

  return series.map((series) => ({
    name: series.name,
    color: series.color,
    values: Array.isArray(series.values[0])
      ? (series.values as [number, number][]).map(
          (datum) =>
            ({
              x: datum[0],
              y: datum[1],
            } as Datum),
        )
      : (series.values as Datum[]),
  }))
}
