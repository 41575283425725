import * as styles from './ExpandingCardSet.module.scss'

import React, { useState } from 'react'
import classNames from 'classnames'

import CardSet, { Props as CardSetProps } from './CardSet'

interface Props extends CardSetProps {
  title: string
}

export const ExpandingCardSet: React.FC<Props> = (props) => {
  const { title, columns } = props

  const [expanded, setExpanded] = useState(false)

  return (
    <div
      className={classNames(styles.container, {
        [styles.expanded]: expanded,
      })}
    >
      <div className={styles.title}>{title}</div>
      <CardSet columns={columns} />

      {!expanded && (
        <div className={styles.overlay}>
          <button
            className={styles.expandButton}
            type="button"
            onClick={() => setExpanded(true)}
          >
            Show Full List
          </button>
        </div>
      )}
    </div>
  )
}
