import CardRankings from "../../../../src/components/card-rankings/CardRankings";
import lciRatings from "../../../../src/data/articles/cube-prospective-the-lost-caverns-of-ixalan/lci-prospective-ratings.json";
import ProspectiveIntro from "../../../../src/components/article-elements/ProspectiveIntro.tsx";
import { LCIMechanicsGraph } from "../../../../src/data/articles/cube-prospective-the-lost-caverns-of-ixalan/LCIMechanicsGraph.tsx";
import * as React from 'react';
export default {
  CardRankings,
  lciRatings,
  ProspectiveIntro,
  LCIMechanicsGraph,
  React
};