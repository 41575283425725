import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Decklist from 'components/decklists/Decklist'

export const GruulDeck: React.FC = () => {
  return (
    <HorizontalScrollingContainer>
      <Decklist
        maindeckLabel="Example Deck"
        maindeck={[
          [
            { name: 'Stirring Wildwood' },
            { name: 'Horizon of Progress' },
            { name: 'Taiga' },
            { name: 'Stomping Ground' },
            { name: 'Copperline Gorge' },
            { name: 'Karplusan Forest' },
            { name: 'Boseiju, Who Endures' },
            { name: 'Forest', set: 'mh3', count: 6 },
            { name: 'Mountain', set: 'mh3', count: 3 },
          ],
          [
            { name: 'Analyze the Pollen' },
            { name: 'Utopia Sprawl' },
            { name: 'Jadelight Spelunker' },
            { name: 'Noble Hierarch' },
            { name: 'Hexdrinker' },
            { name: 'Birds of Paradise' },
            { name: 'Llanowar Elves' },
          ],
          [
            { name: 'Abrade' },
            { name: 'Fanatic of Rhonas' },
            { name: 'Mawloc' },
          ],
          [
            { name: 'Flare of Cultivation' },
            { name: 'Archdruid’s Charm' },
            { name: 'Six' },
            { name: 'Ornery Tumblewagg' },
          ],
          [
            { name: 'Bristlebud Farmer' },
            { name: 'Questing Beast' },
            { name: 'Garruk Wildspeaker' },
            { name: 'Sneak Attack' },
            { name: 'Tarmogoyf Nest' },
          ],
          [
            { name: 'Bloodbraid Challenger' },
            { name: 'Nissa, Who Shakes the World' },
            { name: 'Ulamog, the Defiler' },
            { name: 'Trumpeting Carnosaur' },
            { name: 'Etali, Primal Conqueror // Etali, Primal Sickness' },
            { name: 'Generous Ent' },
          ],
        ]}
      />
    </HorizontalScrollingContainer>
  )
}
