import * as styles from './FlavorlessCards.module.scss'

import React, { useState } from 'react'

import card1 from './card-1.svg'
import card2 from './card-2.svg'
import card3 from './card-3.svg'
import card4 from './card-4.svg'
import card5 from './card-5.svg'

const cardImages = [card1, card2, card3, card4, card5]

const columns = cardImages.length

export const FlavorlessCards: React.FC = () => {
  const [hovered, setHovered] = useState<number | null>(null)

  return (
    <div className={styles.container}>
      {cardImages.map((image, index) => {
        const adjustment =
          hovered == null || hovered === index
            ? 0
            : 0.3 * (hovered > index ? -1 : 1)
        const position = index - (columns - 1) / 2 + adjustment

        const transform = `translate(0, 100vw) rotate(${
          position * 7.5
        }deg) translate(0, ${-100}vw) rotate(${-position * 3}deg)`

        const hoverStyle =
          hovered != null && hovered === index
            ? {
                transform: `rotate(${
                  -(position * 7.5) + position * 3
                }deg) scale(1.3, 1.3)`,
              }
            : {}

        return (
          <div
            key={index}
            className={styles.card}
            style={{
              transform,
            }}
          >
            <div
              className={styles.cardImage}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
              style={hoverStyle}
            >
              <img alt="" className={styles.image} src={image} />
            </div>
          </div>
        )
      })}
    </div>
  )
}
