import React from 'react'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'
import Decklist from 'components/decklists/Decklist'

export const WUBRdeck: React.FC = () => {
  return (
    <HorizontalScrollingContainer>
      <Decklist
        maindeckLabel="Satya 3-0 Deck"
        maindeck={[
          [
            { name: 'Plains', set: 'mh3', count: 3 },
            { name: 'Mountain', set: 'mh3', count: 2 },
            { name: 'Island', set: 'mh3' },
            { name: 'Shivan Reef' },
            { name: 'Seachrome Coast' },
            { name: 'Arid Mesa' },
            { name: 'Sulfurous Springs' },
            { name: 'Silent Clearing' },
            { name: 'Hallowed Fountain' },
            { name: 'Shelldock Isle' },
            { name: 'Plateau' },
            { name: 'Sokenzan, Crucible of Defiance' },
            { name: 'Steam Vents' },
            { name: 'Waterlogged Teachings // Inundated Archive' },
          ],
          [
            { name: 'Esper Sentinel' },
            { name: 'Thraben Inspector' },
            { name: 'March of Otherworldly Light' },
            { name: 'Retrofitter Foundry' },
          ],
          [
            { name: 'Virtue of Loyalty' },
            { name: 'Shriekmaw' },
            { name: 'Intrepid Adversary' },
            { name: 'Dust Animus' },
            { name: 'Talisman of Dominance' },
            { name: "Umezawa's Jitte" },
          ],
          [
            { name: 'Kitesail Larcenist' },
            { name: 'Gut, True Soul Zealot' },
            { name: 'Spitting Dilophosaurus' },
            { name: 'Fable of the Mirror-Breaker // Reflection of Kiki-Jiki' },
            { name: 'Cryptic Coat' },
            { name: 'Touch the Spirit Realm' },
            { name: 'Ranger-Captain of Eos' },
          ],
          [
            { name: 'Showdown of the Skalds' },
            { name: 'Satya, Aetherflux Genius' },
          ],
          [
            { name: 'Treachery' },
            { name: 'Shark Typhoon' },
            { name: 'Skysovereign, Consul Flagship' },
            { name: 'Chaos Defiler' },
          ],
        ]}
      />
    </HorizontalScrollingContainer>
  )
}
