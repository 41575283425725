import React from 'react'

import { AllSets } from 'components/set-retrospectives/set-statistics/AllSets'

import { sets } from './sets'

import data from 'raw-loader!./set-statistics.csv'

import setCardPlayCounts from './set-card-play-counts.json'

const ArticleSetStatistics: React.FC = () => {
  return (
    <AllSets
      sets={sets}
      statisticsData={data}
      setCardPlayCounts={setCardPlayCounts}
      maxCardPlayCount={50}
    />
  )
}

export default ArticleSetStatistics
