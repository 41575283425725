import * as styles from './Plot.module.scss'

import React, { useEffect, useRef, useMemo } from 'react'
import classnames from 'classnames'
import * as d3 from 'd3'

import { Size, EdgeInsets } from 'utils/geometry'

import { useCardHover } from 'components/cards/CardHover'

import Card from './Card'

interface Props {
  verticalAxisLabel: string | null
  horizontalAxisLabel: string | null
  cards: Card[]
  focusedCard: string | null
  setFocusedCard(card: string | null): void
}

const bounds = new Size(650, 650).rect

const CardRankingsPlot: React.FC<Props> = (props) => {
  const {
    cards,
    verticalAxisLabel,
    horizontalAxisLabel,
    focusedCard,
    setFocusedCard,
  } = props

  const svgElement = useRef<SVGSVGElement | null>(null)

  const margins = useMemo(
    () =>
      new EdgeInsets(
        5,
        5,
        horizontalAxisLabel ? 40 : 20,
        verticalAxisLabel ? 40 : 30,
      ),
    [horizontalAxisLabel, verticalAxisLabel],
  )

  const contentRect = useMemo(() => bounds.inset(margins), [margins])

  const scaleX = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, 4.6])
        .range([contentRect.x, contentRect.maxX]),
    [contentRect],
  )

  const scaleY = useMemo(
    () =>
      d3.scaleLinear().domain([1, 10]).range([contentRect.maxY, contentRect.y]),
    [contentRect],
  )

  const scaleR = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(d3.extent(cards, (d) => d.testers) as any)
        .range([3, 10]),
    [cards],
  )

  useEffect(() => {
    if (svgElement.current == null) {
      return
    }

    const svg = d3.select(svgElement.current)

    svg.selectAll('.d3rendered').remove()

    const xAxis = d3.axisBottom(scaleX).ticks(7)
    const yAxis = d3.axisLeft(scaleY).ticks(7)

    svg
      .append('g')
      .attr('class', classnames('d3rendered', styles.axis))
      .attr('transform', `translate(0, ${contentRect.maxY})`)
      .call(xAxis)

    svg
      .append('g')
      .attr('class', classnames('d3rendered', styles.axis))
      .attr('transform', `translate(${contentRect.x}, 0)`)
      .call(yAxis)
      .selectAll('text')
      .attr('transform', 'translate(-12,-14)rotate(-90)')

    if (horizontalAxisLabel) {
      svg
        .append('g')
        .append('text')
        .attr('class', classnames('d3rendered', styles.axisLabel))
        .attr('x', contentRect.midX)
        .attr('y', contentRect.maxY + 35)
        .text(horizontalAxisLabel)
    }

    if (verticalAxisLabel) {
      svg
        .append('g')
        .append('text')
        .attr('class', classnames('d3rendered', styles.axisLabel))
        .attr('x', -contentRect.midY)
        .attr('y', 10)
        .attr('transform', 'rotate(-90)')
        .text(verticalAxisLabel)
    }
  }, [
    cards,
    horizontalAxisLabel,
    verticalAxisLabel,
    margins,
    scaleX,
    scaleY,
    contentRect,
  ])

  return (
    <svg
      className={classnames(styles.container, {
        [styles.pointFocused]: focusedCard != null,
      })}
      ref={svgElement}
      viewBox={`0 0 ${bounds.width} ${bounds.height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={styles.background}
        x={contentRect.x}
        y={contentRect.y}
        width={contentRect.width}
        height={contentRect.height}
      />
      <g>
        {cards.map((card) => (
          <PlotPoint
            card={card}
            key={card.name}
            scaleX={scaleX}
            scaleY={scaleY}
            scaleR={scaleR}
            focusedCard={focusedCard}
            setFocusedCard={setFocusedCard}
          />
        ))}
      </g>
    </svg>
  )
}

interface PointProps {
  card: Card
  scaleX: d3.ScaleLinear<number, number>
  scaleY: d3.ScaleLinear<number, number>
  scaleR: d3.ScaleLinear<number, number>
  focusedCard: string | null
  setFocusedCard(card: string | null): void
}

const PlotPoint = (props: PointProps) => {
  const { card, scaleX, scaleY, scaleR, focusedCard, setFocusedCard } = props
  const { hoverProps, portal } = useCardHover(card.name)
  const { onClick, onMouseEnter, onMouseLeave, onMouseMove } = hoverProps

  return (
    <>
      <g
        className={classnames(styles.point, {
          [styles.focused]: focusedCard === card.name,
        })}
        key={card.name}
        transform={`translate(${scaleX(4.6 - card.rankingStDev)} ${scaleY(
          card.averageRating,
        )})`}
        onClick={onClick}
        onMouseEnter={() => {
          onMouseEnter && onMouseEnter()
          setFocusedCard(card.name)
        }}
        onMouseLeave={() => {
          onMouseLeave && onMouseLeave()
          setFocusedCard(null)
        }}
        onMouseMove={onMouseMove}
      >
        <circle
          r={scaleR(card.testers)}
          fill={focusedCard === card.name ? `var(--accent-color)` : '#1b243370'}
        ></circle>
      </g>
      {portal}
    </>
  )
}

export default CardRankingsPlot
