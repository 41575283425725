import * as styles from './Table.module.scss'

import React, { useState, useMemo, useCallback } from 'react'
import classNames from 'classnames'
import { sortBy } from 'lodash'
import { Link } from 'gatsby'

import { useCardHover } from 'components/cards/CardHover'

import Card from './Card'

interface Props {
  cards: Card[]
  totalTesters: number
  testerThreshold: number
  focusedCard: string | null
  setFocusedCard(card: string | null): void
}

const Table: React.FC<Props> = (props) => {
  const { cards, testerThreshold, ...rowProps } = props

  const [sortColumn, setSortColumn] = useState<keyof Card>('testers')
  const [sortOrder, setSortOrder] = useState(true)

  const tableData = useMemo(() => {
    const data = sortBy(cards, (r) => r[sortColumn])
    if (sortColumn === 'name') {
      return sortOrder ? data : data.reverse()
    } else {
      return sortOrder ? data.reverse() : data
    }
  }, [cards, sortColumn, sortOrder])

  const onClick = useCallback(
    (column: keyof Card) => {
      if (sortColumn === column) {
        setSortOrder(!sortOrder)
      } else {
        setSortColumn(column)
        setSortOrder(true)
      }
    },
    [sortColumn, sortOrder],
  )

  const headingProps = {
    sortColumn,
    sortOrder,
    onClick,
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.heading}>
            <TableHeading name="name" {...headingProps} className={styles.item}>
              Card
            </TableHeading>
            <TableHeading
              name="testers"
              {...headingProps}
              className={classNames(styles.item, styles.centered)}
            >
              Testers
            </TableHeading>
            <TableHeading
              name="averageRating"
              {...headingProps}
              className={classNames(styles.item, styles.centered)}
            >
              Rank
            </TableHeading>
          </tr>
        </thead>
        <tbody>
          {tableData.map((card) => (
            <TableRow key={card.name} card={card} {...rowProps} />
          ))}
        </tbody>
      </table>
      <div className={styles.thresholdDisclaimer}>
        Cards being tested by fewer than {testerThreshold + 1} respondents not
        shown.
      </div>
    </div>
  )
}

interface HeadingProps {
  name: string
  sortColumn: string
  sortOrder: boolean
  onClick(name: string): void
  children: string
  className: string
}

const TableHeading: React.FC<HeadingProps> = (props) => {
  const { name, sortColumn, sortOrder, children, onClick, className } = props

  return (
    <th className={className} onClick={() => onClick(name)}>
      {children}
      <span className={styles.sortIcon}>
        {sortColumn === name ? sortOrder ? '▼' : '▲' : <>&nbsp;</>}
      </span>
    </th>
  )
}

interface RowProps {
  card: Card
  totalTesters: number
  focusedCard: string | null
  setFocusedCard(card: string | null): void
}

const TableRow: React.FC<RowProps> = (props) => {
  const { card, totalTesters, focusedCard, setFocusedCard } = props
  const { hoverProps, portal } = useCardHover(card.name)
  const { onClick, onMouseEnter, onMouseLeave, onMouseMove } = hoverProps

  return (
    <>
      <tr
        onClick={onClick}
        onMouseEnter={() => {
          onMouseEnter && onMouseEnter()
          setFocusedCard(card.name)
        }}
        onMouseLeave={() => {
          onMouseLeave && onMouseLeave()
          setFocusedCard(null)
        }}
        onMouseMove={onMouseMove}
        style={
          focusedCard === card.name
            ? { backgroundColor: `rgba(var(--accent-color-rgb), 0.2)` }
            : {}
        }
      >
        <td className={styles.item}>{card.name}</td>
        <td className={classNames(styles.item, styles.centered)}>
          {card.mapLink != null ? (
            <Link to={card.mapLink}>
              {((card.testers / totalTesters) * 100).toFixed(1)}%
            </Link>
          ) : (
            <>{((card.testers / totalTesters) * 100).toFixed(1)}%</>
          )}
        </td>
        <td className={classNames(styles.item, styles.centered)}>
          {card.averageRating.toFixed(1)}
        </td>
      </tr>
      {portal}
    </>
  )
}

export default Table
