import * as styles from './CardRankings.module.scss'

import React, { useState, useMemo } from 'react'

import Card from './Card'
import Plot from './Plot'
import Table from './Table'
import Controls from './Controls'

interface Props {
  totalTesters: number
  data: {
    totalResponses: number
    cards: Card[]
  }
  testerThreshold?: number
}

const CardRankings: React.FC<Props> = (props) => {
  const { data, testerThreshold = 2 } = props
  const { cards } = data
  const totalTesters = data.totalResponses

  const [selectedRarities, setSelectedRarities] = useState({
    common: true,
    uncommon: true,
    rare: true,
    mythic: true,
  })

  const filteredCards = useMemo(() => {
    return cards.filter(
      (card) => card.testers > testerThreshold && selectedRarities[card.rarity],
    )
  }, [cards, selectedRarities, testerThreshold])

  const [focusedCard, setFocusedCard] = useState<string | null>(null)

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <Controls
          selectedRarities={selectedRarities}
          setSelectedRarities={setSelectedRarities}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.plot}>
          <div className={styles.stickyContainer}>
            <Plot
              cards={filteredCards}
              focusedCard={focusedCard}
              setFocusedCard={setFocusedCard}
              verticalAxisLabel="Average Rating"
              horizontalAxisLabel="Consensus (-Standard Deviation)"
            />
          </div>
        </div>
        <div className={styles.table}>
          <Table
            cards={filteredCards}
            totalTesters={totalTesters}
            testerThreshold={testerThreshold}
            focusedCard={focusedCard}
            setFocusedCard={setFocusedCard}
          />
        </div>
      </div>
    </div>
  )
}

export default CardRankings
