import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

const BantControl: React.FC = () => {
  return (
    <Decklist
      name="Bant Control"
      drafter="Puff the Magic Dragon"
      description={
        <>
          This Bant control deck pulled down the 3-0 in one of our playtesting
          sessions. The combination of cheap countermagic and removal with{' '}
          <CardHover card="Oko, Thief of Crowns">Oko</CardHover> as a win
          condition proved very potent.{' '}
          <CardHover card="Memory's Journey">Memory&rsquo;s Journey</CardHover>{' '}
          was frequently sided in.
        </>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'TMP' },
          { name: 'Plains', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Island', set: 'TMP' },
          { name: 'Gemstone Mine', set: 'WTH' },
        ],
        [
          { name: 'Mana Tithe' },
          { name: 'Swords to Plowshares', set: 'LEB' },
          { name: 'Force Spike', set: 'LEG' },
          { name: "Judge's Familiar", set: 'F13' },
        ],
        [
          { name: 'Snapcaster Mage', set: 'ISD' },
          { name: 'Time Walk', set: 'LEB' },
        ],
        [
          { name: 'Stonecoil Serpent' },
          { name: "Sevinne's Reclamation" },
          { name: "Council's Judgment" },
          { name: 'Oko, Thief of Crowns' },
        ],
      ]}
      sideboard={[
        { name: 'Balance' },
        { name: 'Abolish' },
        { name: "Memory's Journey" },
        { name: 'Cabal Therapy' },
        { name: 'Faithless Looting' },
        { name: 'Collector Ouphe' },
        { name: 'Return to Nature' },
        { name: 'Sorcerous Spyglass' },
        { name: 'Forbidden Orchard' },
      ]}
    />
  )
}

export default BantControl
