import * as styles from './LabelledImage.module.scss'

import React, { useCallback, useState } from 'react'

interface Props {
  image: string
  width: number
  height: number
  features: {
    x: number
    y: number
    iconURL?: string
  }[]
}

/**
 * An image with an overlaid set of points. This was built specifically for the
 * set cube article to show the locations of set cube islands. It's fairly
 * tailored to that, but could be abstracted.
 */
export const LabelledImage: React.FC<Props> = (props) => {
  const { image, width, height, features } = props

  const [points, setPoints] = useState(
    features.map((feature, index) => ({ id: index, ...feature })),
  )

  const bringToFront = useCallback((index: number) => {
    setPoints((value) => {
      const result = [...value]
      result.push(...result.splice(index, 1))
      return result
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={image} alt="" className={styles.image} />
      </div>

      <svg viewBox={`0 0 ${width} ${height}`} className={styles.svg}>
        {points.map((point, index) => {
          return (
            <g
              key={point.id}
              transform={`translate(${point.x} ${point.y})`}
              onMouseEnter={() => bringToFront(index)}
            >
              <g className={styles.innerCircle}>
                <circle cx={0} cy={0} r={14} className={styles.circle} />

                {point.iconURL && (
                  <image
                    width="16"
                    height="16"
                    href={point.iconURL}
                    x="-8"
                    y="-8"
                  />
                )}
              </g>
            </g>
          )
        })}
      </svg>
    </div>
  )
}
