import * as styles from './Decklist.module.scss'

import React from 'react'
import classNames from 'classnames'

import DecklistColumn from './Column'

interface Props {
  maindeck: { name: string; set?: string; id?: string; count?: number }[][]
  sideboard?: { name: string; set?: string; id?: string; count?: number }[]
  maindeckLabel?: string
}

/**
 * Visual deck list component with card images in columns, overlapping to show
 * just the name of most cards.
 */
const Decklist: React.FC<Props> = (props) => {
  const { maindeck, sideboard, maindeckLabel = 'Maindeck' } = props

  return sideboard ? (
    <div
      className={classNames(styles.container, styles.withSideboard)}
      style={{
        gridTemplateColumns: `${maindeck.length}fr auto 1fr`,
      }}
    >
      <div>
        <div className={styles.heading}>{maindeckLabel}</div>
        <div className={styles.columns}>
          {maindeck.map((column, index) => (
            <DecklistColumn key={index} cards={column} />
          ))}
        </div>
      </div>
      <div className={styles.divider} />
      <div>
        <div className={styles.heading}>Sideboard</div>
        <div className={styles.columns}>
          <DecklistColumn cards={sideboard} />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div>
        <div className={styles.heading}>{maindeckLabel}</div>
        <div className={styles.columns}>
          {maindeck.map((column, index) => (
            <DecklistColumn key={index} cards={column} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Decklist
