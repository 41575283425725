import * as styles from './HistogramRow.module.scss'

import React from 'react'
import * as d3 from 'd3'

import { useTooltip } from 'components/visualization/tooltip/Tooltip'

interface Props {
  values: {
    value: number
    label: string
  }[]
  min: number
  max: number
}

const colors = ['#2d739d', '#5CABDC']

const width = 200
const height = 20
const padding = 4
const gap = 1

const HistogramRow: React.FC<Props> = (props) => {
  const { values, min, max } = props

  const scale = d3.scaleLinear().domain([min, max]).range([0, width])

  const barHeight = (height - padding * 2 - gap) / values.length

  return (
    <svg viewBox={`0 0 ${width} ${height}`} className={styles.container}>
      {values.map((value, index) => (
        <Bar
          key={index}
          x={scale(Math.min(0, value.value))}
          y={index * (barHeight + gap) + padding}
          width={Math.abs(scale(value.value) - scale(0))}
          height={barHeight}
          fill={colors[index]}
          label={value.label}
        />
      ))}

      <line x1={scale(0)} y1={0} x2={scale(0)} y2={height} stroke="black" />
    </svg>
  )
}

interface BarProps {
  x: number
  y: number
  width: number
  height: number
  fill: string
  label: string
}

const Bar: React.FC<BarProps> = (props) => {
  const { x, y, width, height, fill, label } = props

  const { mouseEvents, portal } = useTooltip(() => <div>{label}</div>)

  return (
    <>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        {...mouseEvents}
      />

      {portal}
    </>
  )
}

export default React.memo(HistogramRow)
