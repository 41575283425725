import * as styles from './AcronymSearch.module.scss'

import React, { useCallback, useMemo, useState } from 'react'

import * as controls from 'components/controls'
import * as typography from 'components/typography'

import classNames from 'classnames'

function regexFragmentsForAcronym(acronym: string, strict: boolean) {
  const letters = acronym.toUpperCase().split('').slice(0, 5)

  if (strict) {
    return [
      ...letters.flatMap((letter, index) =>
        index === 0
          ? [
              { text: `^`, type: 'fragment' },
              { text: letter, type: 'input' },
            ]
          : [
              { text: `[a-z']+[ -]`, type: 'fragment' },
              { text: letter, type: 'input' },
            ],
      ),
      { text: `[a-z']+($| \\/\\/)`, type: 'fragment' },
    ]
  } else {
    return letters.flatMap((letter, index) =>
      index === 0
        ? [
            { text: `\\b`, type: 'fragment' },
            { text: letter, type: 'input' },
          ]
        : [
            { text: `.*\\b`, type: 'fragment' },
            { text: letter, type: 'input' },
          ],
    )
  }
}

export const AcronymSearch: React.FC = () => {
  const [query, setQuery] = useState('')
  const [strictMatch, setStrictMatch] = useState(false)

  const regexObject = useMemo(() => {
    return regexFragmentsForAcronym(query, strictMatch)
  }, [query, strictMatch])

  const regexPattern = useMemo(() => {
    return regexObject.map((fragment) => fragment.text).join('')
  }, [regexObject])

  const search = useCallback(() => {
    const scryfallQuery = `name:/${regexPattern}/ -is:extra`

    const url = `https://scryfall.com/search?q=${encodeURIComponent(
      scryfallQuery,
    )}&unique=cards&as=grid&order=name`

    window.open(url, '_blank')
  }, [regexPattern])

  return (
    <div className={styles.container}>
      <div className={styles.label}>Search for card acronyms with regex</div>

      <div className={styles.input}>
        <controls.TextInput
          value={query}
          onChange={(event) => setQuery(event.currentTarget.value)}
          maxLength={5}
          placeholder="JTMS"
        />
      </div>

      <div className={styles.result}>
        <div className={styles.patternPreview}>
          {query.length > 0 ? (
            <typography.Code>
              {regexObject.map((fragment, index) => (
                <span
                  key={index}
                  className={classNames({
                    [styles.previewInputFragment]: fragment.type === 'input',
                  })}
                >
                  {fragment.text}
                </span>
              ))}
            </typography.Code>
          ) : (
            <typography.Code>
              <span className={styles.previewPlaceholder}>
                \bJ.*\bT.*\bM.*\bS
              </span>
            </typography.Code>
          )}
        </div>

        <controls.SmallButton onClick={() => search()}>
          Search Scryfall &rarr;
        </controls.SmallButton>
      </div>

      <controls.CheckboxField
        label="Strict match, no extra words"
        checked={strictMatch}
        onChange={(event) => setStrictMatch(event.currentTarget.checked)}
      />
    </div>
  )
}
