import * as styles from './ControlGroup.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
  alignRight?: boolean
}

export const ControlGroup: React.FC<Props> = (props) => {
  const { children, alignRight = false } = props

  return (
    <div
      className={styles.container}
      style={{ justifyContent: alignRight ? 'end' : 'start' }}
    >
      {children}
    </div>
  )
}
