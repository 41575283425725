// extracted by mini-css-extract-plugin
export var comments = "Response-module--comments--159c9";
export var container = "Response-module--container--35403";
export var flag = "Response-module--flag--b6446";
export var inCube = "Response-module--inCube--766e1";
export var loadingIndicator = "Response-module--loadingIndicator--764fe";
export var missingName = "Response-module--missingName--738da";
export var notInCube = "Response-module--notInCube--102e8";
export var rating = "Response-module--rating--a168d";
export var ratings = "Response-module--ratings--3b68a";
export var title = "Response-module--title--f26d3";
export var unrated = "Response-module--unrated--2f240";